import React from 'react';
import Table from "react-bootstrap/Table";
import { renderTableRow } from '../../config/utils';
import ActionItems from './showActions';

const DataTable = (props) => {
    const {
        loader,
        headers,
        tableData,
        tableDataStatus,
        filterData,
        handleSortingColumn,
        actionType,
        handleAction,
    } = props;
    return (
        <Table
            className={`table-height cus-left-right-col-layout ${loader ? "disableTble" : ""}`}
        >
            <thead className="tbl-btch">
                <tr className="table-rw-batch">
                    {headers.map((item, index) => (
                        <th key={item.key}>
                            {item.key ? (
                                <div
                                    className={
                                        filterData?.sortColumn === item.key
                                            ? "active"
                                            : "sortColumn"
                                    }
                                    onClick={() => handleSortingColumn(item.key)}
                                >
                                    {item.label}
                                    <i
                                        className={
                                            filterData?.sortColumn === item.key &&
                                                filterData?.sortOrder === "ASCENDING"
                                                ? "fa fa-arrow-up"
                                                : "fa fa-arrow-down"
                                        }
                                        aria-hidden="true"
                                    ></i>
                                </div>
                            ) : (
                                <>{item.label}</>
                            )}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody className="tbl-bt-body">
                {tableData && tableData.map((item, listIndex) => (
                    <tr key={item.listIndex}>
                        {headers.map((column) => (
                            <td key={column.id}>
                                {column.key ? (
                                    <>{item[column.key]}</>
                                ) : (
                                    <>
                                        {actionType === 2 && (
                                            <>
                                                <ActionItems type={'View'} item={item} handleAction={() => handleAction(item, 'view', false)} />
                                                <ActionItems type={'Edit'} item={item} handleAction={() => handleAction(item, 'edit', false)} />
                                                <ActionItems type={'History'} item={item} handleAction={() => handleAction(item, 'history', false)} />
                                            </>
                                        )}
                                    </>
                                )}
                            </td>
                        ))}
                    </tr>
                ))}
                {tableDataStatus === "SUCCESS" &&
                    tableData.length === 0 && (
                        <>{renderTableRow(headers.length, "No Result Found...")}</>
                    )}
            </tbody>
        </Table>
    );
}

export default DataTable;