import { Button } from "bootstrap-4-react/lib/components";
import React from "react";
import { useState } from "react";
import "./rateCard.css";
import Table from "react-bootstrap/Table";
import edit_icon from "../../../assets/edit_icon.svg";
import eye_icon from "../../../assets/eye_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import RateCardModal from "../../modal/AddRateCardModal/index";
import moment from "moment";
import {
  GetActiveCompany,
  getRateCardDetails,
  getSellerCardDetails,
  getTotalSellerDetails,
  sellerRatecardListBackDetails,
  baseRatecardListBackDetails,
} from "../../../store/actions/rateCard";
import { useEffect } from "react";
import EffectiveSellerModal from "../../modal/EffectiveSellerModal";
import BatchPagination from "../../batch/BatchPagination";
import SellerRateCardListing from "./sellerRateCardList";
import { checkPermission } from "../../../config/utils";
import { baseRateCardAdd, baseRateCardEdit, baseRateCardView, featureSubmodulePermission, sellerRateCardAdd, sellerRateCardEdit, sellerRateCardView, settings, SUBF_RATECARD } from "../../../constant";

function RateCard(props) {
  const [modalShow, setModalShow] = React.useState(false);
  const [effectiveSellerModalShow, setEffectiveSellerModalShow] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(1);
  const [editable, setEditable] = React.useState(true);
  const [totalPages, setTotalPages] = useState(1);
  let dataLoad = {
    pageIndex: 1,
    pageSize: 30,
    sortColumn: "",
    sortOrder: 'DESCENDING',
  };
  const [filterData, setFilterData] = useState({});
  const [sellerTotalPages, setsellerTotalPages] = useState(1);
  const numOfPage = [30, 50, 100, 200, 500, 1000, 2000];
  const [activeCompany, setactiveCompany] = useState(30);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ZoneTab = location?.state?.zoneTab;
  const AllRateCards = useSelector(
    (state) =>
      state?.RateCardReducer?.getAllRateCards?.Entity?.BaseRateResponseEntity
  );
  const { getActiveCompany, sellerratecardBackDetails, baseratecardBackDetails } = useSelector((state) => state.RateCardReducer);
  const AllRateCardsRecord = useSelector(
    (state) => state?.RateCardReducer?.getAllRateCards
  );
  const AllSellerRateCards = useSelector(
    (state) =>
      state?.RateCardReducer?.getAllSellerCards?.Entity?.SellerRateCardEntity
  );
  const AllSellerRateCardsRecord = useSelector(
    (state) => state?.RateCardReducer?.getAllSellerCards
  );
  const AllSellerDetails = useSelector(
    (state) => state?.RateCardReducer?.getSellerData?.EntityList
  );
  const createdBy = useSelector((state) => state?.auth?.user);
  const permissions = useSelector(
    (state) => state?.sidebar?.employeePermissions
  );

  useEffect(() => {
    if (!_.isEmpty(baseratecardBackDetails)) {
      setFilterData({ ...filterData, ...baseratecardBackDetails });
    } else if (!_.isEmpty(sellerratecardBackDetails)) {
      setFilterData({ ...filterData, ...sellerratecardBackDetails });
    } else {
      setFilterData({ ...dataLoad });
    }
  }, [])

  useEffect(() => {
    if (ZoneTab) {
      setActiveTab(ZoneTab);
    } else {
      if (!permissions?.baseRateCard || !createdBy?.Value?.isSuperAdmin) {
        setActiveTab(2);
      } else {
        setActiveTab(1);
      }
    }
  }, [permissions, ZoneTab, createdBy?.Value?.isSuperAdmin]);

  const handleSortingCoulmn = (event, sortColumn) => {
    event.preventDefault();
    let newSortOrder = filterData.sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING";
    setFilterData({
      ...filterData,
      sortColumn: sortColumn,
      sortOrder: newSortOrder,
    });
  };

  React.useEffect(() => {
    dispatch(GetActiveCompany());
  }, []);

  React.useEffect(() => {
    if (!_.isEmpty(filterData)) {
      if (createdBy?.Value?.isSuperAdmin && activeTab === 1) {
        dispatch(
          getRateCardDetails(
            createdBy,
            setTotalPages,
            filterData.pageSize,
            filterData.pageIndex,
            createdBy?.Value?.companySellerId,
            createdBy?.Value?.role,
            filterData,
          )
        );
      }
    }

  }, [filterData.pageSize, filterData.pageIndex, filterData.sortColumn, filterData.sortOrder, activeTab]);

  useEffect(() => {
    if (getActiveCompany && getActiveCompany.length) {
      let data = getActiveCompany.map((item) => {
        return {
          ...item,
          value: item.Id,
          label: item.name,
        };
      });
      setactiveCompany(data);
    }
  }, [getActiveCompany]);

  React.useEffect(() => {
    if (activeTab === 2) {
      dispatch(
        getSellerCardDetails(
          permissions?.baseRateCard,
          createdBy,
          setsellerTotalPages,
          filterData.pageSize,
          filterData.pageIndex,
          filterData,
        )
      );
    }

    // }
  }, [filterData.pageSize, filterData.pageIndex, filterData.sortColumn, filterData.sortOrder, activeTab]);

  const fetchSellerCards = () => {
    dispatch(getSellerCardDetails(permissions?.baseRateCard, createdBy));
  };

  const getRateCards = () => {
    dispatch(
      getRateCardDetails(
        createdBy,
        setTotalPages,
        filterData.pageSize,
        filterData.pageIndex,
        createdBy?.Value?.companySellerId,
        createdBy?.Value?.role
      )
    );
  };

  const updateState = (newState) => {
    setEditable(newState);
  };

  const handleModalSellerData = (sellerId) => {
    dispatch(getTotalSellerDetails(sellerId));
    setTimeout(() => {
      setEffectiveSellerModalShow(true);
    }, 500);
  };

  function handleEditClickRow(rowData) {
    navigate(`/rateCardDetails/rate-${rowData.carrierId}-3pl`, {
      state: {
        data: {
          ...rowData,
          activeTab,
          createdDate: moment(rowData?.createdDate).format(
            "MM-DD-YYYY | h:mm:ss A"
          ),
          effectiveFrom:
            rowData?.effectiveFrom &&
              rowData?.effectiveFrom != "0001-01-01T00:00:00"
              ? moment(rowData?.effectiveFrom, "MMM DD YYYY h:mmA").format(
                "MM-DD-YYYY | h:mm:ss A"
              )
              : "-----",
          effectiveTo:
            rowData?.effectiveTo &&
              rowData?.effectiveTo != "0001-01-01T00:00:00"
              ? moment(rowData?.effectiveTo, "MMM DD YYYY h:mmA").format(
                "MM-DD-YYYY | h:mm:ss A"
              )
              : "-----",
        },
        editable: true,
        tab: activeTab,
        activeCompany,
      },
      updateState,
    });
  }

  function handleViewClickRow(rowData) {
    dispatch(baseRatecardListBackDetails(filterData))
    navigate(`/rateCardDetails/rate-${rowData.carrierId}-3pl`, {
      state: {
        data: {
          ...rowData,
          activeTab,
          createdDate: moment(rowData?.createdDate).format(
            "MM-DD-YYYY | h:mm:ss A"
          ),
          effectiveFrom:
            rowData?.effectiveFrom &&
              rowData?.effectiveFrom != "0001-01-01T00:00:00"
              ? moment(rowData?.effectiveFrom, "MMM DD YYYY h:mmA").format(
                "MM-DD-YYYY | h:mm:ss A"
              )
              : "-----",
          effectiveTo:
            rowData?.effectiveTo &&
              rowData?.effectiveTo != "0001-01-01T00:00:00"
              ? moment(rowData?.effectiveTo, "MMM DD YYYY h:mmA").format(
                "MM-DD-YYYY | h:mm:ss A"
              )
              : "-----",
        },
        editable: false,
        tab: activeTab,
        activeCompany,
      },
      updateState,
    });
  }

  function handleViewSellerRow(rowData) {
    dispatch(sellerRatecardListBackDetails(filterData))
    navigate(`/rateCardDetails/rate-${rowData.carrierId}-3pl`, {
      state: {
        data: {
          ...rowData,
          activeTab,
          createdDate: moment(rowData?.createdDate).format(
            "MM-DD-YYYY | h:mm:ss A"
          ),
          effectiveFrom:
            rowData?.effectiveFrom &&
              rowData?.effectiveFrom != "0001-01-01T00:00:00"
              ? moment(rowData?.effectiveFrom).format("MM-DD-YYYY | h:mm:ss A")
              : "-----",
          effectiveTo:
            rowData?.effectiveTo &&
              rowData?.effectiveTo != "0001-01-01T00:00:00"
              ? moment(rowData?.effectiveTo).format("MM-DD-YYYY | h:mm:ss A")
              : "-----",
        },
        editable: false,
        tab: activeTab,
        type: "sellerRatecard",
        activeCompany,
      },
      updateState,
    });
  }

  function handleEditSellerRow(rowData) {
    dispatch(sellerRatecardListBackDetails(filterData))
    navigate(`/rateCardDetails/rate-${rowData.carrierId}-3pl`, {
      state: {
        data: {
          ...rowData,
          activeTab,
          createdDate: moment(rowData?.createdDate).format(
            "MM-DD-YYYY | h:mm:ss A"
          ),
          effectiveFrom:
            rowData?.effectiveFrom &&
              rowData?.effectiveFrom != "0001-01-01T00:00:00"
              ? moment(rowData?.effectiveFrom).format("MM-DD-YYYY | h:mm:ss A")
              : "-----",
          effectiveTo:
            rowData?.effectiveTo &&
              rowData?.effectiveTo != "0001-01-01T00:00:00"
              ? moment(rowData?.effectiveTo).format("MM-DD-YYYY | h:mm:ss A")
              : "-----",
        },
        editable: true,
        type: "sellerRatecard",
        activeCompany,
        tab: activeTab,
      },
      updateState,
    });
  }

  const renderList = () => {
    return (
      <tbody>
        {AllSellerRateCards &&
          AllSellerRateCards?.map((d) => (
            <>
              <tr key={d?.carrierProviderId}>
                <td>{d?.sellerRateCardName}</td>
                <td>
                  {d?.isActive ? (
                    <span className="activeStatusBtn">
                      Active
                    </span>
                  ) : (
                    <span className="activeStatusBtn inactiveBtn">
                      In Active
                    </span>
                  )}
                </td>
                <td>{d?.carrierProviderName}</td>
                {permissions?.baseRateCard && (
                  <td>
                    {d?.effectiveCompanies == 0 ? (
                      <div className="seller-modal">
                        {d?.effectiveCompanies}
                      </div>
                    ) : (
                      <div
                        className="seller-modal"
                        onClick={() =>
                          handleModalSellerData(
                            d?.sellerRateCardMasterId
                          )
                        }
                      >
                        {d?.effectiveCompanies}
                      </div>
                    )}
                  </td>
                )}
                <td>
                  {d?.effectiveFrom &&
                    d?.effectiveFrom != "0001-01-01T00:00:00"
                    ? moment(d?.effectiveFrom).format(
                      "MM-DD-YYYY | h:mm:ss A"
                    )
                    : "-----"}
                </td>
                <td>
                  {d?.effectiveTo &&
                    d?.effectiveTo != "0001-01-01T00:00:00"
                    ? moment(d?.effectiveTo).format(
                      "MM-DD-YYYY | h:mm:ss A"
                    )
                    : "-----"}
                </td>
                <td colSpan={2}>
                  <div>
                    {checkPermission(settings, SUBF_RATECARD, sellerRateCardView, featureSubmodulePermission, props.employeePermissions) && (
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={() => handleViewSellerRow(d)}
                        src={eye_icon}
                        className="editIcon mr-2"
                        alt=""
                      />
                    )}
                    {checkPermission(settings, SUBF_RATECARD, sellerRateCardEdit, featureSubmodulePermission, props.employeePermissions) && (
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={() => handleEditSellerRow(d)}
                        src={edit_icon}
                        alt=""
                        className="editIcon"
                      />)}
                  </div>
                </td>
              </tr>
            </>
          ))}
      </tbody>
    )
  }

  return (
    <main>
      {modalShow && activeTab === 1 && (
        <RateCardModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          getRateCards={getRateCards}
          activeCompany={activeCompany}
          editable={editable}
        />
      )}

      {modalShow && activeTab === 2 && (
        <RateCardModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          getRateCards={getRateCards}
          activeCompany={activeCompany}
          type={"Seller"}
          fetchSellerCards={fetchSellerCards}
        />
      )}
      <EffectiveSellerModal
        show={effectiveSellerModalShow}
        onHide={() => setEffectiveSellerModalShow(false)}
        sellerData={AllSellerDetails}
      />
      <div className="main__container">
        <>
          <div className="main__tile_heading cus-btn-include-sec">
            Rate Card
            <div className="top_right_button_container">
              {(activeTab === 1 ? checkPermission(settings, SUBF_RATECARD, baseRateCardAdd, featureSubmodulePermission, props.employeePermissions) : checkPermission(settings, SUBF_RATECARD, sellerRateCardAdd, featureSubmodulePermission, props.employeePermissions)) && (
                <Button
                  className="cus-seconday-bg-btn top-btn"
                  onClick={() => setModalShow(true)}
                >Add Rate Card</Button>
              )}
              <Button
                className="cus-primary-transparent-btn"
                onClick={() => {
                  navigate("/setting");
                  dispatch(baseRatecardListBackDetails({}))
                  dispatch(sellerRatecardListBackDetails({}))
                }}
              >
                Back
              </Button>
            </div>
          </div>
        </>
        <div className="container-fluid">
          <div className="cus-page-content-sec">
            <div className="cus-tab-heading-sec">
              <div className="tabs">
                {checkPermission(settings, SUBF_RATECARD, baseRateCardView, featureSubmodulePermission, props.employeePermissions) && (
                  <button
                    className={`${activeTab === 1 ? "cus-tab-btn active" : "cus-tab-btn"
                      }`}
                    onClick={() => {
                      setActiveTab(1)
                      dispatch(sellerRatecardListBackDetails({}))
                      setFilterData(dataLoad)
                    }}
                  >
                    Base Rate Card
                  </button>
                )}

                {checkPermission(settings, SUBF_RATECARD, sellerRateCardView, featureSubmodulePermission, props.employeePermissions) && (
                  <button
                    className={`${activeTab === 2 ? "cus-tab-btn active" : "cus-tab-btn"
                      }`}
                    onClick={() => {
                      setActiveTab(2)
                      dispatch(baseRatecardListBackDetails({}))
                      setFilterData(dataLoad)
                    }}
                  >
                    Seller Rate Card
                  </button>
                )}

                {activeTab === 1 && (
                  <div className="main_content_table incomingTbl tableFixHead">
                    <Table className="table-height">
                      <thead>
                        <tr>
                          <th>
                            <div
                              className={
                                filterData.sortColumn === "name"
                                  ? "active"
                                  : "sortColumn"
                              }
                              onClick={(e) => handleSortingCoulmn(e, "name")}
                            >
                              Name
                              <i
                                className={
                                  filterData.sortColumn === "name" &&
                                    filterData.sortOrder === "ASCENDING"
                                    ? "fa fa-arrow-up"
                                    : "fa fa-arrow-down"
                                }
                                aria-hidden="true"
                              ></i>
                            </div>
                          </th>
                          <th>
                            <div
                              className={
                                filterData.sortColumn === "CarrierName"
                                  ? "active"
                                  : "sortColumn"
                              }
                              onClick={(e) =>
                                handleSortingCoulmn(e, "CarrierName")
                              }
                            >
                              Carrier Provider
                              <i
                                className={
                                  filterData.sortColumn === "CarrierName" &&
                                    filterData.sortOrder === "ASCENDING"
                                    ? "fa fa-arrow-up"
                                    : "fa fa-arrow-down"
                                }
                                aria-hidden="true"
                              ></i>
                            </div>
                          </th>

                          <th>Effective From</th>
                          <th>Effective Till</th>
                          <th>
                            <div
                              className={
                                filterData.sortColumn === "IsActive"
                                  ? "active"
                                  : "sortColumn"
                              }
                              onClick={(e) =>
                                handleSortingCoulmn(e, "IsActive")
                              }
                            >
                              Status
                              <i
                                className={
                                  filterData.sortColumn === "IsActive" &&
                                    filterData.sortOrder === "ASCENDING"
                                    ? "fa fa-arrow-up"
                                    : "fa fa-arrow-down"
                                }
                                aria-hidden="true"
                              ></i>
                            </div>
                          </th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {AllRateCards &&
                          AllRateCards?.map((d) => (
                            <>
                              <tr key={d?.carrierId}>
                                <td>{d?.name}</td>
                                <td>{d?.carrierName}</td>
                                <td>
                                  {d?.effectiveFrom &&
                                    d?.effectiveFrom !== "0001-01-01T00:00:00"
                                    ? moment(
                                      d?.effectiveFrom,
                                      "MMM DD YYYY h:mmA"
                                    ).format("MM-DD-YYYY | h:mm:ss A")
                                    : "-----"}
                                </td>
                                <td>
                                  {d?.effectiveTo &&
                                    d?.effectiveTo != "0001-01-01T00:00:00"
                                    ? moment(
                                      d?.effectiveTo,
                                      "MMM DD YYYY h:mmA"
                                    ).format("MM-DD-YYYY |  h:mm:ss A")
                                    : "-----"}
                                </td>
                                <td>
                                  {d?.isActive ? (
                                    <span className="activeStatusBtn">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="activeStatusBtn inactiveBtn"
                                    >
                                      In Active
                                    </span>
                                  )}
                                </td>
                                <td colSpan={2}>
                                  {checkPermission(settings, SUBF_RATECARD, baseRateCardView, featureSubmodulePermission, props.employeePermissions) && (
                                    <img
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleViewClickRow(d)}
                                      src={eye_icon}
                                      className="editIcon mr-3"
                                      alt=""
                                    />)}
                                  {d?.isActive && checkPermission(settings, SUBF_RATECARD, baseRateCardEdit, featureSubmodulePermission, props.employeePermissions) && (
                                    <img
                                      className="editIcon"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleEditClickRow(d)}
                                      src={edit_icon}
                                      alt=""
                                    />
                                  )}
                                </td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </div>
            </div>
          </div>
          {AllRateCards && activeTab === 1 && (
            <BatchPagination
              gridData={AllRateCards}
              totalVisualRecords={AllRateCards.length}
              numberOfPage={filterData.pageSize}
              numOfPage={numOfPage}
              totalRecords={AllRateCardsRecord?.Entity?.TotalRecords}
              currentPage={filterData.pageIndex}
              totalPages={totalPages || 0}
              setCurrentPage={(value) => setFilterData({ ...filterData, pageIndex: value })}
              setNumberOfPage={(value) => setFilterData({ ...filterData, pageSize: value, pageIndex: 1 })}
            />
          )}
          {activeTab === 2 && (
            <SellerRateCardListing
              sortingColumn={filterData.sortColumn}
              handleSortingCoulmn={handleSortingCoulmn}
              sortOrder={filterData.sortOrder}
              permissions={permissions}
              renderList={renderList}
              AllSellerRateCards={AllSellerRateCards}
              activeTab={activeTab}
              sellernumberOfPage={filterData.pageSize}
              numOfPage={numOfPage}
              sellerCurrentPage={filterData.pageIndex}
              sellerTotalPages={sellerTotalPages || 0}
              AllSellerRateCardsRecord={AllSellerRateCardsRecord}
              setsellerCurrentPage={(value) => setFilterData({ ...filterData, pageIndex: value })}
              setsellernumberOfPage={(value) => setFilterData({ ...filterData, pageSize: value, pageIndex: 1 })}
            />
          )}
        </div>
      </div>

    </main >
  );
}

export default RateCard;
