import React, { useEffect, useState } from "react";
import LoaderSpinner from "../common/Loader";
import Table from "react-bootstrap/Table";
import ActionItems from "../common/showActions";
import { getConsignmentOutboundListing, updateOutboundStatus } from "../../store/actions/inventory.action";
import { useDispatch, useSelector } from "react-redux";
import { featureSubmodulePermission, inventoryManagement, numOfPage, permissionMarkedPickedOutbound, permssionCreate, permssionEdit, permssionView, SUBF_INBOUND, SUBF_OUTBOUND } from "../../constant";
import BatchPagination from "../batch/BatchPagination";
import FilterOutbound from "./filterOutbound";
import ViewImage from "../common/ViewImage";
import _ from "lodash";
import { getSellersforMultipleCompany } from "../../store/actions";
import SearchProduct from "../Inventory/Product/searchProduct";
import { checkPermission, renderTableRow } from "../../config/utils";
import { useNavigate } from "react-router";
import { outboundBackDetails } from "../../store/actions/employee";
import AlertDialogModal from "../common/AlertDialog";
import { toast } from "react-toastify";
import { BulkUploadDialog } from "../common/bulkUploadDialog";

const OutboundComponent = (props) => {
  const [outBoundlist, setOutBoundlist] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const { consignmentOutboundData, consignmentOutboundStatus } = useSelector(
    (state) => state.InventoryOutboundManagement
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedRecord, setSelectedRecord] = useState([]);
  const [showHideImageModal, setShowHideImageModal] = useState(false);
  const [allSellers, setAllSellers] = useState([]);
  const getMultipleSellers = useSelector(
    (state) => state?.company?.getMultipleCompanySellers
  );
  const [sellerList, setSellerList] = useState([]);
  const [csvModalShow, setCsvModalShow] = useState(false);
  const userDetails = useSelector((state) => state.auth.user);
  const { prevState } = useSelector((state) => state.employee);

  let dataLoad = {
    searchKeyword: '',
    pageIndex: 1,
    pageSize: 30,
    sortColumn: '',
    sortOrder: 'DESCENDING',
    carrierIdList: [],
    consignmentStatusIdList: [],
    consignmentTypeIdList: [],
    outboundStatusIdList: [],
    trackingNumber: "",
    productSku: "",
    totalPages: 1,
    totalRecords: 0,
    numberOfPage: 30,
    showSearch: false,
    searchTxt: '',
    showFilter: false,
    sortingColumn: '',
    outboundNumber: '',
    name: "",
    consignmentCompanyIdList: [],
    consignmentSellerIdList: [],
  };
  const [filterValue, setFilterValue] = useState({});

  const handleFilterToggle = (key, value) => {
    setFilterValue({ ...filterValue, [key]: value });
  }

  const setCurrentPagePagination = (value) => {
    setFilterValue({ ...filterValue, pageIndex: value });
  }
  const setTotalNumberOfPage = (value) => {
    setFilterValue({ ...filterValue, numberOfPage: value, pageIndex: 1 });
  }

  useEffect(() => {
    if (consignmentOutboundData?.TotalRecords) {
      setFilterValue({ ...filterValue, totalRecords: consignmentOutboundData?.TotalRecords, totalPages: Math.ceil(consignmentOutboundData?.TotalRecords / filterValue.numberOfPage) });
    }
    if (consignmentOutboundData?.OutboundList) {
      setOutBoundlist(consignmentOutboundData?.OutboundList);
    }
  }, [consignmentOutboundData]);

  useEffect(() => {
    if (!_.isEmpty(prevState)) {
      setFilterValue({ ...filterValue, ...prevState });
    } else {
      setFilterValue({
        searchKeyword: '',
        pageIndex: 1,
        pageSize: 30,
        sortColumn: '',
        sortOrder: 'DESCENDING',
        carrierIdList: [],
        consignmentStatusIdList: [],
        consignmentTypeIdList: [],
        outboundStatusIdList: [],
        trackingNumber: "",
        productSku: "",
        totalPages: 1,
        totalRecords: 0,
        numberOfPage: 30,
        showSearch: false,
        searchTxt: '',
        showFilter: false,
        sortingColumn: '',
        outboundNumber: '',
        name: "",
        consignmentCompanyIdList: [],
        consignmentSellerIdList: [],
      });
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(filterValue)) {
      fetchConsignmentOutboundLisiting();
    }
  }, [filterValue.numberOfPage, filterValue.pageIndex, filterValue.sortColumn, filterValue.sortOrder]);

  useEffect(() => {
    if (consignmentOutboundStatus === "pending") {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [consignmentOutboundStatus]);

  const handleSortingClass = (columnName, isArrow = false) => {
    if (isArrow) {
      return filterValue.sortColumn === columnName && filterValue.sortOrder === "ASCENDING"
        ? "fa fa-arrow-up"
        : "fa fa-arrow-down";
    }
    return filterValue.sortColumn === columnName ? "active" : "sortColumn";
  };

  const handleSortingColumn = (event, sortColumn) => {
    event.preventDefault();
    let newSortOrder = filterValue.sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING";
    setFilterValue({
      ...filterValue,
      sortColumn: sortColumn,
      sortOrder: newSortOrder,
      searchKeyword: filterValue.searchTxt,
    });
  };

  const handleSearchText = (value) => {
    setFilterValue({
      ...filterValue,
      searchTxt: value
    })
  }

  useEffect(() => {
    if (filterValue?.consignmentCompanyIdList?.length === 0) {
      setSellerList(allSellers);
      return;
    }
    if (
      getMultipleSellers?.Entity?.listSellerMasterOrdersEntity &&
      getMultipleSellers?.Entity?.listSellerMasterOrdersEntity.length > 0
    ) {
      let data = getMultipleSellers?.Entity?.listSellerMasterOrdersEntity.map(
        (item, index) => {
          return {
            ...item,
            label: item.sellerAndCompany,
            value: index + 1,
          };
        }
      );
      setSellerList([...data]);
    }
  }, [getMultipleSellers]);

  const fetchConsignmentOutboundLisiting = () => {
    let data = {
      ...filterValue,
      pageSize: filterValue.numberOfPage,
      sortColumn: filterValue.sortColumn,
      sortOrder: filterValue.sortOrder,
      searchKeyword: filterValue.searchTxt,
      consignmentTypeIdList: _.map(filterValue.consignmentTypeIdList, "id"),
      carrierIdList: _.map(filterValue.carrierIdList, "id"),
      outboundStatusIdList: _.map(filterValue.consignmentStatusIdList, "id"),
      trackingNumber: filterValue.trackingNumber,
      consignmentNumber: filterValue.consignmentNumber,
      sellerIdList: _.map(filterValue.consignmentSellerIdList, "id"),
      companyIdList: _.map(filterValue.consignmentCompanyIdList, "Id"),
      marketPlaceIdList: _.map(filterValue.marketPlaceIdList, "id"),
      productSku: filterValue.productSku,
      name: filterValue.name,
      outboundNumber: filterValue.outboundNumber,
    };
    dispatch(getConsignmentOutboundListing(data));
  };

  const handleSearchFilter = (e, type) => {
    if (type === "clear") {
      let data = JSON.parse(JSON.stringify(dataLoad));
      data.showFilter = false;
      data.numberOfPage = filterValue.numberOfPage;
      setFilterValue(data);
      dispatch(getConsignmentOutboundListing(data));
      setSellerList(allSellers)
      return;
    } else if (type === "search") {
      let data = JSON.parse(JSON.stringify(dataLoad));
      data.showSearch = true;
      data.searchTxt = '';
      setFilterValue(data);
    }
    fetchConsignmentOutboundLisiting();
  };

  const handleMultiSelect = (value, name) => {
    setFilterValue({ ...filterValue, [name]: value });
    if (name === "consignmentCompanyIdList") {
      let companyData = _.map(value, "Id");
      dispatch(getSellersforMultipleCompany(companyData));
      if (filterValue?.consignmentSellerIdList?.length) {
        const filteredSellers = filterValue.consignmentSellerIdList.filter(seller =>
          companyData.includes(parseInt(seller.companyIds))
        );
        setFilterValue({ ...filterValue, consignmentSellerIdList: filteredSellers, consignmentCompanyIdList: value })

      }
    }
  };

  const handleFilter = () => {
    let dataFilters = {
      ...filterValue,
      sortingColumn: '',
      sortOrder: 'DESCENDING',
      pageIndex: 1
    }
    fetchConsignmentOutboundLisiting();
    setFilterValue(dataFilters);
  };

  const handleNavigateToOutbound = (item, type = "") => {
    dispatch(outboundBackDetails(filterValue));
    if (type) {
      navigate(`/outbound/${item.id}`, {
        state: {
          data: item,
          userActionEdit: type === "edit",
          isEdit: item.outboundStatusId === 1 && type === "edit",
        },
      });
      return;
    }
    navigate(`/outbound/${item}`, {
      state: {
        data: item,
        isEdit: true,
        userActionEdit: '',
      },
    });
  };

  const handleHistoryPage = (item) => {
    dispatch(outboundBackDetails(filterValue));
    navigate(`/outbound/history-${item.id}`, {
      state: {
        data: item,
        editable: false,
        type: "outbound",
      },
    });
  }

  const handleCheckItem = (e, index) => {
    let { name, checked } = e.target;
    let outBoundlistClone = _.cloneDeep(outBoundlist);
    outBoundlistClone[index][name] = checked;
    setOutBoundlist(outBoundlistClone);
  }

  const handleChangeStatus = async (e) => {
    let findChecked = outBoundlist.filter(item => item.isChecked);
    let ids = _.map(findChecked, "id");
    setLoader(true);
    let statusRes = await Promise.resolve(dispatch(updateOutboundStatus(ids)));
    if (statusRes && statusRes.data.Status === 200) {
      setShowAlert(false);
      setLoader(false);
      handleSearchFilter(e, '');
      toast.success('Status updated successfully');
    } else {
      setShowAlert(false);
      toast.error('Something went wrong');
    }
  }

  return (
    <main>
      <div className="main__container">
        <div className="main__tile_heading cus-btn-include-sec">
          <span>Outbound</span>
          <div className="top_right_button_container">
            <div className="top_right_button_container">
              <button
                className="btn cus-seconday-bg-btn top-btn"
                onClick={() => setCsvModalShow(true)}
              >
                Bulk Upload
              </button>

              {checkPermission(inventoryManagement, SUBF_OUTBOUND, permssionCreate, featureSubmodulePermission, props.employeePermissions) && (
                <button
                  className="btn cus-seconday-bg-btn top-btn"
                  onClick={() => handleNavigateToOutbound(0)}
                >
                  Add New
                </button>
              )}

              <button
                className="btn cus-seconday-bg-btn top-btn addNewProduct"
                onClick={() => handleFilterToggle('showFilter', !filterValue.showFilter)}
              >
                Filter
              </button>
              {checkPermission(inventoryManagement, SUBF_OUTBOUND, permissionMarkedPickedOutbound, featureSubmodulePermission, props.employeePermissions) && (
                <button
                  className="btn cus-seconday-bg-btn top-btn addNewProduct"
                  onClick={() => setShowAlert(true)}
                  disabled={!outBoundlist.filter(item => item.isChecked).length}
                >
                  Marked as Picked
                </button>
              )}
              <button
                className="btn cus-seconday-bg-btn openSearch"
                onClick={() => handleFilterToggle('showSearch', !filterValue.showSearch)}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <div className="">
          <SearchProduct
            showSearch={filterValue.showSearch}
            searchTxt={filterValue.searchTxt}
            setSearchTxt={handleSearchText}
            handleReset={handleSearchFilter}
            handleSearchFilter={handleSearchFilter}
            placeHolderText="Search by SKU, Outbound Id, Name, Consignment Type, Carrier, Tracking Number or Outbound Status"
          />
        </div>

        {filterValue.showFilter && (
          <FilterOutbound
            showFilter={filterValue.showFilter}
            dispatch={dispatch}
            useSelector={useSelector}
            filterData={filterValue}
            handleMultiSelect={handleMultiSelect}
            handleFilter={handleFilter}
            handleReset={handleSearchFilter}
            numberOfPage={filterValue.numberOfPage}
            sellerList={sellerList}
            setSellerList={setSellerList}
            setAllSellers={setAllSellers}
          />
        )}

        {loader && <LoaderSpinner />}

        <div className="main_content cus-page-content-sec srch-pt0">
          <div className="main_content_table_history">
            <div className="tableFixHead incomingTbl">
              <Table
                className={`table table-height cus-left-right-col-layout table ${loader ? "disableTble" : ""
                  }`}
              >
                <thead className="tbl-btch">
                  <tr className="table-rw-batch">
                    {checkPermission(inventoryManagement, SUBF_OUTBOUND, permissionMarkedPickedOutbound, featureSubmodulePermission, props.employeePermissions) && (
                      <th width={'5%'}></th>
                    )}
                    <th width={'7%'}>Thumbnail</th>
                    {/*<th width={'12%'}>
                      <div
                        className={handleSortingClass("marketPlaceName")}
                        onClick={(e) => handleSortingColumn(e, "marketPlaceName")}
                        id="marketPlaceName"
                      >
                        Marketplace
                        <i
                          className={handleSortingClass("marketPlaceName", true)}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                     <th>
                      <div
                        className={handleSortingClass("marketPlaceOrderId")}
                        onClick={(e) => handleSortingColumn(e, "marketPlaceOrderId")}
                        id="marketPlaceOrderId"
                      >
                        Marketplace Order ID
                        <i
                          className={handleSortingClass("marketPlaceOrderId", true)}
                          aria-hidden="true"
                        ></i>
                      </div>

                    </th> */}
                    <th width={'10%'}>
                      <div
                        className={handleSortingClass("outboundNumber")}
                        onClick={(e) => handleSortingColumn(e, "outboundNumber")}
                        id="outboundNumber"
                      >
                        Outbound Id
                        <i
                          className={handleSortingClass("outboundNumber", true)}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                    {userDetails?.Value?.roleName !== 'Seller' && (
                      <th width={'15%'}>Company / Seller Name</th>
                    )}
                    <th width={'15%'}>
                      <div
                        className={handleSortingClass("consignmentType")}
                        onClick={(e) =>
                          handleSortingColumn(e, "consignmentType")
                        }
                        id="consignmentType"
                      >
                        Consignment Type
                        <i
                          className={handleSortingClass(
                            "consignmentType",
                            true
                          )}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                    <th width={'8%'}>
                      <div
                        className={handleSortingClass("carrierName")}
                        onClick={(e) => handleSortingColumn(e, "carrierName")}
                        id="carrierName"
                      >
                        Carrier
                        <i
                          className={handleSortingClass("carrierName", true)}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                    <th width={'10%'}>
                      <div
                        className={handleSortingClass("trackingNumber")}
                        onClick={(e) =>
                          handleSortingColumn(e, "trackingNumber")
                        }
                        id="trackingNumber"
                      >Tracking Number
                        <i
                          className={handleSortingClass("trackingNumber", true)}
                          aria-hidden="true"
                        ></i></div>
                    </th>
                    <th width={'8%'}>
                      <div
                        className={handleSortingClass("outboundstatus")}
                        onClick={(e) =>
                          handleSortingColumn(e, "outboundstatus")
                        }
                        id="consignmentStatus"
                      >
                        Status
                        <i
                          className={handleSortingClass("outboundstatus", true)}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                    <th width={'13%'}>Action</th>
                  </tr>
                </thead>
                <tbody className="tbl-bt-body">
                  {outBoundlist && outBoundlist.map((item, listIndex) => (
                    <tr key={item.listIndex}>
                      {checkPermission(inventoryManagement, SUBF_OUTBOUND, permissionMarkedPickedOutbound, featureSubmodulePermission, props.employeePermissions) && (
                        <td>
                          <input
                            type="checkbox"
                            name="isChecked"
                            checked={item.isChecked}
                            onChange={(e) => handleCheckItem(e, listIndex)}
                          />
                        </td>
                      )}
                      <td>
                        {item.thumbnail && (
                          <div
                            className="thumbImg"
                            onClick={() => {
                              setSelectedRecord({ imageUrl: item.thumbnail });
                              setShowHideImageModal(!showHideImageModal);
                            }}
                          >
                            <img
                              src={item.thumbnail}
                              alt=""
                              style={{ height: "24px" }}
                            />
                          </div>
                        )}
                      </td>
                      {/* <td>{item.marketPlaceName}</td>
                      <td>{item.marketPlaceOrderId}</td> */}
                      <td>{item.outboundNumber}</td>
                      {userDetails?.Value?.roleName !== 'Seller' && (
                        <td>{item.companySellerName}</td>
                      )}
                      <td>{item.consignmentType}</td>
                      <td>{item.carrierName}</td>
                      <td>{item.trackingNumber}</td>
                      <td>{item.outboundStatus}</td>
                      <td>
                        {checkPermission(inventoryManagement, SUBF_INBOUND, permssionView, featureSubmodulePermission, props.employeePermissions) && (
                          <ActionItems
                            type={"View"}
                            item={item}
                            handleAction={() =>
                              handleNavigateToOutbound(item, "view")
                            }
                          />
                        )}
                        {checkPermission(inventoryManagement, SUBF_INBOUND, permssionEdit, featureSubmodulePermission, props.employeePermissions) && (
                          <ActionItems
                            type={"Edit"}
                            item={item}
                            handleAction={() =>
                              handleNavigateToOutbound(item, "edit")
                            }
                          />
                        )}
                        <ActionItems
                          type={"History"}
                          item={item}
                          handleAction={() => handleHistoryPage(item)}
                        />
                      </td>
                    </tr>
                  ))}
                  {consignmentOutboundStatus === "success" &&
                    outBoundlist.length === 0 && (
                      <>{renderTableRow(9, "No Result Found...")}</>
                    )}
                </tbody>
              </Table>
            </div>
          </div>
          <ViewImage
            title={"Product Image"}
            selectedRecord={selectedRecord}
            setShowHideImageModal={setShowHideImageModal}
            showHideImageModal={showHideImageModal}
          />
          <BatchPagination
            gridData={outBoundlist}
            totalRecords={filterValue.totalRecords}
            numberOfPage={filterValue.numberOfPage}
            numOfPage={numOfPage}
            loader={loader}
            currentPage={filterValue.pageIndex}
            totalPages={filterValue.totalPages}
            setCurrentPage={setCurrentPagePagination}
            setNumberOfPage={setTotalNumberOfPage}
          />
        </div>
        <AlertDialogModal
          modalShow={showAlert}
          handleCancelDeleteAlert={() => setShowAlert(false)}
          modalClass={'modalDeleteWrapper'}
          messageText="Are you sure, you want to change status to picked?"
          handleYesDeleteAlert={handleChangeStatus}
          headingText={'STATUS'}
        />

        {csvModalShow && (
          <BulkUploadDialog
            onHide={() => setCsvModalShow(false)}
            show={csvModalShow}
            hideIntegration={true}
            type={2}
            renderData={handleSearchFilter}
          />
        )}
      </div>
    </main>
  );
};

export default OutboundComponent;
