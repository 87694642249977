import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../fundManagement/fundmanagement.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCompanyDetails,
  companyListBackDetails,
} from "../../store/actions/rateCard";
import { Button } from "bootstrap-4-react/lib/components";
import BatchPagination from "../batch/BatchPagination";
import AddCompanyModal from "../modal/AddCompanyModal";
import FilterCompany from "../company-Seller-Filter";
import CompanyList from "./list";
import _ from "lodash";
import { checkPermission } from "../../config/utils";
import { accountsManagement, featureSubmodulePermission, permssionCreate, SUBF_COMPANY } from "../../constant";
import SearchProduct from "../Inventory/Product/searchProduct";

const CompanyManagement = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getAllCompany, getAllCompanyStatus, companyBackDetails } = useSelector((state) => state.RateCardReducer);
  const [companyModalShow, setcompanyModalShow] = React.useState(false);
  const [totalPages, setTotalPages] = useState(1);
  let dataLoad = {
    pageIndex: 1,
    pageSize: 30,
    sortColumn: "id",
    sortOrder: 'DESCENDING',
    showFilter: false,
    showSearch: false,
    country: '',
    state: [],
  };
  const [filterValue, setFilterValue] = useState({});
  const numOfPage = [30, 50, 100, 200, 500, 1000, 2000];
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(companyBackDetails)) {
      setFilterValue({ ...filterValue, ...companyBackDetails });
    } else {
      setFilterValue({ ...dataLoad });
    }
  }, [])

  useEffect(() => {
    if (getAllCompanyStatus === 'PENDING') {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [getAllCompanyStatus])

  useEffect(() => {
    if (!_.isEmpty(filterValue)) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue.pageSize, filterValue.pageIndex, filterValue.sortColumn, filterValue.sortOrder, filterValue.state, filterValue.country]);

  const fetchData = () => {
    const labels = Array.isArray(filterValue?.state) ? filterValue?.state?.map(i => i.label) : filterValue?.state;
    let filterData = {
      ...filterValue,
      state: labels
    }
    dispatch(getAllCompanyDetails(setTotalPages, filterValue.pageSize, filterValue.pageIndex, filterData));
  }

  const handleAssociatedSellerPage = (companyId, sellerCount) => {
    dispatch(companyListBackDetails(filterValue))
    navigate(`/editCompany/comp-${companyId}`, {
      state: {
        companyId,
        associatedSeller: true,
      },
    });
  };
  const handleAssociatedUserPage = (companyId, sellerCount) => {
    dispatch(companyListBackDetails(filterValue))
    navigate(`/editCompany/comp-${companyId}`, {
      state: {
        companyId,
        associatedUser: true,
      },
    });
  };

  const handleSortingCoulmn = (event, sortColumn) => {
    event.preventDefault();
    let newSortOrder = filterValue.sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING";
    setFilterValue({
      ...filterValue,
      sortColumn: sortColumn,
      sortOrder: newSortOrder,
    });
  };

  const handleFilterToggle = (key, value) => {
    setFilterValue({ ...filterValue, [key]: value });
  }

  const handleSearchText = (searchTxt) => {
    setFilterValue({ ...filterValue, searchKeywords: searchTxt });
  }

  const handleSearchFilter = (e, type) => {
    if (type === 'clear') {
      setFilterValue({ ...filterValue, searchKeywords: '' });
      let data = {
        pageIndex: filterValue.currentPage,
        pageSize: filterValue.pageSize,
        sortColumn: filterValue.sortColumn,
        sortOrder: filterValue.sortOrder,
        searchKeywords: "",
      };
      dispatch(getAllCompanyDetails(setTotalPages, filterValue.pageSize, filterValue.pageIndex, data));
    } else {
      fetchData();
    }
  }

  return (
    <main>
      {companyModalShow && (
        <AddCompanyModal
          show={companyModalShow}
          onHide={() => setcompanyModalShow(false)}
        />
      )}

      <div className="main__container">
        <div className="main__tile_heading cus-btn-include-sec">
          Company Management
          <div className="top_right_button_container">
            <div className="selectYear filterIncoming">
              <button
                className="btn cus-seconday-bg-btn top-btn"
                onClick={() => setFilterValue({ ...filterValue, showSearch: !filterValue.showSearch })}
              >
                <i className="fa fa-search" aria-hidden="true"></i> Search
              </button>
              <Button
                className="cus-seconday-bg-btn top-btn FilterButton"
                onClick={() => handleFilterToggle('showFilter', !filterValue.showFilter)}
              >Filter
              </Button>
            </div>
            {checkPermission(accountsManagement, SUBF_COMPANY, permssionCreate, featureSubmodulePermission, props.employeePermissions) && (
              <Button
                className="cus-seconday-bg-btn cusbtn"
                onClick={() => setcompanyModalShow(true)}

              >
                Add Company
              </Button>
            )}
          </div>
        </div>
        <SearchProduct
          showSearch={filterValue.showSearch}
          searchTxt={filterValue.searchKeywords}
          setSearchTxt={handleSearchText}
          handleReset={handleSearchFilter}
          handleSearchFilter={handleSearchFilter}
          placeHolderText="Search by company name"
        />
        {filterValue.showFilter && (
          <FilterCompany
            setfilterData={setFilterValue}
            filterData={filterValue}
            dataLoad={dataLoad}
          />
        )}
        <div className="main_content_table_history incomingTbl">
          <div className="tableFixHead">
            <CompanyList
              {...props}
              sortingColumn={filterValue.sortColumn}
              handleSortingCoulmn={handleSortingCoulmn}
              sortOrder={filterValue.sortOrder}
              getAllCompany={getAllCompany}
              handleAssociatedSellerPage={handleAssociatedSellerPage}
              handleAssociatedUserPage={handleAssociatedUserPage}
              navigate={navigate}
              loader={loader}
              filterData={filterValue}
            />
          </div>
        </div>
        {getAllCompany?.Entity?.listCompanyMasterOrdersEntity && (
          <BatchPagination
            gridData={getAllCompany?.Entity?.listCompanyMasterOrdersEntity}
            totalVisualRecords={
              getAllCompany?.Entity?.listCompanyMasterOrdersEntity.length
            }
            numberOfPage={filterValue.pageSize}
            numOfPage={numOfPage}
            currentPage={filterValue.pageIndex}
            totalPages={totalPages || 0}
            totalRecords={getAllCompany?.Entity?.TotalRecords}
            setCurrentPage={(value) => setFilterValue({ ...filterValue, pageIndex: value })}
            setNumberOfPage={(value) => setFilterValue({ ...filterValue, pageSize: value })}
          />
        )}
      </div>
    </main>
  );
}

export default CompanyManagement;