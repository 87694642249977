import { serviceRateCardConstant } from "../constants/serviceRatrCard.constant";

const initialState = {
  ServiceTypeList: [],
  TransactionTypeList: [],
  PackingTypeList: [],
  SizeTypeList: [],
  UnitTypeList: [],
  BillingCycleTypeList: [],
  serviceRateCardList: [],
  serviceRateCardListStatus: []
}
const ServiceRateCardReducer = (state = initialState, action = '') => {
  const { type, payload } = action;
  switch (type) {
    case serviceRateCardConstant.GET_SERVICE_TYPE_LIST_SUCCESS:
      return {
        ...state,
        ServiceTypeList: payload,
      };
    case serviceRateCardConstant.GET_PACKING_TYPE_LIST_SUCCESS:
      return {
        ...state,
        PackingTypeList: payload,
      };
    case serviceRateCardConstant.GET_TRANSACTION_TYPE_LIST_SUCCESS:
      return {
        ...state,
        TransactionTypeList: payload,
      };
    case serviceRateCardConstant.GET_SIZE_TYPE_LIST_SUCCESS:
      return {
        ...state,
        SizeTypeList: payload,
      };
    case serviceRateCardConstant.GET_UNIT_TYPE_LIST_SUCCESS:
      return {
        ...state,
        UnitTypeList: payload,
      };
    case serviceRateCardConstant.GET_BILLING_CYCLE_TYPE_LIST_SUCCESS:
      return {
        ...state,
        BillingCycleTypeList: payload,
      };
    case serviceRateCardConstant.GET_ALL_SERVICE_RATECARD_LIST_PENDING:
      return {
        ...state,
        serviceRateCardListStatus: 'PENDING',
        serviceRateCardList: [],
      };
    case serviceRateCardConstant.GET_ALL_SERVICE_RATECARD_LIST_SUCCESS:
      return {
        ...state,
        serviceRateCardListStatus: 'SUCCESS',
        serviceRateCardList: payload,
      };
    case serviceRateCardConstant.GET_ALL_SERVICE_RATECARD_LIST_FAILED:
      return {
        ...state,
        serviceRateCardListStatus: 'FAILED',
        serviceRateCardList: [],
      };
    default:
      return state;
  }
}
export default ServiceRateCardReducer;