export const serviceRateCardConstant = {
    GET_SERVICE_TYPE_LIST_SUCCESS: 'GET_SERVICE_TYPE_LIST_SUCCESS',
    GET_TRANSACTION_TYPE_LIST_SUCCESS: 'GET_TRANSACTION_TYPE_LIST_SUCCESS',
    GET_PACKING_TYPE_LIST_SUCCESS: 'GET_PACKING_TYPE_LIST_SUCCESS',
    GET_SIZE_TYPE_LIST_SUCCESS: 'GET_SIZE_TYPE_LIST_SUCCESS',
    GET_UNIT_TYPE_LIST_SUCCESS: 'GET_UNIT_TYPE_LIST_SUCCESS',
    GET_BILLING_CYCLE_TYPE_LIST_SUCCESS: 'GET_BILLING_CYCLE_TYPE_LIST_SUCCESS',
    GET_ALL_SERVICE_RATECARD_LIST_SUCCESS: 'GET_ALL_SERVICE_RATECARD_LIST_SUCCESS',
    GET_ALL_SERVICE_RATECARD_LIST_FAILED: 'GET_ALL_SERVICE_RATECARD_LIST_FAILED',
    GET_ALL_SERVICE_RATECARD_LIST_PENDING: 'GET_ALL_SERVICE_RATECARD_LIST_PENDING',
}