import axios from "axios";
import { apiUrl } from "../constant";

const getServiceTypeListService = () => {
    return axios.get(`${apiUrl}/api/Accounts/ServiceTypeList`);
};

const getTransactionTypeListService = () => {
    return axios.get(`${apiUrl}/api/Accounts/TransactionTypeList`);
};

const getPackingTypeListService = () => {
    return axios.get(`${apiUrl}/api/Accounts/PackingTypeList`);
};

const getSizeTypeListService = () => {
    return axios.get(`${apiUrl}/api/Accounts/SizeTypeList`);
};

const getChargeUnitTypeListService = () => {
    return axios.get(`${apiUrl}/api/Accounts/ChargeUnitTypeList`);
};

const getBillingCycleListService = () => {
    return axios.get(`${apiUrl}/api/Accounts/BillingCycleList`);
};

const createUpdateServiceRateCardService = (data) => {
    return axios.post(`${apiUrl}/api/Accounts/CreateUpdateServiceRateCard`, data);
};

const getAllServiceRateCardListService = (data) => {
    return axios.post(`${apiUrl}/api/Accounts/GetAllServiceRateCard`, data);
};
export const ServiceRateCard = {
    getServiceTypeListService,
    getTransactionTypeListService,
    getPackingTypeListService,
    getSizeTypeListService,
    getChargeUnitTypeListService,
    getBillingCycleListService,
    createUpdateServiceRateCardService,
    getAllServiceRateCardListService
}