import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./fundmanagement.css";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import { GET_SINGLE_FUNDS_DATA } from "../../../src/store/types";
import TopUpModal from "../modal/TopUpModal/index";
import PriceAdjustModal from "../modal/priceAdjustModal/adjustModal";
import { checkPermission, formatNumberWithCommas, renderTableRow } from "../../config/utils";
import {
  fundmanagementBackDetails,
  getAllFundDetails,
  getLoginUserDetailsonFund,
  updateProcessingFee,
  updateTreshold,
} from "../../store/actions/fundManagement";
import history from "../../assets/icons/timer1.svg";
import AdjustmentIcon from "../../assets/adjustmentIcon.svg";
import AdjustmentIconWhite from "../../assets/adjustmentIconWhite.svg";
import WalletRecharge from "../../assets/walletRecharge.svg";
import WalletRechargeWhite from "../../assets/walletRechargeWhite.svg";
import TopUpIcon from "../../assets/topUp.svg";
import TopUpIconWhite from "../../assets/topUpWhite.svg";
import { BsPencil, BsSave2 } from "react-icons/bs";
import { fetchAllCompanyDetails } from "../../store/actions/rateCard";
import { Button } from "bootstrap-4-react/lib/components";
import BatchPagination from "../batch/BatchPagination";
import RechargeWallet from "../modal/RechargeWallet";
import { toast } from "react-toastify";
import LoaderSpinner from "../common/Loader";
import { featureSubmodulePermission, permssionAdjustment, permssionProcessingFee, permssionThreshold, permssionTopUp, permssionViewHistory, permssionWalletRecharge, settings, SUBF_FUNDMANAGEMENT } from "../../constant";
import SearchProduct from "../Inventory/Product/searchProduct";
import _ from "lodash";
import { Tooltip as WmsTooltip } from "react-tooltip";

export function Fundmanagement(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const UserValue = useSelector((state) => state.auth.user);
  const [activeSellerList, setActiveSellerList] = useState([]);
  const [fundList, setFundList] = useState([]);

  const fundDetails = useSelector(
    (state) =>
      state?.FundManagementReducer?.getAllFundDetails?.Entity
        ?.listFundMasterOrdersEntity
  );
  const fundDetailsRecord = useSelector(
    (state) => state?.FundManagementReducer?.getAllFundDetails
  );
  const fetchAllCompany = useSelector(
    (state) => state.RateCardReducer?.fetchAllCompany
  );
  const getAllFundDetailStatus = useSelector(
    (state) => state?.FundManagementReducer?.getAllFundDetailStatus
  );
  const fundManagementprevState = useSelector(
    (state) => state?.FundManagementReducer?.fundManagementprevState
  );

  let fundmanagementdataLoad = {
    pageIndex: 1,
    pageSize: 30,
    totalPages: 1,
    sortColumn: '',
    sortOrder: 'DESCENDING',
    totalRecords: 0,
    showSearch: false,
    searchKey: '',
  }
  const [filterData, setfilterData] = useState({});
  const [modalShow, setModalShow] = React.useState(false);
  const [showRechargePopup, setShowRechargePopup] = React.useState(false);
  const [priceModalShow, setpriceModalShow] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [editProcessing, setEditProcessing] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const numOfPage = [30, 50, 100, 200, 500, 1000, 2000];
  const [treshold, setTreshold] = useState();
  const [processingFee, setProcessingFee] = useState("");
  const [column, setColumn] = useState();

  useEffect(() => {
    if (fundDetails?.length) {
      setFundList([...fundDetails]);
    } else {
      setFundList([]);
    }
  }, [fundDetails]);

  useEffect(() => {
    if (!_.isEmpty(fundManagementprevState)) {
      setfilterData({ ...filterData, ...fundManagementprevState });
    } else {
      setfilterData(fundmanagementdataLoad);
    }
  }, []);

  useEffect(() => {
    // Extract the message from the query parameter
    const urlParams = new URLSearchParams(window.location.search);
    const messageParam = urlParams.get("message");
    toast(messageParam);
    navigate("/fundManagement");
  }, []);

  useEffect(() => {
    if (!modalShow) {
      dispatch(
        getLoginUserDetailsonFund({
          creditCompanySellerId: UserValue?.Value?.companySellerId,
        })
      );
    }
  }, [modalShow]);

  useEffect(() => {
    if (!_.isEmpty(filterData)) {
      getFundDetails();
    }
  }, [UserValue, filterData.pageSize, filterData.pageIndex, filterData.sortColumn, filterData.sortOrder]);

  const getFundDetails = () => {
    let data = {
      ...filterData,
      topupCompanyId: UserValue?.Value?.companyId,
      isAdmin: UserValue?.Value?.isAdmin,
      topupSellerId: UserValue?.Value?.sellerId,
      role: UserValue?.Value?.role,
      isCurrentUser: true,
    };
    dispatch(getAllFundDetails(data, setTotalPages, filterData));
  };

  useEffect(() => {
    let companyData = {
      ...filterData,
      screenType: 2,
    }
    dispatch(fetchAllCompanyDetails(companyData));
  }, [UserValue]);

  const handleSortingCoulmn = (event, sortColumn) => {
    event.preventDefault();
    let newSortOrder = filterData.sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING";
    setfilterData({
      ...filterData,
      sortColumn: sortColumn,
      sortOrder: newSortOrder,
    });
  };

  const handleSortingClass = (columnName, isArrow = false) => {
    if (isArrow) {
      return filterData.sortColumn === columnName && filterData.sortOrder === "ASCENDING"
        ? "fa fa-arrow-up"
        : "fa fa-arrow-down";
    }
    return filterData.sortColumn === columnName ? "active" : "sortColumn";
  };

  useEffect(() => {
    dispatch({
      type: GET_SINGLE_FUNDS_DATA,
      payload: [],
    });
  }, []);

  useEffect(() => {
    if (fetchAllCompany && fetchAllCompany?.length) {
      let data = fetchAllCompany?.map((item) => {
        return {
          ...item,
          value: item.Id,
          label: item.name,
        };
      });
      setActiveSellerList(data);
    }
  }, [fetchAllCompany]);

  const handleHistoryPage = (rowData) => {
    saveFilterDetails()
    navigate(
      `/fundManagement/history/fd-${rowData?.creditCompanySellerId}-3pl`,
      {
        state: {
          rowData,
        },
      }
    );
  };

  const setCurrentPagePagination = (value) => {
    setfilterData({ ...filterData, pageIndex: value });
  }
  const setTotalNumberOfPage = (value) => {
    setfilterData({ ...filterData, pageSize: value, pageIndex: 1 });
  }

  const onTresholdSave = async (data) => {
    let payload = {
      ...data,
      threshold: parseInt(treshold),
      createdBy: UserValue?.Value?.employeeId,
      role: UserValue?.Value?.role,
      isAdmin: UserValue?.Value?.isAdmin,
      companyId: UserValue?.Value?.companyId,
      sellerId: UserValue?.Value?.sellerId,
    };

    let res = await Promise.resolve(
      dispatch(updateTreshold(payload))
    );
    if (res?.data.Status === 200) {
      getFundDetails();
      toast.success("Request successfully");
    }
    setEdit(false);
    setTreshold(null);
  };

  const editColumn = (i) => {
    setEdit(true);
    setColumn(i);
    setEditProcessing(false);
  };

  const editProcessingColumn = (i) => {
    setEditProcessing(true);
    setColumn(i);
    setEdit(false);
  };

  const saveProcessingFee = async (data) => {
    let payload = {
      id: data.id,
      modifiedBy: UserValue?.Value?.employeeId,
      processFeePercent: Number(processingFee),
    };
    setEditProcessing(false);
    setProcessingFee(null);
    let res = await Promise.resolve(dispatch(updateProcessingFee(payload)));
    if (res?.data.Status === 200) {
      getFundDetails();
      toast.success("Request successfully");
    }
  };
  const saveFilterDetails = () => {
    dispatch(fundmanagementBackDetails(filterData))
  }

  const handleFilterToggle = (key, value) => {
    setfilterData({ ...filterData, [key]: value });
  }

  const handleSearchText = (value) => {
    setfilterData({
      ...filterData,
      searchKey: value
    })
  }

  const fetchSearchData = (search) => {
    let data = {
      pageIndex: filterData.currentPage,
      pageSize: filterData.pageSize,
      sortColumn: filterData.sortColumn,
      sortOrder: filterData.sortOrder,
      searchKey: search,
      topupCompanyId: UserValue?.Value?.companyId,
      isAdmin: UserValue?.Value?.isAdmin,
      topupSellerId: UserValue?.Value?.sellerId,
      role: UserValue?.Value?.role,
      isCurrentUser: true,
    };
    dispatch(getAllFundDetails(data, setTotalPages, filterData));

  }

  const handleSearchFilter = () => {
    fetchSearchData(filterData.searchKey)
    setfilterData({ ...filterData, pageIndex: 1 });
    saveFilterDetails();
  };

  const handleReset = () => {
    setfilterData({ ...filterData, searchKey: '' });
    fetchSearchData("");
  };

  const handleSelectRecord = (event, row) => {
    let { checked } = event.target;
    let fundData = fundList.map((item) => {
      return {
        ...item,
        isChecked: item.id === row.id ? checked : item.isChecked
      }
    });
    setFundList(fundData);
  }

  const handleSingleRecords = (e, data) => {
    let fundData = fundList.map((item) => {
      return {
        ...item,
        isChecked: item.id === data.id ? true : false
      }
    });
    setFundList(fundData);
  }


  return (
    <main>
      {modalShow && (
        <TopUpModal
          show={modalShow}
          activeSellerList={activeSellerList}
          onHide={() => setModalShow(false)}
          fundList={fundList}
          filterData={filterData}
        />
      )}

      {priceModalShow && (
        <PriceAdjustModal
          show={modalShow}
          activeSellerList={activeSellerList}
          onHide={() => setpriceModalShow(false)}
          fundList={fundList}
        />
      )}
      <div className="main__container">
        <div className="main__tile_heading cus-btn-include-sec zIndex2">
          Fund Management
          <div className="top_right_button_container">
            <Button
              className="cus-seconday-bg-btn openSearch m-2 fullIconBtn"
              onClick={() => handleFilterToggle('showSearch', !filterData.showSearch)}
            >
              <i className="fa fa-search" aria-hidden="true"></i>
            </Button>
            {checkPermission(settings, SUBF_FUNDMANAGEMENT, permssionWalletRecharge, featureSubmodulePermission, props.employeePermissions) && (
              <>
                <Button
                  className="cus-seconday-bg-btn top-btn fullIconBtn"
                  onClick={() => setShowRechargePopup(true)}
                  id="walletRecharge"
                >
                  <img
                    src={WalletRechargeWhite}
                    alt=""
                    className="mr-1"
                  /> Wallet Recharge
                </Button>
                <WmsTooltip
                  anchorId={`walletRecharge`}
                  content={'Wallet Recharge'}
                  place="bottom"
                  className="tooltipWidth tootipText"
                />
              </>
            )}
            {checkPermission(settings, SUBF_FUNDMANAGEMENT, permssionTopUp, featureSubmodulePermission, props.employeePermissions) && (
              <>
                <Button
                  className="cus-seconday-bg-btn top-btn fullIconBtn"
                  onClick={() => setModalShow(true)}
                  id="topUpBtn"
                >
                  <img
                    src={TopUpIconWhite}
                    alt=""
                    className="mr-1"
                  /> Top Up
                </Button>
                <WmsTooltip
                  anchorId={`topUpBtn`}
                  content={'Top Up'}
                  place="bottom"
                  className="tooltipWidth tootipText"
                />
              </>
            )}
            {checkPermission(settings, SUBF_FUNDMANAGEMENT, permssionAdjustment, featureSubmodulePermission, props.employeePermissions) && (
              <>
                <Button
                  className="cus-seconday-bg-btn top-btn ajustmentBtn fullIconBtn"
                  onClick={() => setpriceModalShow(true)}
                  id="ajustmentBtn"
                >
                  <img
                    src={AdjustmentIconWhite}
                    alt=""
                    className="mr-1"
                  /> Adjustment
                </Button>
                <WmsTooltip
                  anchorId={`ajustmentBtn`}
                  content={'Adjustment'}
                  place="bottom"
                  className="tooltipWidth tootipText"
                />
              </>
            )}
            <Button
              className="cus-primary-transparent-btn"
              onClick={() => {
                navigate("/setting");
                dispatch(fundmanagementBackDetails({}));
              }}
            >
              Back
            </Button>
          </div>
        </div>
        <div className="zIndex1">
          <SearchProduct
            showSearch={filterData.showSearch}
            searchTxt={filterData.searchKey}
            setSearchTxt={handleSearchText}
            handleReset={handleReset}
            handleSearchFilter={handleSearchFilter}
            placeHolderText="Search by Company, Seller name"
          />
        </div>
        {getAllFundDetailStatus === 'PENDING' && <LoaderSpinner />}
        <div className="main_content_table_history incomingTbl tableFixHead zIndex1">
          <Table className="table table-height cus-left-right-col-layout fundMngTble">
            <thead>
              <tr>
                <th></th>
                <th>
                  <div
                    className={handleSortingClass("companyName")}
                    onClick={(e) => handleSortingCoulmn(e, "companyName")}
                  >
                    Company Name
                    <i
                      className={handleSortingClass("companyName", true)}
                      aria-hidden="true"
                    ></i>
                  </div>
                </th>
                <th>
                  <div
                    className={handleSortingClass("sellerName")}
                    onClick={(e) => handleSortingCoulmn(e, "sellerName")}
                  >
                    Seller Name
                    <i
                      className={handleSortingClass("sellerName", true)}
                      aria-hidden="true"
                    ></i>
                  </div>
                </th>
                <th>
                  <div
                    className={handleSortingClass("availableAmount")}
                    style={{ textAlign: "left" }}
                    onClick={(e) => handleSortingCoulmn(e, "availableAmount")}
                  >
                    Available Balance ($)
                    <i
                      className={handleSortingClass("availableAmount", true)}
                      aria-hidden="true"
                    ></i>
                  </div>
                </th>
                <th>
                  <div
                    className={handleSortingClass("Threshold")}
                    style={{ textAlign: "left" }}
                    onClick={(e) => handleSortingCoulmn(e, "Threshold")}
                  >
                    Threshold Value ($)
                    <i
                      className={handleSortingClass("Threshold", true)}
                      aria-hidden="true"
                    ></i>
                  </div>
                </th>
                {checkPermission(settings, SUBF_FUNDMANAGEMENT, permssionProcessingFee, featureSubmodulePermission, props.employeePermissions) && (
                  <th>
                    <div
                      className={handleSortingClass("processFeePercent")}
                      style={{ textAlign: "left" }}
                      onClick={(e) =>
                        handleSortingCoulmn(e, "processFeePercent")
                      }
                    >
                      Processing Fees (%)
                      <i
                        className={handleSortingClass("processFeePercent", true)}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </th>
                )}
                <th>History</th>
              </tr>
            </thead>
            <tbody>
              {fundList?.map((data, i) => (
                <>
                  <tr key={data?.id}>
                    <td>
                      <input
                        type="checkbox"
                        onChange={(e) => handleSelectRecord(e, data)}
                        checked={data?.isChecked}
                        name="checkbox"
                        className="selectRecord"
                        disabled={fundList.filter(item => item.isChecked).length === 1 && !data?.isChecked}
                      />
                    </td>
                    <td>{data?.companyName}</td>
                    <td>{data?.sellerName}</td>
                    <td className="alignLeft">
                      {formatNumberWithCommas(data?.availableAmount)}
                    </td>
                    <td className="alignLeft">
                      {edit && column === i ? (
                        <>
                          <div className="d-inline-flex">
                            <div className="thresh-input-1">
                              <input
                                className="cus-input-cls thresh-data"
                                onChange={(e) => setTreshold(e.target.value)}
                                defaultValue={data?.threshold}
                                type="number"
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                              />
                            </div>
                            <div className="fund-tbl">
                              <BsSave2
                                className="penconEditIcon"
                                onClick={() => onTresholdSave(data)}
                                title="Save"
                              ></BsSave2>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="d-inline-flex">
                            <div>{formatNumberWithCommas(data?.threshold)}</div>
                            <div className="fund-tbl">
                              {checkPermission(settings, SUBF_FUNDMANAGEMENT, permssionThreshold, featureSubmodulePermission, props.employeePermissions) && (
                                <BsPencil
                                  className="cus-pencil-icon-sec View-Icon"
                                  onClick={() => editColumn(i)}
                                  title="Edit"
                                ></BsPencil>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </td>
                    {checkPermission(settings, SUBF_FUNDMANAGEMENT, permssionProcessingFee, featureSubmodulePermission, props.employeePermissions) && (
                      <td className="alignLeft">
                        {editProcessing && column === i ? (
                          <div className="d-inline-flex">
                            <div className="thresh-input-1">
                              <input
                                className="cus-input-cls thresh-data"
                                onChange={(e) =>
                                  setProcessingFee(e.target.value)
                                }
                                defaultValue={data?.processFeePercent}
                                type="number"
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                              />
                            </div>
                            <div className="fund-tbl">
                              <BsSave2
                                className="penconEditIcon"
                                title="Save"
                                onClick={() => saveProcessingFee(data)}
                              ></BsSave2>
                            </div>
                          </div>
                        ) : (
                          <div className="d-inline-flex">
                            <div className="">
                              {formatNumberWithCommas(data.processFeePercent)}
                            </div>
                            <div className="fund-tbl">
                              <BsPencil
                                className="cus-pencil-icon-sec View-Icon"
                                onClick={() => editProcessingColumn(i)}
                                title="Edit"
                              />
                            </div>
                          </div>
                        )}
                      </td>
                    )}
                    <td>

                      {checkPermission(settings, SUBF_FUNDMANAGEMENT, permssionWalletRecharge, featureSubmodulePermission, props.employeePermissions) && (
                        <>
                          <img
                            src={WalletRecharge}
                            alt=""
                            className="mr-2 cursorPointer"
                            id={`walletRecharge-${data?.id}`}
                            onClick={(e) => {
                              handleSelectRecord(e, data);
                              setShowRechargePopup(true);
                            }}
                          />
                          <WmsTooltip
                            anchorId={`walletRecharge-${data?.id}`}
                            content={'Wallet Recharge'}
                            place="bottom"
                            className="tooltipWidth tootipText"
                          />
                        </>
                      )}

                      {checkPermission(settings, SUBF_FUNDMANAGEMENT, permssionTopUp, featureSubmodulePermission, props.employeePermissions) && (
                        <>
                          <img
                            src={TopUpIcon}
                            alt=""
                            className="mr-2 cursorPointer"
                            id={`topUpBtn-${data?.id}`}
                            onClick={(e) => {
                              handleSingleRecords(e, data);
                              setModalShow(true);
                            }}
                          />
                          <WmsTooltip
                            anchorId={`topUpBtn-${data?.id}`}
                            content={'Top Up'}
                            place="bottom"
                            className="tooltipWidth tootipText"
                          />
                        </>
                      )}

                      {checkPermission(settings, SUBF_FUNDMANAGEMENT, permssionAdjustment, featureSubmodulePermission, props.employeePermissions) && (
                        <>
                          <img
                            src={AdjustmentIcon}
                            alt=""
                            className="mr-2 cursorPointer"
                            id={`ajustmentBtn-${data?.id}`}
                            onClick={(e) => {
                              handleSingleRecords(e, data);
                              setpriceModalShow(true);
                            }}
                          />
                          <WmsTooltip
                            anchorId={`ajustmentBtn-${data?.id}`}
                            content={'Adjustment'}
                            place="bottom"
                            className="tooltipWidth tootipText"
                          />
                        </>
                      )}

                      {checkPermission(settings, SUBF_FUNDMANAGEMENT, permssionViewHistory, featureSubmodulePermission, props.employeePermissions) && (
                        <>
                          <img
                            className="history-Icon"
                            onClick={() => handleHistoryPage(data)}
                            src={history}
                            alt=""
                            id={`history-${data?.id}`}
                          />
                          <WmsTooltip
                            anchorId={`history-${data?.id}`}
                            content={'History'}
                            place="bottom"
                            className="tooltipWidth tootipText"
                          />
                        </>
                      )}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
            {getAllFundDetailStatus === 'SUCCESS' && fundDetails.length === 0 && (
              <>{renderTableRow(6, "No Result Found...")}</>
            )}
          </Table>
        </div>
        {showRechargePopup && (
          <RechargeWallet
            showModal={showRechargePopup}
            userDetails={UserValue}
            setShowRechargePopup={setShowRechargePopup}
            dispatch={dispatch}
          />
        )}

        {fundDetails && (
          <BatchPagination
            gridData={fundDetails}
            totalVisualRecords={fundDetails.length}
            numberOfPage={filterData?.pageSize}
            numOfPage={numOfPage}
            currentPage={filterData?.pageIndex}
            totalPages={totalPages || 0}
            totalRecords={fundDetailsRecord?.Entity?.TotalRecords}
            setCurrentPage={setCurrentPagePagination}
            setNumberOfPage={setTotalNumberOfPage}
          />
        )}
      </div>
    </main >
  );
}