import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { deleteIntegration, getLoginUrl, loadOrders, getMarketplaceCountriesList } from '../../store/actions/marketplace.action';
import { Button } from "bootstrap-4-react/lib/components";
import { fetchAddressFromZipCode, getAllCountries, getStates, GetSellerSavedAddress } from '../../store/actions';
import { setLocalStorageItem } from '../../services/StorageService';
import { toast } from "react-toastify";
import { checkPermission, isValidEmail } from '../../config/utils';
import InputMask from "react-input-mask";
import AlertDialogModal from '../common/AlertDialog';
import LoaderSpinner from '../common/Loader';
import { featureSubmodulePermission, permssionDelete, settings, SUBF_MARKETPLACEINTEGRATION, validUsZip } from '../../constant';
import DatePickerComponent from '../common/DatePickerComponent';

const IntegrationConfigure = (props) => {
    const [loader, setLoader] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isRedirecting, setIsRedirecting] = useState(false);
    const countries = useSelector((state) => state.shipment.countries);
    const USSates = useSelector((state) => state.shipment.states);
    const userDetails = useSelector((state) => state.auth.user);
    const listAction = [
        { name: 'Manual', id: '1', value: 'internally_fulfilled' },
        { name: 'Direct', id: '2', value: 'externally_fulfilled' },
    ];
    const [marketplacelist, setMarketPlaceList] = useState([]);

    const etsyData = {
        etsy_shop_id: 0,
        skip_order_update: false,
        expected_ship_date_flag: false
    };


    useEffect(() => {
        fetchSavedAddress();
    }, []);

    const handleOverrideAddress = (overrideAddress) => {
        let addressData = overrideAddress[0];
        return ({
            isActive: true,
            Address_1: addressData.addressFirst,
            Address_2: addressData.addressSecond,
            City: addressData.city,
            State: addressData.state,
            Zip: addressData.zipCode,
            Country: addressData.country,
        })
    }
    const fetchSavedAddress = async () => {
        let resData = await Promise.resolve(dispatch(GetSellerSavedAddress(userDetails?.Value?.sellerId)));
        if (resData && resData.data.Status === 200) {
            let senderAddress = resData.data.Entity;
            let overrideAddress = senderAddress.AddressModels;
            setFormData({
                ...formData,
                senderAddress: {
                    Override_Company_and_Name: {
                        isActive: true,
                        Company: senderAddress.selectedCompanyIds[0].CompanyName,
                        Name: senderAddress.newSellerName,
                    },
                    Override_Address: handleOverrideAddress(overrideAddress),
                    Override_Phone: {
                        isActive: true,
                        Phone: overrideAddress[0].phoneNumber
                    },
                    Override_Email: {
                        isActive: true,
                        Email: senderAddress.email
                    }
                },
            })
        }
    }

    useEffect(() => {
        if (!countries.length) {
            dispatch(getAllCountries());
        }
    }, [countries]);

    useEffect(() => {
        if (!USSates.length) {
            dispatch(getStates(218));
        }
    }, [USSates]);

    const fetchMarketPlaceList = async () => {
        const resData = await getMarketplaceCountriesList();
        const listdata = resData.data.EntityList;
        setMarketPlaceList(listdata);
        setLoader(false);
    };

    useEffect(() => {
        fetchMarketPlaceList();
    }, []);

    const [error, setError] = useState({
        senderAddress: {
            Override_Company_and_Name: {
                isActive: false,
                Company: '',
                Name: ''
            },
            Override_Address: {
                isActive: false,
                Address_1: '',
                Address_2: '',
                City: '',
                State: '',
                Zip: '',
                Country: '',
            },
            Override_Phone: {
                isActive: false,
                Phone: ''
            },
            Override_Email: {
                isActive: false,
                Email: ''
            }
        },
        returnAddress: {
            Override_Return_To_Address: {
                isActive: false,
                Company: "",
                Name: "",
                Address_1: "",
                Address_2: "",
                City: "",
                State: "",
                Zip: "",
                Country: "",
                Phone: "",
                Email: "",
            },
        }
    });
    const dispatch = useDispatch();
    const location = useLocation();
    const integrationData = location?.state?.data;
    const backUrl = location?.state?.backUrl;
    const navigate = useNavigate();
    useEffect(() => {
        if (!integrationData) {
            navigate('/market-place');
        }
    }, [integrationData]);

    const formDataIntegration = {
        send_confirmation_mail: false,
        action_type: 'internally_fulfilled',
        active: true,
        skip_address_pre_verification: false,
        include_packing_slips_in_confirmation_email: false,
        load_item_dimensions: false,
        number_of_days_of_orders: '14',
        ignore_weight_checkbox: false,
        ignore_package_weight_checkbox: false,
        save_asin_as_sku_checkbox: false,
        redownload_all_orders_on_next_sync_checkbox: false,
        pending_shipment_statuses: 'Completed',
        marketplace_dropdown_url: 'Choose One...',
        senderAddress: {
            Override_Company_and_Name: {
                isActive: true,
                Company: '',
                Name: ''
            },
            Override_Address: {
                isActive: true,
                Address_1: '',
                Address_2: '',
                City: '',
                State: '',
                Zip: '',
                Country: '',
            },
            Override_Phone: {
                isActive: true,
                Phone: ''
            },
            Override_Email: {
                isActive: true,
                Email: ''
            }
        },
        returnAddress: {
            Override_Return_To_Address: {
                isActive: false,
                Company: "",
                Name: "",
                Address_1: "",
                Address_2: "",
                City: "",
                State: "",
                Zip: "",
                Country: "",
                Phone: "",
                Email: "",
            },
        },
        auto_refresh: false,
        integration_id: '',
        order_quantity: '0',
        refresh_token: '',
        type: '',
    }

    const [formData, setFormData] = useState({
        seller_id: String(userDetails?.Value?.sellerId),
        company_id: String(userDetails?.Value?.companyId),
        integration_name: integrationData?.channel_name,
        company_seller_id: String(userDetails?.Value?.companySellerId),
        pending_shipment_statuses: 'Completed',
        channel_id: integrationData?.channel_id,
        ...formDataIntegration,
        ...(integrationData?.channel_name === 'Etsy' && { ...etsyData }),
        ...(location?.state?.isIntegration ? { ...integrationData } : {})
    });

    const handleLogin = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoader(true);
            setIsRedirecting(true);
            setLocalStorageItem('redirect', JSON.stringify(formData));
            let data = { "channel_id": integrationData?.channel_id }
            let resData = await getLoginUrl(data);
            if (resData && resData?.data?.Status === 200 && resData.data?.Entity) {
                window.open(resData.data?.Entity?.url, '_self');
                setLoader(false);
                setIsRedirecting(false);
            } else {
                setLoader(false);
                setIsRedirecting(false);
            }
        }
    }

    const handleUserInput = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleUserInputCheckbox = (e, type = '') => {
        const { name, checked } = e.target;
        setFormData({
            ...formData,
            [name]: checked
        });
    }

    const checkNumberOfDaysExceeded = (isNumberOfDaysExceeded) => {
        return isNumberOfDaysExceeded > 30;
    }

    const validateOverRideAddress = (overRideAddress, fieldsError, isValid) => {
        if (!overRideAddress.Address_1) {
            fieldsError.senderAddress.Override_Address.Address_1 = 'error';
            isValid = false;
        }
        if (!overRideAddress.City) {
            fieldsError.senderAddress.Override_Address.City = 'error';
            isValid = false;
        }
        if (!overRideAddress.State) {
            fieldsError.senderAddress.Override_Address.State = 'error';
            isValid = false;
        }
        if (!overRideAddress.Zip) {
            fieldsError.senderAddress.Override_Address.Zip = 'error';
            isValid = false;
        }
        if (!overRideAddress.Country) {
            fieldsError.senderAddress.Override_Address.Country = 'error';
            isValid = false;
        }

        if (overRideAddress.Country && overRideAddress.Zip) {
            if (overRideAddress.Country === 'US' && !validUsZip.test(overRideAddress.Zip)) {
                fieldsError.senderAddress.Override_Address.Zip = 'error';
                isValid = false;
            }
        }

        return { fieldsError, isValid }
    }

    const validateOverideCompany = (overrideData, fieldsError, isValid) => {
        if (!overrideData.Company || !overrideData.Name) {
            if (!overrideData.Company) {
                fieldsError.senderAddress.Override_Company_and_Name.Company = 'error';
            }
            if (!overrideData.Name) {
                fieldsError.senderAddress.Override_Company_and_Name.Name = 'error';
            }
            isValid = false;
        }
        return { fieldsError, isValid }
    }

    const validateForm = () => {
        let isValid = true;
        let fieldsError = { ...error };
        const numberOfDaysOfOrders = Number(formData.number_of_days_of_orders);

        if ((checkNumberOfDaysExceeded(numberOfDaysOfOrders))) {
            fieldsError.number_of_days_of_orders = 'error';
            isValid = false;
        }

        const overrideData = formData.senderAddress.Override_Company_and_Name;
        if (overrideData.isActive) {
            let dataOveride = validateOverideCompany(overrideData, fieldsError, isValid);
            fieldsError = { ...fieldsError, ...dataOveride.fieldsError };
            isValid = dataOveride.isValid;
        }

        let isSenderAddress = formData.senderAddress.Override_Phone;
        if (isSenderAddress.isActive) {
            if (validatePhone(isSenderAddress)) {
                fieldsError.senderAddress.Override_Phone.Phone = 'error';
                isValid = false;
            }
        }

        let isSenderAddressOverride_Email = formData.senderAddress.Override_Email;
        if (isSenderAddressOverride_Email.isActive) {
            if (validateEmail(isSenderAddressOverride_Email)) {
                fieldsError.senderAddress.Override_Email.Email = 'error';
                isValid = false;
            }
        }

        let overRideAddress = formData.senderAddress.Override_Address;
        if (overRideAddress.isActive) {
            let data = validateOverRideAddress(overRideAddress, fieldsError, isValid);
            fieldsError = { ...fieldsError, ...data.fieldsError };
            isValid = data.isValid;
        }

        if (formData.returnAddress.Override_Return_To_Address.isActive) {
            let returnAddressObj = validateReturnAddress(fieldsError, isValid);
            fieldsError = { ...fieldsError, ...returnAddressObj.fieldsError };
            isValid = returnAddressObj.isValid;
        }
        setError(fieldsError);
        return isValid
    }

    const validatePhone = (isSenderAddress) => {
        return !isSenderAddress.Phone || isSenderAddress.Phone.toString().endsWith("_");
    }

    const validateEmail = (isSenderAddressOverride_Email) => {
        return !isSenderAddressOverride_Email.Email || !isSenderAddressOverride_Email.Email.match(isValidEmail);
    }

    const validateCountryAndZip = (country, zipcode, fieldsError, isValid) => {
        let isError = '';
        if (country === 'US' && !validUsZip.test(zipcode)) {
            isError = 'error';
            isValid = false;
        }
        return { fieldsError, isValid, isError };
    }

    const validateReturnAddress = (fieldsError, isValid) => {
        let override_AddressObj = formData.returnAddress.Override_Return_To_Address;
        if (!override_AddressObj.Address_1) {
            fieldsError.returnAddress.Override_Return_To_Address.Address_1 = 'error';
            isValid = false;
        }
        if (!override_AddressObj.City) {
            fieldsError.returnAddress.Override_Return_To_Address.City = 'error';
            isValid = false;
        }
        if (!override_AddressObj.State) {
            fieldsError.returnAddress.Override_Return_To_Address.State = 'error';
            isValid = false;
        }
        if (!override_AddressObj.Zip) {
            fieldsError.returnAddress.Override_Return_To_Address.Zip = 'error';
            isValid = false;
        }
        if (!override_AddressObj.Country) {
            fieldsError.returnAddress.Override_Return_To_Address.Country = 'error';
            isValid = false;
        }
        if (override_AddressObj.Country && override_AddressObj.Zip) {
            let data = validateCountryAndZip(override_AddressObj.Country, override_AddressObj.Zip, fieldsError, isValid);
            isValid = data.isValid;
            fieldsError.returnAddress.Override_Return_To_Address.Zip = data.isError;
        }

        if (validatePhone(override_AddressObj)) {
            fieldsError.returnAddress.Override_Return_To_Address.Phone = 'error';
            isValid = false;
        }
        if (!override_AddressObj.Email) {
            fieldsError.returnAddress.Override_Return_To_Address.Email = 'error';
            isValid = false;
        }

        return { fieldsError, isValid }
    }

    const handleUserInputAddreess = (e, address = '', type = '', inputCheck = false) => {
        const { name, checked, value } = e.target;
        if (inputCheck) {
            const clonedData = { ...formData };
            clonedData[address][type][name] = checked;
            setFormData(clonedData);
        } else {
            const clonedData = { ...formData };
            const clonedErrorData = { ...error };
            clonedData[address][type][name] = value;
            clonedErrorData[address][type][name] = '';
            setFormData(clonedData);
            setError(clonedErrorData);
        }
    };

    const handleLoadOrder = async () => {
        let data = {
            "company_seller_id": String(userDetails.Value.companySellerId),
            "company_id": String(userDetails?.Value?.companyId),
            "seller_id": String(userDetails?.Value?.sellerId),
            "action_type": formData.action_type,
            "channel_id": integrationData?.channel_id,
            "integration_id": integrationData?.integration_id
        }
        setLoader(true);
        let resData = await loadOrders(data);
        if (resData && resData.data.Status === 200) {
            toast.success('Orders loaded successfully');
            setLoader(false);
        } else {
            setLoader(false);
        }
    }

    const hanldeDeleteIntegration = async () => {
        let data = { ...formData, action: "delete" };
        setLoader(true);
        let deleteRes = await deleteIntegration(data);
        if (deleteRes.data && deleteRes.data.Status === 200) {
            toast.success('Deleted successfully');
            navigate('/marketplace-integration');
            setShowAlert(false);
        }
        setShowAlert(false);
        setLoader(false);
    };

    const hanldeSaveIntegration = async () => {
        if (validateForm()) {
            setLoader(true);
            let data = { ...formData, action: "save", order_quantity: '0' };
            let saveDataRes = await Promise.resolve(deleteIntegration(data));
            if (saveDataRes?.data && saveDataRes?.data?.Status === 200) {
                toast.success('Save data successfully');
                setShowAlert(false);
            }
            setShowAlert(false);
            setLoader(false);
        }
    }

    const renderEbay = () => {
        return (
            <>
                <div className='row mb-10'>
                    <div className='col-7 alightVerticalCenter'>Load Item Dimensions</div>
                    <div className='col-5'>
                        <input
                            className='checkboxGeneral load_item_dimensions'
                            type={'checkbox'}
                            disabled
                            name="load_item_dimensions"
                            checked={formData.load_item_dimensions}
                            onChange={(e) => handleUserInputCheckbox(e)}
                        />
                    </div>
                </div>
                <div className='row mb-10'>
                    <div className='col-7 alightVerticalCenter'>Number of days</div>
                    <div className='col-5'>
                        <input
                            className={`form-control number_of_days_of_orders ${error?.number_of_days_of_orders && 'error'}`}
                            type={'number'}
                            step="1"
                            max={30}
                            min={1}
                            name="number_of_days_of_orders"
                            value={formData.number_of_days_of_orders}
                            onChange={(e) => handleUserInput(e)}
                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                        />
                    </div>
                    <div className='col-7 alightVerticalCenter'></div>
                    <i className='col-5'>Load this many days of orders (up to 30)</i>
                </div>
                <div className='row'>
                    <div className='col-7 alightVerticalCenter'>Pending Shipment Statuses</div>
                    <div className='col-5 alightVerticalCenter'>
                        <input
                            className='form-control'
                            type={'text'}
                            disabled
                            name='pending_shipment_statuses'
                            value='Completed'
                        />
                    </div>
                </div>
            </>
        )
    }

    const renderAmazon = () => {
        return (
            <>
                <div className='row mb-10'>
                    <div className='col-7 alightVerticalCenter'>MarketPlace</div>
                    <div className='col-5'>
                        <select
                            className={`form-control marketplace_dropdown_url`}
                            name={'marketplace_dropdown_url'}
                            value={formData.marketplace_dropdown_url}
                            onChange={(e) => handleUserInput(e)}
                        >
                            <option value="" selected disabled>Choose one...</option>
                            {Object.keys(marketplacelist).map((key) => (
                                <option value={marketplacelist[key]} key={key?.id}>{marketplacelist[key]}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='row mb-10'>
                    <div className='col-7 alightVerticalCenter'>Ignore Weight</div>
                    <div className='col-5'>
                        <input
                            className='checkboxGeneral ignore_weight_checkbox'
                            type={'checkbox'}
                            name="ignore_weight_checkbox"
                            checked={formData.ignore_weight_checkbox}
                            onChange={(e) => handleUserInputCheckbox(e)}
                        />
                    </div>
                </div>
                <div className='row mb-10'>
                    <div className='col-7 alightVerticalCenter'>Ignore Package Weight</div>
                    <div className='col-5'>
                        <input
                            className='checkboxGeneral ignore_package_weight_checkbox'
                            type={'checkbox'}
                            name="ignore_package_weight_checkbox"
                            checked={formData.ignore_package_weight_checkbox}
                            onChange={(e) => handleUserInputCheckbox(e)}
                        />
                    </div>
                </div>
                <div className='row mb-10'>
                    <div className='col-7 alightVerticalCenter'>Save ASIN number as SKU</div>
                    <div className='col-5'>
                        <input
                            className='checkboxGeneral save_asin_as_sku_checkbox'
                            type={'checkbox'}
                            name="save_asin_as_sku_checkbox"
                            checked={formData.save_asin_as_sku_checkbox}
                            onChange={(e) => handleUserInputCheckbox(e)}
                        />
                    </div>
                </div>
                <div className='row mb-10'>
                    <div className='col-7 alightVerticalCenter'>Redownload all orders on next sync</div>
                    <div className='col-5'>
                        <input
                            className='checkboxGeneral redownload_all_orders_on_next_sync_checkbox'
                            type={'checkbox'}
                            name="redownload_all_orders_on_next_sync_checkbox"
                            checked={formData.redownload_all_orders_on_next_sync_checkbox}
                            onChange={(e) => handleUserInputCheckbox(e)}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-7 alightVerticalCenter'>Pending Shipment Statuses</div>
                    <div className='col-5 alightVerticalCenter'>
                        <input
                            className='form-control pending_shipment_statuses'
                            type={'text'}
                            disabled
                            name='pending_shipment_statuses'
                            value={formData.pending_shipment_statuses}
                        />
                    </div>
                </div>
            </>
        )
    }

    const renderSopifySettings = () => {
        return (
            <>
                <div className='row mb-3'>
                    <div className='col-7 alightVerticalCenter'>Shopify Shop Name</div>
                </div>
                <div className='row mb-3'>
                    <div className='col-7 alightVerticalCenter'>
                        Map Cart Attribute to Due by
                    </div>
                    <div className='col-5 fullDatePicker'>
                        <DatePickerComponent
                            dateField={formData?.mapCartDue || null}
                            fieldName={'mapCartDue'}
                            handleDateChange={handleDateChange}
                        />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-7 alightVerticalCenter'>Use Order Name Instead of Order Number</div>
                    <div className='col-5'>
                        <input
                            className='checkboxGeneral ignore_package_weight_checkbox'
                            type={'checkbox'}
                            name="ignore_package_weight_checkbox"
                            checked={formData.ignore_package_weight_checkbox}
                            onChange={(e) => handleUserInputCheckbox(e)}
                        />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-7 alightVerticalCenter'>Financial Statuses</div>
                    <div className='col-5'>
                        Paid, Partially Refunded
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-7 alightVerticalCenter'>Include orders that are in "Payment pending" status</div>
                    <div className='col-5'>
                        <input
                            className='checkboxGeneral redownload_all_orders_on_next_sync_checkbox'
                            type={'checkbox'}
                            name="redownload_all_orders_on_next_sync_checkbox"
                            checked={formData.redownload_all_orders_on_next_sync_checkbox}
                            onChange={(e) => handleUserInputCheckbox(e)}
                        />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-7'>Don't retrieve Item Attributes</div>
                    <div className='col-5'>
                        <input
                            className='checkboxGeneral mr-1'
                            type={'checkbox'}
                            name="dontRetrieve"
                            checked={formData.dontRetrieve}
                            onChange={(e) => handleUserInputCheckbox(e)}
                        />
                        Don't retrieve order line item arrtributes
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-7'>Redownload all orders on next sync</div>
                    <div className='col-5'>
                        <input
                            className='checkboxGeneral'
                            type={'checkbox'}
                            name="redownload"
                            checked={formData.redownload}
                            onChange={(e) => handleUserInputCheckbox(e)}
                        />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-7 alightVerticalCenter'>Sync commodities with product from this store</div>
                    <div className='col-5 alightVerticalCenter'>
                        <input
                            className='checkboxGeneral'
                            type={'checkbox'}
                            name="syncCommodities"
                            checked={formData.syncCommodities}
                            onChange={(e) => handleUserInputCheckbox(e)}
                        />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-7 alightVerticalCenter'>Redownload all products on next sync</div>
                    <div className='col-5 alightVerticalCenter'>
                        <input
                            className='checkboxGeneral'
                            type={'checkbox'}
                            name="reDownloadProducts"
                            checked={formData.reDownloadProducts}
                            onChange={(e) => handleUserInputCheckbox(e)}
                        />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-7 alightVerticalCenter'>Pending Shipment Statuses</div>
                    <div className='col-5 alightVerticalCenter'>
                        <input
                            className='form-control pending_shipment_statuses'
                            type={'text'}
                            disabled
                            name='pending_shipment_statuses'
                            value={'unshipped, partial'}
                        />
                    </div>
                </div>
            </>
        )
    }

    const renderClass = (error, defaultXlass = '') => {
        return `form-control ${defaultXlass} ${error ? 'error' : ''}`;
    }

    const handleAddressByZip = (address, type) => {
        const copyShipmentObj = { ...formData };
        if (type === "sender") {
            copyShipmentObj.senderAddress.Override_Address.Country =
                countries.filter((country) => country.name === address?.Country)[0]
                    .iSO || copyShipmentObj.senderAddress.Override_Address.Country;
            copyShipmentObj.senderAddress.Override_Address.State =
                address.Country === "United States"
                    ? USSates.filter((state) => state.StateName === address?.State)[0]
                        .StateName
                    : address?.State;
            copyShipmentObj.senderAddress.Override_Address.City = address.City || copyShipmentObj.senderAddress.Override_Address.City;
        } else if (type === "receiver") {
            copyShipmentObj.returnAddress.Override_Return_To_Address.Country =
                countries.filter((country) => country.name === address?.Country)[0]
                    .iSO || copyShipmentObj.returnAddress.Override_Return_To_Address.Country;;
            copyShipmentObj.returnAddress.Override_Return_To_Address.State =
                address.Country === "United States"
                    ? USSates.filter((state) => state.StateName === address?.State)[0]
                        .StateName
                    : address?.State;
            copyShipmentObj.returnAddress.Override_Return_To_Address.City = address.City || copyShipmentObj.fCity;
        }
        setFormData(copyShipmentObj);
    }

    const fetchAddress = async (zipCode, type) => {
        let responseAddress = await Promise.resolve(dispatch(fetchAddressFromZipCode(zipCode)));
        if (responseAddress?.data?.Status === 200) {
            let address = responseAddress?.data?.Entity;
            if (address && address?.Country) {
                handleAddressByZip(address, type);
            }
        }
    };

    const renderSenderAddress = () => {
        let data = formData.senderAddress.Override_Company_and_Name;
        let errors = { ...error?.senderAddress?.Override_Company_and_Name };
        let returnToAddress = formData.returnAddress.Override_Return_To_Address;
        let returnAddressError = error?.returnAddress.Override_Return_To_Address;
        return (
            <div className='col-lg-4 col-sm-12 mobMb-10'>
                <div className='row mb-10'>
                    <div className='col-12'>
                        <div className='heading'>Sender Address</div>
                        <div className='descriptionText mb-10'>
                            If the override configurations are enabled below, we will prioritize them as the Sender Address. If not, then we will consider the sender address included with the order. If neither are specified, we will default to this user's sender address.
                        </div>

                        <div className='labelInput'>
                            <input
                                type={'checkbox'}
                                checked={data.isActive}
                                name={'isActive'}
                                id={'Override_Company_and_Name.isActive'}
                                className='senderAddressCheckBox'
                                onChange={(e) => handleUserInputAddreess(e, 'senderAddress', 'Override_Company_and_Name', true)}
                            /> <label htmlFor='Override_Company_and_Name.isActive'>Override Company and Name</label>
                            {data.isActive && (
                                <div className='subFieldsWrapper'>
                                    <div className='mb-10'>
                                        <div className='mb-10'>Company</div>
                                        <div>
                                            <input
                                                type={'text'}
                                                className={renderClass(errors?.Company)}
                                                name={'Company'}
                                                maxLength="35"
                                                value={data.Company}
                                                onChange={(e) => handleUserInputAddreess(e, 'senderAddress', 'Override_Company_and_Name')}
                                            />
                                        </div>
                                    </div>
                                    <div className='mb-10'>
                                        <div className='mb-10'>Name</div>
                                        <div>
                                            <input
                                                type={'text'}
                                                className={renderClass(errors?.Name)}
                                                name="Name"
                                                value={data.Name}
                                                onChange={(e) => handleUserInputAddreess(e, 'senderAddress', 'Override_Company_and_Name')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className='labelInput'>
                            <input
                                type={'checkbox'}
                                checked={formData.senderAddress.Override_Address.isActive}
                                name={'isActive'}
                                className="override_AddressCheckbox"
                                id="Override_Address.isActive"
                                onChange={(e) => handleUserInputAddreess(e, 'senderAddress', 'Override_Address', true)}
                            /> <label htmlFor='Override_Address.isActive'>Override Address</label>

                            {formData.senderAddress.Override_Address.isActive && (
                                <div className='subFieldsWrapper'>
                                    <div className='mb-10'>
                                        <div className='mb-10'>Address 1</div>
                                        <div>
                                            <input
                                                type={'text'}
                                                className={renderClass(error?.senderAddress.Override_Address.Address_1)}
                                                name={'Address_1'}
                                                value={formData.senderAddress.Override_Address.Address_1}
                                                onChange={(e) => handleUserInputAddreess(e, 'senderAddress', 'Override_Address')}
                                            />
                                        </div>
                                    </div>
                                    <div className='mb-10'>
                                        <div className='mb-10'>Address 2</div>
                                        <div>
                                            <input
                                                type={'text'}
                                                className='form-control'
                                                name={'Address_2'}
                                                value={formData.senderAddress.Override_Address.Address_2}
                                                onChange={(e) => handleUserInputAddreess(e, 'senderAddress', 'Override_Address')}
                                            />
                                        </div>
                                    </div>
                                    <div className='mb-10'>
                                        <div className='mb-10'>City</div>
                                        <div>
                                            <input
                                                type={'text'}
                                                className={renderClass(error?.senderAddress.Override_Address.City)}
                                                name={'City'}
                                                value={formData.senderAddress.Override_Address.City}
                                                onChange={(e) => handleUserInputAddreess(e, 'senderAddress', 'Override_Address')}
                                            />
                                        </div>
                                    </div>
                                    <div className='mb-10'>
                                        <div className='mb-10'>State</div>
                                        <div>
                                            {formData.senderAddress.Override_Address.Country === 'US' ? (
                                                <select
                                                    className={renderClass(error?.senderAddress.Override_Address.State, 'form-control Override_Address_State')}
                                                    name={'State'}
                                                    value={formData.senderAddress.Override_Address.State}
                                                    onChange={(e) => handleUserInputAddreess(e, 'senderAddress', 'Override_Address')}
                                                >
                                                    <option value="">Select</option>
                                                    {USSates.length && USSates.map((state) => (
                                                        <option value={state.StateName} key={state.Id}>{state.StateName}</option>
                                                    ))}
                                                </select>
                                            ) : (
                                                <input
                                                    type={'text'}
                                                    className={renderClass(error?.senderAddress.Override_Address.State)}
                                                    name={'State'}
                                                    value={formData.senderAddress.Override_Address.State}
                                                    onChange={(e) => handleUserInputAddreess(e, 'senderAddress', 'Override_Address')}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className='mb-10'>
                                        <div className='mb-10'>Zip</div>
                                        <div>
                                            <input
                                                type={'text'}
                                                className={renderClass(error?.senderAddress.Override_Address.Zip, 'Override_AddressZip')}
                                                name={'Zip'}
                                                value={formData.senderAddress.Override_Address.Zip}
                                                onChange={(e) => handleUserInputAddreess(e, 'senderAddress', 'Override_Address')}
                                                onBlur={(e) => fetchAddress(e.target.value, 'sender')}
                                            />
                                        </div>
                                    </div>
                                    <div className='mb-10'>
                                        <div className='mb-10'>Country</div>
                                        <div>
                                            <select
                                                className={renderClass(error?.senderAddress.Override_Address.Country, 'form-control override_Company_and_Name')}
                                                name={'Country'}
                                                value={formData.senderAddress.Override_Address.Country}
                                                onChange={(e) => handleUserInputAddreess(e, 'senderAddress', 'Override_Address')}
                                            >
                                                <option value="">{formData.senderAddress.Override_Address.Country}</option>
                                                {countries.length && countries.map((country) => (
                                                    <option value={country.iSO} key={country.id}>{country.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className='labelInput'>
                            <input
                                type={'checkbox'}
                                checked={formData.senderAddress.Override_Phone.isActive}
                                name={'isActive'}
                                className="override_Phone_Checkbox"
                                id="Override_Phone.isActive"
                                onChange={(e) => handleUserInputAddreess(e, 'senderAddress', 'Override_Phone', true)}
                            /> <label htmlFor='Override_Phone.isActive'>Override Phone</label>

                            {formData.senderAddress.Override_Phone.isActive && (
                                <div className='subFieldsWrapper'>
                                    <div className='mb-10'>
                                        <div className='mb-10'>Phone</div>
                                        <div>
                                            <InputMask
                                                type="text"
                                                className={renderClass(error?.senderAddress.Override_Phone.Phone)}
                                                value={formData.senderAddress.Override_Phone.Phone}
                                                onChange={(e) => handleUserInputAddreess(e, 'senderAddress', 'Override_Phone')}
                                                name="Phone"
                                            // mask="(+9) 999 999 9999"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className='labelInput'>
                            <input
                                type={'checkbox'}
                                checked={formData.senderAddress.Override_Email.isActive}
                                name={'isActive'}
                                id="Override_Email.isActive"
                                className="Override_Email_checkBox"
                                onChange={(e) => handleUserInputAddreess(e, 'senderAddress', 'Override_Email', true)}
                            /> <label htmlFor='Override_Email.isActive'>Override Email</label>
                            {formData.senderAddress.Override_Email.isActive && (
                                <div className='subFieldsWrapper'>
                                    <div className='mb-10'>
                                        <div className='mb-10'>Email</div>
                                        <div>
                                            <input
                                                type={'text'}
                                                className={renderClass(error?.senderAddress.Override_Email.Email, 'Override_Email_Email')}
                                                name={'Email'}
                                                value={formData.senderAddress.Override_Email.Email}
                                                onChange={(e) => handleUserInputAddreess(e, 'senderAddress', 'Override_Email')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className='row mb-10'>
                    <div className='col-12'>
                        <div className='heading'>Return To Address</div>
                        <div className='descriptionText mb-10'>
                            First we look to see if a return address is included with the order. If nothing is specified, we will use the overrides configured below. If neither are specified, we will default to this user's return address.
                        </div>
                        <div className='labelInput'>
                            <input
                                type={'checkbox'}
                                checked={returnToAddress.isActive}
                                name={'isActive'}
                                className='checkReturnAdd'
                                id="Override_Return_To_Address.isActive"
                                onChange={(e) => handleUserInputAddreess(e, 'returnAddress', 'Override_Return_To_Address', true)}
                            /> <label htmlFor='Override_Return_To_Address.isActive'>Override Return To Address</label>

                            {returnToAddress.isActive && (
                                <div className='subFieldsWrapper'>
                                    <div className='mb-10'>
                                        <div>Address 1</div>
                                        <div>
                                            <input
                                                type={'text'}
                                                className={renderClass(returnAddressError.Address_1, 'form-control checkReturnAdd')}
                                                name={'Address_1'}
                                                value={returnToAddress.Address_1}
                                                onChange={(e) => handleUserInputAddreess(e, 'returnAddress', 'Override_Return_To_Address')}
                                            />
                                        </div>
                                    </div>
                                    <div className='mb-10'>
                                        <div>Address 2</div>
                                        <div>
                                            <input
                                                type={'text'}
                                                className='form-control checkReturnAdd'
                                                name={'Address_2'}
                                                value={returnToAddress.Address_2}
                                                onChange={(e) => handleUserInputAddreess(e, 'returnAddress', 'Override_Return_To_Address')}
                                            />
                                        </div>
                                    </div>
                                    <div className='mb-10'>
                                        <div>Country</div>
                                        <div>
                                            <select
                                                className={renderClass(returnAddressError.Country, 'form-control countrySelect override_Company_and_Name')}
                                                name={'Country'}
                                                value={returnToAddress.Country}
                                                onChange={(e) => handleUserInputAddreess(e, 'returnAddress', 'Override_Return_To_Address')}
                                            >
                                                <option value="">Select</option>
                                                {countries.length && countries.map((country) => (
                                                    <option value={country.iSO} key={country.id}>{country.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className='mb-10'>
                                        <div>State</div>
                                        <div>
                                            {returnToAddress.Country === 'US' ? (
                                                <select
                                                    className={renderClass(returnAddressError.State, 'form-control userSelectElem checkReturnAdd')}
                                                    name={'State'}
                                                    value={returnToAddress.State}
                                                    onChange={(e) => handleUserInputAddreess(e, 'returnAddress', 'Override_Return_To_Address')}
                                                >
                                                    <option value='0'>Select</option>
                                                    {USSates.length && USSates.map((state) => (
                                                        <option disabled={state.id === 0} value={state.StateName} key={state.Id}>{state.StateName}</option>
                                                    ))}
                                                </select>
                                            ) : (
                                                <input
                                                    type={'text'}
                                                    className={renderClass(returnAddressError.State, 'form-control checkReturnAdd')}
                                                    name={'State'}
                                                    value={returnToAddress.State}
                                                    onChange={(e) => handleUserInputAddreess(e, 'returnAddress', 'Override_Return_To_Address')}
                                                />

                                            )}
                                        </div>
                                    </div>
                                    <div className='mb-10'>
                                        <div>City</div>
                                        <div>
                                            <input
                                                type={'text'}
                                                className={renderClass(returnAddressError.City, 'form-control checkReturnAdd')}
                                                name={'City'}
                                                value={returnToAddress.City}
                                                onChange={(e) => handleUserInputAddreess(e, 'returnAddress', 'Override_Return_To_Address')}
                                            />
                                        </div>
                                    </div>
                                    <div className='mb-10'>
                                        <div>Zip</div>
                                        <div>
                                            <input
                                                type={'text'}
                                                className={renderClass(returnAddressError.Zip, 'form-control checkReturnAdd returnToAddress')}
                                                name={'Zip'}
                                                value={returnToAddress.Zip}
                                                onChange={(e) => handleUserInputAddreess(e, 'returnAddress', 'Override_Return_To_Address')}
                                                onBlur={(e) => fetchAddress(e.target.value, 'receiver')}
                                            />
                                        </div>
                                    </div>
                                    <div className='mb-10'>
                                        <div>Phone</div>
                                        <div>
                                            <InputMask
                                                type="text"
                                                className={renderClass(returnAddressError.Phone, 'form-control checkReturnAdd')}
                                                name={'Phone'}
                                                value={returnToAddress.Phone}
                                                onChange={(e) => handleUserInputAddreess(e, 'returnAddress', 'Override_Return_To_Address')}
                                                mask="(999) 999 9999"
                                            />
                                        </div>
                                    </div>
                                    <div className='mb-10'>
                                        <div>Email</div>
                                        <div>
                                            <input
                                                type={'text'}
                                                className={renderClass(returnAddressError.Email, 'form-control checkEmail checkReturnAdd')}
                                                name={'Email'}
                                                value={returnToAddress.Email}
                                                onChange={(e) => handleUserInputAddreess(e, 'returnAddress', 'Override_Return_To_Address')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const handleDateChange = (date) => {
        setFormData({
            ...formData,
            mapCartDue: date
        })
    }

    return (
        <main>
            <div className="main__container">
                <div className="main__tile_heading cus-btn-include-sec">Configuration
                    <div className="top_right_button_container">
                        <button className='btn cus-seconday-bg-btn top-btn' onClick={handleLogin} disabled={loader}>Connect Integration</button>
                        {location?.state?.isIntegration &&
                            <>
                                <button className='btn cus-seconday-bg-btn top-btn saveIntegration' onClick={hanldeSaveIntegration}>Save Integration</button>
                                {checkPermission(settings, SUBF_MARKETPLACEINTEGRATION, permssionDelete, featureSubmodulePermission, props.employeePermissions) && (
                                    <button className='btn cus-seconday-bg-btn top-btn deleteInt' onClick={() => setShowAlert(true)}>Delete Integration</button>
                                )}
                                <button className='btn cus-seconday-bg-btn top-btn refreshBtn' onClick={handleLoadOrder} disabled={loader}>Refresh</button>
                            </>
                        }

                        <Button
                            className="cus-primary-transparent-btn"
                            onClick={() => navigate(backUrl)}
                        >Back</Button>
                    </div>
                </div>
                {loader && <LoaderSpinner />}

                {loader && isRedirecting && (
                    <div className='redirectingOverly'>
                        Redirecting
                    </div>
                )}

                <div className="cus-page-content-sec">
                    <div className="top_right_button_container cus-content-only-sec">
                        <div className='incoming_wrapper main_content_table integrationDetails'>
                            <div className='row pl-pr mb-10'>
                                <div className='col-lg-8 col-sm-12 mobMb-10'>
                                    <div className='heading'>General</div>
                                    <div className='row mb-10'>
                                        <div className='col-7 alightVerticalCenter'>Integration Name</div>
                                        <div className='col-5 alightVerticalCenter'>
                                            <input
                                                className='form-control integration_name'
                                                type={'text'}
                                                name="integration_name"
                                                value={formData.integration_name}
                                                onChange={(e) => handleUserInput(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mb-15'>
                                        <div className='col-7 alightVerticalCenter'>Send Confirmation Email</div>
                                        <div className='col-5 alightVerticalCenter'>
                                            <input
                                                disabled
                                                className='checkboxGeneral'
                                                type={'checkbox'}
                                                name="send_confirmation_mail"
                                                checked={formData.send_confirmation_mail}
                                                onChange={(e) => handleUserInputCheckbox(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mb-15'>
                                        <div className='col-7 alightVerticalCenter'>Active</div>
                                        <div className='col-5 alightVerticalCenter'>
                                            <input
                                                className='checkboxGeneral'
                                                type={'checkbox'}
                                                name="active"
                                                checked={formData.active}
                                                disabled={!location?.state?.isIntegration}
                                                onChange={(e) => handleUserInputCheckbox(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mb-15'>
                                        <div className='col-7 alightVerticalCenter'>Skip Address Pre Verification</div>
                                        <div className='col-5 alightVerticalCenter'>
                                            <input
                                                className='checkboxGeneral'
                                                type={'checkbox'}
                                                name="skip_address_pre_verification"
                                                checked={formData.skip_address_pre_verification}
                                                onChange={(e) => handleUserInputCheckbox(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mb-15'>
                                        <div className='col-7 alightVerticalCenter'>Include packing slips in confirmation email</div>
                                        <div className='col-5 alightVerticalCenter'>
                                            <input
                                                className='checkboxGeneral'
                                                type={'checkbox'}
                                                disabled
                                                name="include_packing_slips_in_confirmation_email"
                                                checked={formData.include_packing_slips_in_confirmation_email}
                                                onChange={(e) => handleUserInputCheckbox(e)}
                                            />
                                        </div>
                                    </div>

                                    <div className='row mb-15'>
                                        <div className='col-7 alightVerticalCenter'>Label generation type</div>
                                        <div className='col-5'>
                                            <select
                                                className={`form-control`}
                                                name={'action_type'}
                                                value={formData.action_type}
                                                onChange={(e) => handleUserInput(e)}
                                            >
                                                {listAction.length > 0 && listAction.map((action) => (
                                                    <option value={action.value} key={action.id}>{action.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className='row mb-15'>
                                        <div className='col-7 alightVerticalCenter'>
                                            <p>
                                                Auto-Refresh
                                                <div><i>Your orders will be fetched every one hour automatically</i></div>
                                            </p>
                                        </div>
                                        <div className='col-5 alightVerticalCenter'>
                                            <input
                                                className='checkboxGeneral'
                                                type={'checkbox'}
                                                name="auto_refresh"
                                                checked={formData.auto_refresh}
                                                onChange={(e) => handleUserInputCheckbox(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className='heading'>{integrationData?.channel_name} Settings</div>
                                    {integrationData?.channel_name === 'Etsy' && (
                                        <>
                                            <div className='row mb-10'>
                                                <div className='col-7 alightVerticalCenter'>Etsy Shop ID</div>
                                                <div className='col-5'>
                                                    <input
                                                        className='form-control etsy_shop_id'
                                                        type={'text'}
                                                        name="etsy_shop_id"
                                                        checked={formData.etsy_shop_id}
                                                        onChange={(e) => handleUserInputCheckbox(e)}
                                                    />
                                                </div>
                                                <div className='col-7 alightVerticalCenter'></div>
                                                <i className='col-5'>
                                                    The term 'Etsy' is a trademark of Etsy, Inc. This application uses the Etsy API but is not endorsed or certified by Etsy, Inc.
                                                </i>
                                            </div>
                                            <div className='row mb-10'>
                                                <div className='col-7 alightVerticalCenter'>Skip Order Update</div>
                                                <div className='col-5'>
                                                    <input
                                                        className='checkboxGeneral skip_order_update'
                                                        type={'checkbox'}
                                                        name="skip_order_update"
                                                        checked={formData.skip_order_update}
                                                        onChange={(e) => handleUserInputCheckbox(e)}
                                                    />
                                                </div>
                                            </div>

                                            <div className='row mb-10'>
                                                <div className='col-7 alightVerticalCenter'>Import Etsy's "Expected Ship Date" as Due -by-date</div>
                                                <div className='col-5'>
                                                    <input
                                                        className='checkboxGeneral expected_ship_date_flag'
                                                        type={'checkbox'}
                                                        name="expected_ship_date_flag"
                                                        checked={formData.expected_ship_date_flag}
                                                        onChange={(e) => handleUserInputCheckbox(e)}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {integrationData?.channel_name === 'Ebay' && (
                                        <>{renderEbay()}</>
                                    )}

                                    {integrationData?.channel_name === 'Amazon' && (
                                        <>{renderAmazon()}</>
                                    )}
                                    {integrationData?.channel_name === 'Shopify' && (
                                        <>{renderSopifySettings()}</>
                                    )}
                                </div>

                                {renderSenderAddress()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                showAlert && (
                    <AlertDialogModal
                        modalShow={showAlert}
                        handleCancelDeleteAlert={() => setShowAlert(false)}
                        modalClass={'modalDeleteWrapper'}
                        messageText="Are you sure, you want to delete this integration?"
                        handleYesDeleteAlert={hanldeDeleteIntegration}
                        headingText={'Delete Integration'}
                    />
                )
            }
        </main >
    );
}

export default IntegrationConfigure;