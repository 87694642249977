import { getSelectList } from "../../config/utils";
import { ServiceRateCard } from "../../services/serviceRateCard.service";
import { serviceRateCardConstant } from "../constants/serviceRatrCard.constant";


const getServiceTypeListSuccess = (response) => ({
    type: serviceRateCardConstant.GET_SERVICE_TYPE_LIST_SUCCESS,
    payload: response,
});

export const getServiceTypeList = () => {
    return (dispatch) => {
        ServiceRateCard.getServiceTypeListService().then((res) => {
            let createList = getSelectList(res.data.EntityList, 'id', 'services');
            dispatch(getServiceTypeListSuccess(createList));
        }).catch((err) => {
            console.log(err);
        });
    };
};

const getTransactionTypeListSuccess = (response) => ({
    type: serviceRateCardConstant.GET_TRANSACTION_TYPE_LIST_SUCCESS,
    payload: response,
});

export const getTransactionTypeList = () => {
    return (dispatch) => {
        ServiceRateCard.getTransactionTypeListService().then((res) => {
            let createList = getSelectList(res.data.EntityList, 'id', 'transactionType');
            dispatch(getTransactionTypeListSuccess(createList));
        }).catch((err) => {
            console.log(err);
        });
    };
};

const getPackingTypeListSuccess = (response) => ({
    type: serviceRateCardConstant.GET_PACKING_TYPE_LIST_SUCCESS,
    payload: response,
});

export const getPackingTypeList = () => {
    return (dispatch) => {
        ServiceRateCard.getPackingTypeListService().then((res) => {
            let createList = getSelectList(res.data.EntityList, 'id', 'packingType');
            dispatch(getPackingTypeListSuccess(createList));
        }).catch((err) => {
            console.log(err);
        });
    };
};

const getSizeTypeListSuccess = (response) => ({
    type: serviceRateCardConstant.GET_SIZE_TYPE_LIST_SUCCESS,
    payload: response,
});

export const getSizeTypeList = () => {
    return (dispatch) => {
        ServiceRateCard.getSizeTypeListService().then((res) => {
            let createList = getSelectList(res.data.EntityList, 'id', 'sizeType');
            dispatch(getSizeTypeListSuccess(createList));
        }).catch((err) => {
            console.log(err);
        });
    };
};

const getChargeUnitTypeListSuccess = (response) => ({
    type: serviceRateCardConstant.GET_UNIT_TYPE_LIST_SUCCESS,
    payload: response,
});

export const getChargeUnitTypeList = () => {
    return (dispatch) => {
        ServiceRateCard.getChargeUnitTypeListService().then((res) => {
            let createList = getSelectList(res.data.EntityList, 'id', 'unitType');
            dispatch(getChargeUnitTypeListSuccess(createList));
        }).catch((err) => {
            console.log(err);
        });
    };
};

const getBillingCycleListSuccess = (response) => ({
    type: serviceRateCardConstant.GET_BILLING_CYCLE_TYPE_LIST_SUCCESS,
    payload: response,
});

export const getBillingCycleList = () => {
    return (dispatch) => {
        ServiceRateCard.getBillingCycleListService().then((res) => {
            let createList = getSelectList(res.data.EntityList, 'id', 'billingType');
            dispatch(getBillingCycleListSuccess(createList));
        }).catch((err) => {
            console.log(err);
        });
    };
};
export const createUpdateServiceRateCard = (data) => {
    return () => {
        return ServiceRateCard.createUpdateServiceRateCardService(data);
    }}
const fetchServiceRateCardSuccess = (response) => ({
    type: serviceRateCardConstant.GET_ALL_SERVICE_RATECARD_LIST_SUCCESS,
    payload: response,
});
const fetchServiceRateCardFailed = () => ({
    type: serviceRateCardConstant.GET_ALL_SERVICE_RATECARD_LIST_FAILED,
});
const fetchServiceRateCardPending = () => ({
    type: serviceRateCardConstant.GET_ALL_SERVICE_RATECARD_LIST_PENDING
});

export const fetchServiceRateCard = (data) => {
    return (dispatch) => {
        dispatch(fetchServiceRateCardPending());
        ServiceRateCard.getAllServiceRateCardListService(data).then((result) => {
            dispatch(fetchServiceRateCardSuccess(result.data.Entity));
        }).catch((error) => {
            dispatch(fetchServiceRateCardFailed());
        })
    };
};
