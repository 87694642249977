import React from "react";
import DataTable from "../../common/DataTable";
import { LedgerUnbilled } from "../../../constant";

const UnbilledTransaction = (props) => {
  const listItems = [
    { id: 1, serviceType: 'Type 1', transactionType: 'DEBIT', status: '10-10-2025', date: '10-10-2026', amount: '10' },
    { id: 2, serviceType: 'Type 2', transactionType: 'CREDIT', status: '10-10-2025', date: '10-10-2026', amount: '10' },
    { id: 3, serviceType: 'Type 3', transactionType: 'CREDIT', status: '10-10-2025', date: '10-10-2026', amount: '10' },
  ];

  const handleAction = (item, type, isBack) => {
    console.log(item, type, isBack);
  }

  const handleSortingColumn = (event, key) => {
    console.log(event, key);
  }

  return (
    <>
      <div className="main_content_table  company-table">
        <div className="tableFixHead">
          <div className="tableFixHead incomingTbl">
            <DataTable
              headers={LedgerUnbilled}
              tableData={listItems}
              actionType={2}
              handleAction={handleAction}
              handleSortingColumn={handleSortingColumn}
            />
          </div>
        </div>
      </div>
    </>
  )
};
export default UnbilledTransaction;