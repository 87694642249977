import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { Accordion } from "react-bootstrap";
import { fetchAllConsignmentElementStatus } from '../../../store/actions/inventory.action';
import { useDispatch, useSelector } from 'react-redux';
import { barCode, elementObj, featureSubmodule, shippingStatusList, SUBF_ADHOCBARCODEGENERATION } from '../../../constant';
import { toast } from "react-toastify";
import { checkPermission, convertFilesToBase64, handleDocUploadBase64 } from '../../../config/utils';
import AlertDialogModal from '../../common/AlertDialog';
import OutboundProductListingDialog from '../../Outbound/OutboundProductListing';
import { Tooltip as WmsTooltip } from "react-tooltip";
import BarCodeGeneratePopup from '../Product/BarCodePopup';
import { v4 as uuidv4 } from 'uuid';
/* istanbul ignore next */

const ConsignmentDetails = (props) => {
    const { formData, isEditable, elements, setElements, handleAddConsoleType, showLoader, deletedConsignments, setDeletedConsignments } = props;
    const { consignmentElementStatus } = useSelector((state) => state.InventoryManagement);
    const { dimensionUnitList, weightUnitList } = useSelector((state) => state.shipment);
    const [alertMsg, setAlertMsg] = useState('');
    const [dimensionUnitListing, setDimensionUnitListing] = useState(false);
    const [weightUnitListing, setWeightUnitListing] = useState(false);
    const [selectedConsignment, setSelectedConsignment] = useState({});
    const [showAlertBox, setShowAlertBox] = useState(false);
    const [showProductListingBox, setShowProductListingBox] = useState(false);
    const [addItemsObject, setAddItemsObject] = useState({});
    const [showBarCodeModal, setShowBarCodeModal] = useState(false);
    const [barCodeRecord, setBarCodeRecord] = useState({});
    const dispatch = useDispatch();

    const handleDelete = (index, boxIndex, elemObj, deleteType, itemIndex) => {
        setSelectedConsignment({
            palletIndex: index,
            boxIndex: boxIndex,
            elemObj: elemObj,
            itemIndex: itemIndex,
            deleteType: deleteType,
        });
        setShowAlertBox(true);
    }

    const handleFormOnBlur = (event, index, type, boxIndex) => {
        let { value, name } = event.target;
        let elementsData = _.cloneDeep(elements);
        if (type === 'box') {
            elementsData[index].consignmentElements[boxIndex][name] = value.trim();
        } else {
            elementsData[index][name] = value.trim();
        }
        setElements(elementsData);
    }

    const handleFormValue = (event, index, type, boxIndex) => {
        let { value, name } = event.target;
        let elementsData = _.cloneDeep(elements);
        if (type === 'box') {
            elementsData[index].consignmentElements[boxIndex][name] = value;
        } else {
            if (name === 'consignmentStatusId') {
                let elems = elementsData[index].consignmentElements.map((item) => {
                    return {
                        ...item,
                        consignmentStatusId: Number(value),
                    }
                })
                elementsData[index].consignmentElements = elems;
            }
            elementsData[index][name] = value;
        }
        setElements(elementsData);
    };

    const handleItemValueChange = (event, palletIndex, boxIndex, itemIndex) => {
        let { value, name } = event.target;
        let elementsData = _.cloneDeep(elements);
        if (Number(formData.consignmentTypeId) === 3) {
            elementsData[itemIndex][name] = value;
        } else if (Number(formData.consignmentTypeId) === 2) {
            elementsData[boxIndex].consignmentElements[itemIndex][name] = value;
        } else if (Number(formData.consignmentTypeId) === 1) {
            elementsData[palletIndex].consignmentElements[boxIndex].consignmentElements[itemIndex][name] = value;
        }
        setElements(elementsData);
    }

    useEffect(() => {
        setAddItemsObject({});
    }, [formData.consignmentTypeId]);

    useEffect(() => {
        if (!consignmentElementStatus?.length) {
            dispatch(fetchAllConsignmentElementStatus());
        }
    }, []);

    useEffect(() => {
        if (weightUnitList.length) {
            setWeightUnitListing(weightUnitList)
        }
        if (dimensionUnitList.length) {
            setDimensionUnitListing(dimensionUnitList)
        }
    }, [weightUnitList, dimensionUnitList]);

    const handleAlertAction = () => {
        let deleteList = _.cloneDeep(deletedConsignments);
        deleteList.push(selectedConsignment.elemObj);
        setDeletedConsignments(deleteList);
        let elementsData = _.cloneDeep(elements);
        if (Number(formData.consignmentTypeId) === 3) {
            elementsData.splice([selectedConsignment.itemIndex], 1);
        } else if (Number(formData.consignmentTypeId) === 1) {
            if (selectedConsignment.deleteType === 'box') {
                elementsData[selectedConsignment.palletIndex].consignmentElements.splice([selectedConsignment.boxIndex], 1)
                elementsData[selectedConsignment.palletIndex].quantity = elementsData[selectedConsignment.palletIndex].consignmentElements.length;
            } else if (selectedConsignment.deleteType === 'item') {
                elementsData[selectedConsignment.palletIndex].consignmentElements[selectedConsignment.boxIndex].consignmentElements.splice([selectedConsignment.itemIndex], 1);
            } else {
                elementsData.splice([selectedConsignment.palletIndex], 1);
            }
        } else if (Number(formData.consignmentTypeId) === 2) {
            if (selectedConsignment.deleteType === 'box') {
                elementsData.splice([selectedConsignment.boxIndex], 1);
            } else {
                elementsData[selectedConsignment.boxIndex].consignmentElements.splice([selectedConsignment.itemIndex], 1);
            }
        }
        setElements(elementsData);
        setShowAlertBox(false);
    }

    const handleGenerateBarCode = (value) => {
        let data = {}
        data.productSku = value;
        setBarCodeRecord(data);
        setShowBarCodeModal(true);
    }

    const handleDeleteAttachment = (type, palletIndex, boxIndex, attachmentIndex) => {
        let elementsData = _.cloneDeep(elements);
        if (Number(formData.consignmentTypeId) === 1) {
            if (type === 'box') {
                elementsData[palletIndex].consignmentElements[boxIndex].attachments[attachmentIndex].isDeleted = true;
                if (!elementsData[palletIndex].consignmentElements[boxIndex].attachments[attachmentIndex].id) {
                    elementsData[palletIndex].consignmentElements[boxIndex].attachments.splice(attachmentIndex, 1);
                }
            } else {
                elementsData[palletIndex].attachments[attachmentIndex].isDeleted = true;
                if (!elementsData[palletIndex].attachments[attachmentIndex].id) {
                    elementsData[palletIndex].attachments.splice(attachmentIndex, 1);
                }
            }
        } else if (Number(formData.consignmentTypeId) === 2) {
            elementsData[boxIndex].attachments[attachmentIndex].isDeleted = true;
        }
        setElements(elementsData);
    }

    const renderBoxConsignmentFields = (item, index, type, boxIndex) => {
        return (
            <>
                <div className='row mb-3'>
                    <div className='col-lg-2 col-md-6 col-sm-12'>
                        <label>Box Name <span className='error'>*</span></label>
                        <div className="generateSkuInputWrapper">
                            <input
                                className={`form-control`}
                                type={'text'}
                                name="name"
                                value={item.name}
                                onChange={(e) => handleFormValue(e, boxIndex, type, boxIndex)}
                                onBlur={(e) => handleFormOnBlur(e, boxIndex, type, boxIndex)}
                                disabled={!isEditable}
                                data-index={index}
                            />
                            {!item.name && <div className='error'>This is required</div>}
                            {checkPermission(barCode, SUBF_ADHOCBARCODEGENERATION, '', featureSubmodule, props.employeePermissions) && item.name && (
                                <div className="generateBar">
                                    <button className="btn generateBar pr-0 pt-0 pb-0" onClick={(e) => handleGenerateBarCode(item.name)}>Generate Barcode</button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-6 col-sm-12'>
                        <label>Box SKU <span className='error'>*</span></label>
                        <div className="generateSkuInputWrapper">
                            <input
                                className={`form-control`}
                                type={'text'}
                                name="productSKU"
                                value={item.productSKU}
                                data-index={index}
                                onChange={(e) => handleFormValue(e, index, type, boxIndex)}
                                onBlur={(e) => handleFormOnBlur(e, index, type, boxIndex)}
                                disabled={!isEditable}
                            />
                            {!item.productSKU && <div className='error'>This is required</div>}
                            {checkPermission(barCode, SUBF_ADHOCBARCODEGENERATION, '', featureSubmodule, props.employeePermissions) && item.productSKU && (
                                <div className="generateBar">
                                    <button className="btn generateBar pr-0 pt-0 pb-0" onClick={() => handleGenerateBarCode(item.productSKU)}>Generate Barcode</button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-6 col-sm-12'>
                        <label>Tracking Number</label>
                        <input
                            className={`form-control`}
                            type={'text'}
                            name="trackingNumber"
                            value={item.trackingNumber}
                            onChange={(e) => handleFormValue(e, index, type, boxIndex)}
                            disabled={!isEditable}
                            data-index={index}
                            onBlur={(e) => handleFormOnBlur(e, index, type, boxIndex)}
                        />
                    </div>
                    <div className='col-lg-2 col-md-6 col-sm-12'>
                        <label>Box Status</label>
                        <select
                            className={`form-control carrierSelect`}
                            onChange={(e) => handleFormValue(e, index, type, boxIndex)}
                            value={item.consignmentStatusId}
                            name={'consignmentStatusId'}
                            data-index={index}
                            disabled={!isEditable}
                        >
                            {consignmentElementStatus?.map((item) => (
                                <option
                                    value={item.id}
                                    key={item.id}
                                    disabled={Number(formData.id) === 0 && (item.id === 3)}
                                >
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='col-lg-2 col-md-6 col-sm-12'>
                        <label>Box to be <span className='error'>*</span></label>
                        <select
                            className={`form-control countrySelect`}
                            onChange={(e) => handleFormValue(e, index, type, boxIndex)}
                            value={item.shippingStatusId}
                            name={'shippingStatusId'}
                            data-index={index}
                            disabled={!isEditable}
                        >
                            <option value={0}>Select</option>
                            {shippingStatusList.map((list) => (
                                <option key={list.id} value={list.value}>{list.name}</option>
                            ))}
                        </select>
                        {!item.shippingStatusId && <div className='error'>This is required</div>}
                    </div>
                    <div className='col-lg-2 col-md-6 col-sm-12'>
                        <label>Attachments</label>
                        <div className="uploadContainer">
                            <div className="inlineBlock fileUpload inventory">
                                <button
                                    disabled={!isEditable}
                                    type="button"
                                    className="btn cus-seconday-bg-btn imgUploadbtn"
                                >
                                    Upload Documents
                                </button>
                                <input
                                    className="hiddenFileField"
                                    disabled={!isEditable}
                                    type="file"
                                    accept="*"
                                    multiple
                                    id="customFile"
                                    onChange={(e) => handleDocUpload(e, index, type, boxIndex)}
                                />
                            </div>

                            <div>
                                {item?.attachments && item?.attachments.length > 0 &&
                                    item.attachments.map((item, attachmentIndex) => (
                                        <Fragment key={item?.id}>
                                            {!item?.isDeleted &&
                                                <div
                                                    className="selectedFile"
                                                    key={item?.id}
                                                >
                                                    <div className="fileName">
                                                        {item.fileUrl ? (
                                                            <a href={item.fileUrl} target='_blank' rel="noreferrer">{item.name}</a>
                                                        ) : (
                                                            <>
                                                                {item.name}
                                                            </>
                                                        )}
                                                    </div>
                                                    <button
                                                        className='deleteImg'
                                                        onClick={() => handleDeleteAttachment(type, index, boxIndex, attachmentIndex)}
                                                        disabled={!isEditable}
                                                    >
                                                        <i
                                                            className="fa fa-times"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </button>
                                                </div>
                                            }
                                        </Fragment>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`mt-3 dimesntionsBlock ${type === 'box' && 'dimesntionsBlockDark'}`}>
                    <div className='col'>
                        <div className='row'>
                            <div className='col-4'>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-9 col-sm-12'>
                                        <label>Weight</label>
                                        <input
                                            className={`form-control numInput`}
                                            type={'number'}
                                            name="weight"
                                            min={0}
                                            value={item.weight}
                                            onChange={(e) => handleFormValue(e, index, type, boxIndex)}
                                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                            data-index={index}
                                            disabled={!isEditable}
                                            onBlur={(e) => handleFormOnBlur(e, index, type, boxIndex)}
                                        />
                                    </div>

                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <label>Unit</label>
                                        <select
                                            className={`form-control countrySelect`}
                                            onChange={(e) => handleFormValue(e, index, type, boxIndex)}
                                            value={item.weightUnitId}
                                            name={'weightUnitId'}
                                            onBlur={(e) => handleFormOnBlur(e, index, type, boxIndex)}
                                            data-index={index}
                                            disabled={!isEditable}
                                        >
                                            {weightUnitListing.length > 0 && weightUnitListing.map((weight) => (
                                                <option key={weight.id} value={weight.id}>{weight.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className='col-8'>
                                <div className='row'>
                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                        <label>Length</label>
                                        <input
                                            className={`form-control numInput`}
                                            type={'number'}
                                            name="length"
                                            min={0}
                                            value={item.length}
                                            onChange={(e) => handleFormValue(e, index, type, boxIndex)}
                                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                            onBlur={(e) => handleFormOnBlur(e, index, type, boxIndex)}
                                            data-index={index}
                                            disabled={!isEditable}
                                        />
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                        <label>Width</label>
                                        <input
                                            className={`form-control numInput`}
                                            type={'number'}
                                            name="width"
                                            min={0}
                                            value={item.width}
                                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                            onChange={(e) => handleFormValue(e, index, type, boxIndex)}
                                            data-index={index}
                                            onBlur={(e) => handleFormOnBlur(e, index, type, boxIndex)}
                                            disabled={!isEditable}
                                        />
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                        <label>Height</label>
                                        <input
                                            className={`form-control numInput`}
                                            type={'number'}
                                            min={0}
                                            name="height"
                                            value={item.height}
                                            onChange={(e) => handleFormValue(e, index, type, boxIndex)}
                                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                            data-index={index}
                                            onBlur={(e) => handleFormOnBlur(e, index, type, boxIndex)}
                                            disabled={!isEditable}
                                        />
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                        <label>Dimension Unit</label>
                                        <select
                                            className={`form-control countrySelect`}
                                            onChange={(e) => handleFormValue(e, index, type, boxIndex)}
                                            value={item.dimensionUnitId}
                                            name={'dimensionUnitId'}
                                            data-index={index}
                                            disabled={!isEditable}
                                        >
                                            {dimensionUnitListing.length > 0 && dimensionUnitListing.map((dimension) => (
                                                <option key={dimension.id} value={dimension.id}>{dimension.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const renderFields = (item, index, type, boxIndex) => {
        return (
            <Fragment key={item.id || item.pId}>
                <div className='row mb-3'>
                    <div className='col-lg-2 col-md-6 col-sm-12'>
                        <label>{type === 'box' ? 'Box' : 'Pallet'} Name
                            <i id={`${type}-console1_${type === 'box' ? boxIndex : index}`} className="fa fa-info-circle ml-2" aria-hidden="true"></i> <span className='require-field'>*</span></label>
                        <WmsTooltip
                            anchorId={`${type}-console1_${type === 'box' ? boxIndex : index}`}
                            data-tooltip-html={true}
                            content={`Name of ${type === 'box' ? 'Box' : 'Pallet'} should start like sellercode_PL_XXXX`}
                            place="right"
                            className="tooltipWidth palletInfo"
                        />
                        <div className="generateSkuInputWrapper">
                            <input
                                className={`form-control`}
                                type={'text'}
                                name="name"
                                value={item.name}
                                onChange={(e) => handleFormValue(e, index, type, boxIndex)}
                                disabled={!isEditable}
                                data-index={index}
                                onBlur={(e) => handleFormOnBlur(e, index, type, boxIndex)}
                            />
                            {checkPermission(barCode, SUBF_ADHOCBARCODEGENERATION, '', featureSubmodule, props.employeePermissions) && item.name && (
                                <div className="generateBar">
                                    <button className="btn generateBar pr-0 pt-0 pb-0" onClick={(e) => handleGenerateBarCode(item.name)}>Generate Barcode</button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-6 col-sm-12 mb-3'>
                        <label>{type === 'box' ? 'Box' : 'Pallet'} SKU <span className='require-field'>*</span></label>
                        <div className="generateSkuInputWrapper">
                            <input
                                className={`form-control`}
                                type={'text'}
                                name="productSKU"
                                value={item.productSKU}
                                data-index={index}
                                onChange={(e) => handleFormValue(e, index, type, boxIndex)}
                                disabled={!isEditable}
                                onBlur={(e) => handleFormOnBlur(e, index, type, boxIndex)}
                            />
                            {!item.productSKU && <div className='error'>This is required</div>}
                            {checkPermission(barCode, SUBF_ADHOCBARCODEGENERATION, '', featureSubmodule, props.employeePermissions) && item.productSKU && (
                                <div className="generateBar">
                                    <button className="btn generateBar pr-0 pt-0 pb-0" onClick={(e) => handleGenerateBarCode(item.productSKU)}>Generate Barcode</button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-6 col-sm-12 mb-3'>
                        <label>Tracking Number</label>
                        <input
                            className={`form-control`}
                            type={'text'}
                            name="trackingNumber"
                            value={item.trackingNumber}
                            onChange={(e) => handleFormValue(e, index, type, boxIndex)}
                            onBlur={(e) => handleFormOnBlur(e, index, type, boxIndex)}
                            disabled={!isEditable}
                            data-index={index}
                        />
                    </div>
                    <div className='col-lg-2 col-md-6 col-sm-12 mb-3'>
                        <label>{type === 'box' ? 'Box' : 'Pallet'} Status</label>
                        <select
                            className={`form-control carrierSelect`}
                            onChange={(e) => handleFormValue(e, index, type, boxIndex)}
                            value={item.consignmentStatusId}
                            name={'consignmentStatusId'}
                            data-index={index}
                            disabled={type === 'box'}
                        >
                            {consignmentElementStatus?.map((item) => (
                                <option
                                    value={item.id}
                                    key={item.id}
                                    disabled={Number(formData.id) === 0 && item.id === 3}
                                >
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='col-lg-2 col-md-6 col-sm-12 mb-3'>
                        <label>{type === 'box' ? 'Box' : 'Pallet'} to be <span className='require-field'>*</span></label>
                        <select
                            className={`form-control countrySelect`}
                            onChange={(e) => handleFormValue(e, index, type, boxIndex)}
                            value={item.shippingStatusId}
                            name={'shippingStatusId'}
                            data-index={index}
                            disabled={!isEditable}
                        >
                            <option value={0}>Select</option>
                            {shippingStatusList.map((list) => (
                                <option key={list.id} value={list.value}>{list.name}</option>
                            ))}
                        </select>
                        {!item.shippingStatusId && <div className='error'>This is required</div>}
                    </div>
                    <div className='col-lg-2 col-md-6 col-sm-12'>
                        <label>Attachments</label>
                        <div className="uploadContainer">
                            <div className="inlineBlock fileUpload inventory">
                                <button
                                    disabled={!isEditable}
                                    type="button"
                                    className="btn cus-seconday-bg-btn imgUploadbtn"
                                >
                                    Upload Docs
                                </button>
                                <input
                                    className="hiddenFileField"
                                    disabled={!isEditable}
                                    type="file"
                                    accept="*"
                                    multiple
                                    id="customFile"
                                    onChange={(e) => handleDocUpload(e, index, type, boxIndex)}
                                />
                            </div>

                            <div>
                                {item?.attachments && item?.attachments.length > 0 &&
                                    item.attachments.map((item, attachmentIndex) => (
                                        <Fragment key={item?.id}>
                                            {!item?.isDeleted &&
                                                <div
                                                    className="selectedFile"
                                                    key={item?.id}
                                                >
                                                    <div className="fileName">
                                                        {item.fileUrl ? (
                                                            <a href={item.fileUrl} target='_blank' rel="noreferrer">{item.name}</a>
                                                        ) : (
                                                            <>
                                                                {item.name}
                                                            </>
                                                        )}
                                                    </div>
                                                    <button
                                                        className='deleteImg'
                                                        onClick={() => handleDeleteAttachment(type, index, boxIndex, attachmentIndex)}
                                                        disabled={!isEditable}
                                                    >
                                                        <i
                                                            className="fa fa-times"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </button>
                                                </div>
                                            }
                                        </Fragment>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`mt-3 mb-3 dimesntionsBlock ${type === 'box' && 'dimesntionsBlockDark'}`}>
                    <div className='col'>
                        <div className='row'>
                            <div className='col-lg-4 col-sm-12'>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
                                        <label>Weight</label>
                                        <input
                                            className={`form-control numInput`}
                                            type={'number'}
                                            name="weight"
                                            min={0}
                                            value={item.weight}
                                            onChange={(e) => handleFormValue(e, index, type, boxIndex)}
                                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                            data-index={index}
                                            disabled={!isEditable}
                                        />
                                    </div>

                                    <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
                                        <label>Unit</label>
                                        <select
                                            className={`form-control countrySelect`}
                                            onChange={(e) => handleFormValue(e, index, type, boxIndex)}
                                            value={item.weightUnitId}
                                            name={'weightUnitId'}
                                            disabled={!isEditable}
                                            data-index={index}
                                        >
                                            {weightUnitListing.length > 0 && weightUnitListing.map((weight) => (
                                                <option key={weight.id} value={weight.id}>{weight.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-8 col-sm-12'>
                                <div className='row'>
                                    <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                                        <label>Length</label>
                                        <input
                                            className={`form-control numInput`}
                                            type={'number'}
                                            name="length"
                                            min={0}
                                            value={item.length}
                                            onChange={(e) => handleFormValue(e, index, type, boxIndex)}
                                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                            disabled={!isEditable}
                                            data-index={index}
                                        />
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                                        <label>Width</label>
                                        <input
                                            className={`form-control numInput`}
                                            type={'number'}
                                            name="width"
                                            min={0}
                                            value={item.width}
                                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                            onChange={(e) => handleFormValue(e, index, type, boxIndex)}
                                            data-index={index}
                                            disabled={!isEditable}
                                        />
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                                        <label>Height</label>
                                        <input
                                            className={`form-control numInput`}
                                            type={'number'}
                                            min={0}
                                            name="height"
                                            value={item.height}
                                            onChange={(e) => handleFormValue(e, index, type, boxIndex)}
                                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                            data-index={index}
                                            disabled={!isEditable}
                                        />
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                                        <label>Dimension Unit</label>
                                        <select
                                            className={`form-control countrySelect`}
                                            onChange={(e) => handleFormValue(e, index, type, boxIndex)}
                                            value={item.dimensionUnitId}
                                            name={'dimensionUnitId'}
                                            data-index={index}
                                            disabled={!isEditable}
                                        >
                                            {dimensionUnitListing.length > 0 && dimensionUnitListing.map((dimension) => (
                                                <option key={dimension.id} value={dimension.id}>{dimension.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    const handleNumberOfBoxes = (event, index) => {
        let elementsData = JSON.parse(JSON.stringify(elements));
        let { value } = event.target;
        if (!value) return false;
        let getDiff = Number(value) - elements[index].consignmentElements.length;
        if (value && Number(value) > elements[index].consignmentElements.length) {
            let cloneElementObj = JSON.parse(JSON.stringify(elementObj));
            let createBoxData = new Array(getDiff).fill().map((e, i) => {
                return { ...cloneElementObj, consignmentStatusId: elements[index].consignmentStatusId, consignmentTypeId: 2, pId: uuidv4(), name: `${formData.sellerCode}_BX_${elements[index].consignmentElements.length + (i + 1)}` }
            });
            elementsData[index].consignmentElements.push(...createBoxData);
            setElements(elementsData);
        } else if (Number(value) === elements[index].consignmentElements.length) {
            return false
        } else {
            elementsData[index].quantity = elements[index].consignmentElements.length;
            toast.error('Enter greater value.');
            setElements(elementsData);
        }
    }

    const handleDocUpload = (e, index, type, boxIndex) => {
        let getBase64Files = handleDocUploadBase64(e, toast);
        let elementsData = _.cloneDeep(elements);
        convertFilesToBase64(getBase64Files)
            .then((base64Array) => {
                if (type === 'box') {
                    // Update the specific box attachments without affecting others
                    elementsData[index].consignmentElements[boxIndex].attachments = [
                        ...(elementsData[index].consignmentElements[boxIndex].attachments || []),
                        ...base64Array,
                    ];
                } else {
                    // Update the attachments for the main element
                    elementsData[index].attachments = [
                        ...(elementsData[index].attachments || []),
                        ...base64Array,
                    ];
                }

                // Set the updated elements array
                setElements(elementsData);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const handleMultiProductSelect = (productListdata) => {
        let data = productListdata.map((item) => ({ ...item, id: 0, productId: item.id, productSKU: item.productSku, description: item.productDescription, name: item.productName }));
        let elementsData = JSON.parse(JSON.stringify(elements));
        if (Number(formData.consignmentTypeId) === 1) {
            elementsData[addItemsObject.palletIndex].consignmentElements[addItemsObject.boxIndex].consignmentElements.push(...data);
        } else if (Number(formData.consignmentTypeId) === 2) {
            elementsData[addItemsObject.boxIndex].consignmentElements.push(...data);
        } else if (Number(formData.consignmentTypeId) === 3) {
            elementsData.push(...data);
        }
        setElements(elementsData);
    }

    const renderItems = (list, palletIndex, boxIndex, type) => {
        return (
            list.length > 0 && (
                <div className="main_content cus-page-content-sec srch-pt0">
                    <div className='main_content_table_history'>
                        <div className='row listDesign inbound-res-item-table p-3'>
                            <div className='col-lg-12 heading pt-2 pb-2'>
                                <div className='tbl-btch row'>
                                    <div className='col-3'>Product Name</div>
                                    <div className='col-2'>Product SKU</div>
                                    <div className='col-2'>Available Quantity</div>
                                    <div className='col-2'>Incoming Quantity <span className='require-field'>*</span></div>
                                    <div className='col-2'>Description</div>
                                    <div className='col-1'></div>
                                </div>
                            </div>
                            {list?.map((item, itemIndex) => (
                                <div className='col-lg-12 body' key={item.productId}>
                                    <div className='row pt-2 pb-2'>
                                        <div className='col-3 flexCenter'>{item.name}</div>
                                        <div className='col-2 flexCenter'>{item.productSKU}</div>
                                        <div className='col-2 flexCenter'>{item.quantity}</div>
                                        <div className='col-2'>
                                            <input
                                                className={`form-control numberInput quantity`}
                                                type={'number'}
                                                name="incomingQuantity"
                                                min={0}
                                                data-index={itemIndex}
                                                value={item.incomingQuantity}
                                                onChange={(e) => handleItemValueChange(e, palletIndex, boxIndex, itemIndex)}
                                                onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                                disabled={!isEditable}
                                            />
                                            {!item.incomingQuantity && <div className='error'>This is required</div>}
                                        </div>
                                        <div className='col-2 flexCenter'>{item.description}</div>
                                        <div className='col-1'>
                                            <button
                                                disabled={!isEditable}
                                                className="btn cus-seconday-bg-btn round red"
                                                onClick={() => {
                                                    handleDelete(palletIndex, boxIndex, item, 'item', itemIndex);
                                                    setAlertMsg('');
                                                }}
                                                title='Delete Item'
                                            >
                                                <i className="fa fa-minus" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
            )
        );
    };
    const handleConsignmentTypeItem = (e) => {
        e.stopPropagation();
        let data = elements.map((item) => ({ ...item, id: item.productId }));
        setAddItemsObject({
            palletIndex: 0,
            boxIndex: 0,
            items: data
        });
        setShowProductListingBox(true);
    }

    return (
        <div className="col-lg-12 col-sm-12">
            <Accordion
                defaultActiveKey={["0"]}
                className='consignmentDetails'
            >
                <Accordion.Item eventKey="0">
                    <Accordion.Header >
                        Consignment Details
                        <div className='unit-margin-level'>
                            {isEditable && (
                                <button className="btn cus-primary-transparent-btn addConsole mr-3"
                                    onClick={Number(formData.consignmentTypeId) === 3 ? handleConsignmentTypeItem : handleAddConsoleType}
                                >
                                    {Number(formData.consignmentTypeId) === 3 ? '+ Add from existing Product List' : '+ Add'}
                                </button>
                            )}
                            <i className="fa fa-chevron-down" aria-hidden="true"></i>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        {Number(formData?.consignmentTypeId) === 1 && (
                            <>
                                {elements && elements.length > 0 && elements.map((pallet, index) => (
                                    <Accordion className='innerAccordion' key={pallet.id || pallet.pId} defaultActiveKey={[]}>
                                        <Accordion.Item>
                                            <Accordion.Header>
                                                <span>
                                                    <i className="mr-2 fa fa-chevron-down" aria-hidden="true"></i>
                                                    Pallet Details - {pallet.name}
                                                </span>
                                                {isEditable && (
                                                    <i className="fa fa-trash-o require-field cursorPointer" aria-hidden="true"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleDelete(index, 0, pallet);
                                                            setAlertMsg(pallet.consignmentElements.length && 'Removing the pallet will remove the associated boxes and items too. Are you sure you want to proceed?');
                                                        }}></i>
                                                )}
                                            </Accordion.Header>
                                            <Accordion.Body className='innerAccourdion'>
                                                <div className='palletWrapper'>
                                                    <div className='pt-3 palletBody'>
                                                        {renderFields(pallet, index, 'pallet')}
                                                        <div className='mb-3'>
                                                            <div className="row mt-2">
                                                                <div className='col-lg-2 col-md-6 col-sm-12'>
                                                                    <label>How many boxes?</label>
                                                                    <input
                                                                        className={`form-control numberInput quantity numBoxesInput`}
                                                                        type={'number'}
                                                                        name="quantity"
                                                                        min={0}
                                                                        data-index={index}
                                                                        value={pallet.quantity || ''}
                                                                        onChange={(e) => handleFormValue(e, index, '', 0)}
                                                                        onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                                                        onBlur={(e) => handleNumberOfBoxes(e, index)}
                                                                        disabled={!isEditable}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {pallet.consignmentElements.length > 0 && pallet.consignmentElements.map((box, boxIndex) => (
                                                            <Accordion className='innerAccordion mb-2' key={box.id || box.pId} defaultActiveKey={[]}>
                                                                <Accordion.Item>
                                                                    <Accordion.Header>
                                                                        <span>
                                                                            <i className="mr-2 fa fa-chevron-down" aria-hidden="true"></i>
                                                                            Box Details - {box.name}
                                                                        </span>
                                                                        {isEditable && (
                                                                            <i className="fa fa-trash-o require-field cursorPointer" aria-hidden="true"
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    handleDelete(index, boxIndex, box, 'box')
                                                                                    setAlertMsg(box.consignmentElements.length && 'Removing the box will remove the associated items too. Are you sure you want to proceed?');
                                                                                }}
                                                                            ></i>
                                                                        )}
                                                                    </Accordion.Header>
                                                                    <Accordion.Body className='pb-3'>
                                                                        <div className='box-details'>
                                                                            <div className='box-wrapper'>
                                                                                <div className='pt-3 palletBody'>
                                                                                    {renderFields(box, index, 'box', boxIndex)}
                                                                                    <div className=''>
                                                                                        {isEditable && (
                                                                                            <button
                                                                                                className="btn cus-primary-transparent-btn slcttxt"
                                                                                                style={{ paddingLeft: "0", paddingRight: "0" }}
                                                                                                type="text"
                                                                                                onClick={() => {
                                                                                                    let data = box.consignmentElements.map((item) => ({ ...item, id: item.productId }));
                                                                                                    setAddItemsObject({
                                                                                                        palletIndex: index,
                                                                                                        boxIndex: boxIndex,
                                                                                                        items: data
                                                                                                    });
                                                                                                    setShowProductListingBox(true);
                                                                                                }}
                                                                                            >
                                                                                                Add from existing Product List <i className="fa fa-plus" aria-hidden="true"></i>
                                                                                            </button>
                                                                                        )}
                                                                                        {renderItems(box.consignmentElements, index, boxIndex, 'item')}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            </Accordion>
                                                        ))}
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                ))}
                            </>
                        )}

                        {Number(formData?.consignmentTypeId) === 2 && (
                            <>
                                {elements && elements.length > 0 && elements.map((box, boxIndex) => (
                                    <Accordion alwaysOpen={false} className='innerAccordion' key={box.id} defaultActiveKey={[]}>
                                        <Accordion.Item>
                                            <Accordion.Header>
                                                <span>
                                                    <i className="mr-2 fa fa-chevron-down" aria-hidden="true"></i>
                                                    Box Details - {box.name}
                                                </span>
                                                {isEditable && (
                                                    <i className="fa fa-trash-o require-field cursorPointer" aria-hidden="true" onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDelete(0, boxIndex, box, 'box', 0);
                                                        setAlertMsg(box.consignmentElements.length && 'Removing the box will remove the associated items too. Are you sure you want to proceed?');
                                                    }}></i>
                                                )}
                                            </Accordion.Header>
                                            <Accordion.Body className='innerAccourdion'>
                                                <div className='palletWrapper'>
                                                    <div className='pt-3 palletBody'>
                                                        {renderBoxConsignmentFields(box, boxIndex, '', boxIndex)}
                                                        <div className='mb-3'>
                                                            {isEditable && (
                                                                <button
                                                                    className="btn cus-primary-transparent-btn slcttxt"
                                                                    style={{ paddingLeft: "0", paddingRight: "0" }}
                                                                    type="text"
                                                                    onClick={() => {
                                                                        let data = box.consignmentElements.map((item) => ({ ...item, id: item.productId }));
                                                                        setAddItemsObject({
                                                                            palletIndex: 0,
                                                                            boxIndex: boxIndex,
                                                                            items: data
                                                                        });
                                                                        setShowProductListingBox(true);
                                                                    }}
                                                                >
                                                                    Add from existing Product List <i className="fa fa-plus" aria-hidden="true"></i>
                                                                </button>
                                                            )}
                                                            {renderItems(box.consignmentElements, boxIndex, boxIndex, 'box')}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                ))}
                            </>
                        )}

                        {Number(formData?.consignmentTypeId) === 3 && (
                            <>
                                {renderItems(elements, 0, 1, 'item')}
                            </>
                        )}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <AlertDialogModal
                modalShow={showAlertBox}
                handleCancelDeleteAlert={() => setShowAlertBox(false)}
                modalClass={"modalDeleteWrapper"}
                messageText={alertMsg || 'Are you sure, you want to delete this record?'}
                handleYesDeleteAlert={handleAlertAction}
                loader={showLoader}
            />

            {showProductListingBox && (
                <OutboundProductListingDialog
                    showProductListingBox={showProductListingBox}
                    setShowProductListingBox={setShowProductListingBox}
                    handleCancel={() => setShowProductListingBox(false)}
                    show={showProductListingBox}
                    handleMultiProductSelect={handleMultiProductSelect}
                    formData={addItemsObject}
                    listingType={2}
                />
            )}

            <BarCodeGeneratePopup
                showPopup={showBarCodeModal}
                hidePopup={setShowBarCodeModal}
                barCodeRecord={barCodeRecord}
            />
        </div >
    );
}

export default ConsignmentDetails;