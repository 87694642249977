import { Button } from "bootstrap-4-react/lib/components";
import { useNavigate } from "react-router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getBarcodeSize,
  updateBarcodePrintingSize,
  getAllBarCodeType,
} from "../../../store/actions/barcode.action";

const BarCodeSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setErrors] = useState({});
  const [formData, setFormData] = useState({
    Width: "",
    Height: "",
    FileAction: 1,
  });
  const [barcodeTYpe, setBarcodeType] = useState([]);
  const [typedata, setTypeData] = useState("");
  const { barcodeSizeList, barcodeTypeList } = useSelector(
    (state) => state.barcodeReducer
  );

  useEffect(() => {
    dispatch(getBarcodeSize({}));
    dispatch(getAllBarCodeType());
  }, []);

  useEffect(() => {
    setFormData(barcodeSizeList);
  }, [barcodeSizeList]);

  useEffect(() => {
    setBarcodeType(barcodeTypeList)
    const selectedBarcodeTypeId = barcodeTypeList.find(
      (obj) => obj.id === barcodeSizeList?.BarCodePrintType
    );
    if (selectedBarcodeTypeId) {
      setTypeData(selectedBarcodeTypeId.name);
    }
  }, [barcodeTypeList, barcodeSizeList]);

  const validateAddress = () => {
    let isValid = true;
    let errorData = {};
    if (formData.Width === null) {
      isValid = false;
      errorData.Width = "This is required";
    }
    if (formData.Height === null) {
      isValid = false;
      errorData.Height = "This is required";
    }
    setErrors(errorData);
    return isValid;
  };

  const handleSave = async () => {
    try {
      if (validateAddress()) {
        const selectedBarcodeType = barcodeTypeList.find(
          (obj) => obj.name === typedata
        );
        let upadtedataRes = await Promise.resolve(
          dispatch(
            updateBarcodePrintingSize({
              ...formData,
              BarCodePrintType: selectedBarcodeType.id,
            })
          )
        );
        if (
          upadtedataRes &&
          upadtedataRes.data &&
          upadtedataRes.data.Status === 200
        ) {
          navigate("/barcode");
          toast.success(upadtedataRes?.data?.Message?.AppStatusDescription);
        } else {
          toast.error(upadtedataRes?.data?.Message?.AppStatusDescription);
        }
      }
    } catch (error) {
      navigate("/barcode");
      toast.error("Something went wrong");
    }
  };

  const handleChange = (e, type = "") => {
    let { value, name } = e.target;
    setFormData({ ...formData, [name]: type ? Number(value) || null : value });
    if (name === "Height" || name === "Width") {
      setTypeData("Custom");
    }
    setErrors({ ...error, [name]: "" });
  };

  const handleBarcodeWidthHeight = (e, type = "") => {
    let name = e.target.value;
    const selectedBarcodeType = barcodeTypeList.find(
      (type) => type.name === name
    );
    setTypeData(name);
    if (selectedBarcodeType) {
      if (selectedBarcodeType.id === 1) {
        setFormData({
          ...formData,
          Width: barcodeSizeList.Width,
          Height: barcodeSizeList.Height,
        });
      } else {
        setFormData({
          ...formData,
          Width: selectedBarcodeType.width,
          Height: selectedBarcodeType.height,
        });
      }
    }
    setErrors({ ...error, [name]: "" });
  };

  const handleNumberBlur = (e) => {
    let { value, name } = e.target;
    let num = Number(value);
    setFormData({ ...formData, [name]: num });
  }

  return (
    <main>
      <div className="main__container">
        <div className="main__tile_heading cus-btn-include-sec custitle">
          <div>Default Settings</div>
          <div className="top_right_button_container">
            <Button
              className="cus-seconday-bg-btn top-btn"
              id="savesr"
              onClick={handleSave}
            >
              Save
            </Button>
            <Button
              className="cus-primary-transparent-btn"
              onClick={() => {
                navigate("/barcode");
              }}
            >
              Back
            </Button>
          </div>
        </div>
        <div className="main_content cus-page-content-sec incomingTbl">
          <div className="top_right_button_container cus-content-only-sec">
            <div className="incoming_wrapper addressDetailWrapper pt-3 fontFix">
              <div className="row">
                <div className="col-lg-3 col-sm-12 col-md-12 mobMb-10">
                  <fieldset id="fileAction" className="codeOptions">
                    <label className="input-label-txt">Printing Size (In Inches)</label>
                  </fieldset>
                </div>

                <div className="col-lg-8 col-lg-8">
                  <div className="row">
                    <div className="col-lg-2 col-sm-12 col-md-12 mobMb-10">
                      <label className="input-label-txt">
                        Width <span className="error">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        name="Width"
                        value={formData.Width}
                        min={0}
                        onChange={(e) => handleChange(e)}
                        onBlur={handleNumberBlur}
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                      />
                      <p className="error dialogError">{error.Width}</p>
                    </div>
                    <div className="col-1 alignEnd alignCenter desktopOnly">
                      <label className="hidLbl">-</label>
                      X
                    </div>
                    <div className="col-lg-2 col-sm-12 col-md-12 mobMb-10">
                      <label className="input-label-txt">
                        Height <span className="error">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        name="Height"
                        value={formData.Height}
                        min={0}
                        onBlur={handleNumberBlur}
                        onChange={(e) => handleChange(e, "num")}
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                      />
                      <p className="error dialogError">{error.Height}</p>
                    </div>
                    <div className="col-2 alignEnd mobMb-10 desktopOnly"><label className="input-label-txt">(Inches)</label></div>
                    <div className="col-lg-4 col-sm-12 col-md-12 mobMb-10">
                      <label className="input-label-txt">Type</label>
                      <select
                        className="selectInput form-control"
                        value={typedata}
                        onChange={(e) => handleBarcodeWidthHeight(e)}
                      >
                        {barcodeTYpe.map((option) => (
                          <option
                            value={option.name}
                            key={option.id}
                            disabled={option.isDisabled}
                          >
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-lg-3 col-sm-12 col-md-12 mobMb-10">
                  <label className="input-label-txt">Default Action</label>
                </div>

                <div className="col-lg-8 col-sm-12 col-md-12 mobMb-10">
                  <fieldset
                    id="FileAction"
                    className="barCodeAction row"
                    onChange={(e) => handleChange(e, "num")}
                  >
                    <div className="col-lg-3 col-md-12 col-sm-12">
                      <input
                        type="radio"
                        value={1}
                        checked={Number(formData.FileAction) === 1}
                        name="FileAction"
                        id="savePdf"
                      />{" "}
                      <label for="savePdf" className="input-label-txt">Save as Pdf</label>
                    </div>
                    <div className="col-lg-3 col-md-12 col-sm-12">
                      <input
                        type="radio"
                        value={2}
                        checked={Number(formData.FileAction) === 2}
                        name="FileAction"
                        id="print"
                      />{" "}
                      <label for="print" className="input-label-txt">Print</label>
                    </div>
                    <div className="col-lg-3 col-md-12 col-sm-12">
                      <input
                        type="radio"
                        value={3}
                        checked={Number(formData.FileAction) === 3}
                        name="FileAction"
                        id="savePrint"
                      />{" "}
                      <label for="savePrint" className="input-label-txt">Save and Print</label>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default BarCodeSettings;
