import React, { useEffect, useState } from 'react';
import DataTable from '../common/DataTable';
import { numOfPage, RateCardColumns } from '../../constant';
import BatchPagination from '../batch/BatchPagination';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchServiceRateCard } from '../../store/actions/serviceRateCard.action';
import _ from 'lodash';
import LoaderSpinner from '../common/Loader';
import SearchProduct from '../Inventory/Product/searchProduct';

const Accounts = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { productPrevState } = useSelector((state) => state.employee);
    const { serviceRateCardList, serviceRateCardListStatus } = useSelector((state) => state.serviceRateCardReducer);
    const [loader, setLoader] = useState(false);

    let dataLoad = {
        pageIndex: 1,
        pageSize: 30,
        totalPages: 1,
        sortColumn: '',
        sortOrder: 'DESCENDING',
        searchKeyword: '',
        sellerIdList: [],
        companyIdList: [],
        showFilter: false,
        showSearch: false,
    }

    const [filterData, setfilterData] = useState({});

    useEffect(() => {
        if (serviceRateCardListStatus === 'PENDING') {
            setLoader(true);
        } else {
            setLoader(false);
        }
        if (serviceRateCardListStatus === 'SUCCESS' && serviceRateCardList?.listServiceRateCardEntity?.length) {
            setfilterData({ ...filterData, totalPages: Math.ceil(serviceRateCardList.TotalRecords / filterData.pageSize) });
        }
    }, [serviceRateCardListStatus]);

    useEffect(() => {
        if (!_.isEmpty(productPrevState)) {
            setfilterData({ ...filterData, ...productPrevState });
        } else {
            setfilterData(dataLoad);
        }
    }, []);

    useEffect(() => {
        getAllRateCards();
    }, [filterData.pageSize, filterData.pageIndex, filterData.sortColumn, filterData.sortOrder]);

    const getAllRateCards = () => {
        dispatch(fetchServiceRateCard(filterData));
    }

    const handleAction = (item, type, isView) => {
        navigate(`/service-rate-card/rate-card-${item.id}`);
    }

    const handleSortingColumn = (key) => {
        let newSortOrder = filterData.sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING";
        setfilterData({
            ...filterData,
            sortColumn: key,
            sortOrder: newSortOrder,
        });
    }

    const handleNewRateCard = () => {
        navigate(`/service-rate-card/rate-card-${0}`);
    }

    const handleSearchText = (value) => {
        setfilterData({ ...filterData, searchKeyword: value });
    }

    const handleSearchFilter = () => {
        if (filterData.pageIndex !== 1) {
            setfilterData({ ...filterData, pageIndex: 1 });
        } else {
            getAllRateCards();
        }
    }

    const handleReset = () => {
        setfilterData({ ...filterData, searchKeyword: '' });
    }

    return (
        <main>
            <div className="main__container">
                <div className="main__tile_heading cus-btn-include-sec">
                    <span>
                        Service Rate Card
                    </span>
                    <div className="top_right_button_container">
                        <div className="top_right_button_container">
                            <button
                                className="btn cus-seconday-bg-btn top-btn"
                            // onClick={() => setCsvModalShow(true)}
                            >
                                Bulk Upload
                            </button>
                            <button
                                className="btn cus-seconday-bg-btn top-btn"
                                onClick={handleNewRateCard}
                            >
                                Create
                            </button>

                            <button
                                className="btn cus-seconday-bg-btn top-btn addNewProduct"
                            // onClick={() => handleFilterToggle('showFilter', !filterValue.showFilter)}
                            >
                                Filter
                            </button>
                            <button
                                className="btn cus-seconday-bg-btn openSearch"
                                onClick={() => setfilterData({ ...filterData, showSearch: !filterData.showSearch })}
                            >
                                Search
                            </button>
                        </div>
                    </div>
                </div>
                <SearchProduct
                    showSearch={filterData.showSearch}
                    searchTxt={filterData.searchKeyword}
                    setSearchTxt={handleSearchText}
                    handleReset={handleReset}
                    handleSearchFilter={handleSearchFilter}
                    placeHolderText="Search by Company/Seller name"
                />
                {serviceRateCardListStatus === 'PENDING' && <LoaderSpinner />}
                <div className="main_content cus-page-content-sec srch-pt0">
                    <div className="main_content_table_history">
                        <div className="tableFixHead incomingTbl">
                            <DataTable
                                headers={RateCardColumns}
                                tableData={serviceRateCardList?.listServiceRateCardEntity}
                                actionType={2}
                                handleAction={handleAction}
                                handleSortingColumn={handleSortingColumn}
                                tableDataStatus={serviceRateCardListStatus}
                                filterData={filterData}
                            />
                        </div>
                    </div>
                    <BatchPagination
                        gridData={serviceRateCardList?.listServiceRateCardEntity}
                        totalVisualRecords={serviceRateCardList?.listServiceRateCardEntity?.length}
                        numberOfPage={filterData.pageSize}
                        numOfPage={numOfPage}
                        loader={loader}
                        currentPage={filterData.pageIndex}
                        totalPages={filterData.totalPages}
                        totalRecords={serviceRateCardList.TotalRecords}
                        setCurrentPage={(value) => setfilterData({ ...filterData, pageIndex: value })}
                        setNumberOfPage={(value) => setfilterData({ ...filterData, pageSize: value })}
                    />
                </div>
            </div>
        </main>
    );
}

export default Accounts;