import React from 'react';
import UnbilledTransaction from './UnbilledTransaction';
import SelectMenu from '../../common/SelectMenu';

const Ledger = (props) => {

    const [activeTab, setActiveTab] = React.useState(1);

    return (
        <main>
            <div className="main__container">
                <div className="main__tile_heading cus-btn-include-sec">
                    <span>
                        Ledger
                    </span>
                    <div className='row'>
                        <div class="col-8 fontFix" style={{ minWidth: '100px' }}>
                            <SelectMenu placeholderText="Select seller" />
                        </div>
                        <div className='col-3'>
                            <button className="btn cus-seconday-bg-btn openSearch">
                                Filter
                            </button>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="cus-page-content-sec">
                        <div className="cus-tab-heading-sec">
                            <div className="tabs">
                                {
                                    <>
                                        <button
                                            className={`${activeTab === 1 ? "cus-tab-btn active" : "cus-tab-btn"
                                                }`}
                                            onClick={() => setActiveTab(1)}
                                        >
                                            Unbilled Transactions
                                        </button>
                                        <button
                                            className={`${activeTab === 2 ? "cus-tab-btn active" : "cus-tab-btn"
                                                }`}
                                            onClick={() => setActiveTab(2)}
                                        >
                                            Billed Transactions
                                        </button>
                                    </>
                                }
                                {activeTab === 1 && (
                                    <UnbilledTransaction />
                                )}

                                {activeTab === 2 && (
                                    <UnbilledTransaction />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Ledger;