import React from 'react';
import { Modal } from 'react-bootstrap';
import AdhocBarcodeGeneration from '../../barCode/AdhocGeneration';

const BarCodeGeneratePopup = (props) => {
    const { showPopup, hidePopup, barCodeRecord } = props;
    return (
        <Modal
            {...props}
            show={showPopup}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => {
                hidePopup(!showPopup)
            }}
            className='generateKey modalDeleteWrapper'
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Generate Barcode
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AdhocBarcodeGeneration type={'modal'} barCodeRecord={barCodeRecord} />
            </Modal.Body>
        </Modal>
    );
}

export default BarCodeGeneratePopup;