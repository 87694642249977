import React, { useState } from "react";
import { Button, Table } from "bootstrap-4-react/lib/components";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  getAllCarrierServices,
  updateCarrierStatus,
  sellerCarrierBackDetails,
  sellerCarrierServiceBackDetails,
} from "../../store/actions";
import LoaderSpinner from "../common/Loader";
import AlertDialogModal from "../common/AlertDialog";
import { useEffect } from "react";
import Switch from "react-switch";
import _ from "lodash";
import CompanyCarrierList from "./CompanyCarrier";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../services/StorageService";
import SellerCarrierServices from "./SellerCarrierService";
import { checkPermission } from "../../config/utils";
import { featureSubmodule, featureSubmodulePermission, permissionCarrierEdit, permissionCarrierView, permissionSellerCarrierServicesView, permissionSellerCarrierView, settings, SUBF_SERVICECONFIGIURATION } from "../../constant";

const ManageCarriers = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [alertStatusUpdate, setAlertStatusUpdate] = useState(false);
  const [allCarriers, setAllCarriers] = useState([]);
  const [sortOrder, setSortOrder] = useState("DESCENDING");
  const [sortingColumn, setSortingColumn] = useState("");
  const [sortData, setsortData] = useState({});
  const services = useSelector((state) => state.shipment.carriers);
  const userDetails = useSelector((state) => state.auth.user);
  const employeePermissions = useSelector((state) => state?.sidebar?.employeePermissions);
  const initialTab = getLocalStorageItem('carrierTab') ? Number(getLocalStorageItem('carrierTab')) : 1;
  const [activeTab, setActiveTab] = React.useState(initialTab);

  useEffect(() => {
    setCurrentTab();
  }, []);

  useEffect(() => {
    dispatch(getAllCarrierServices(sortData));
    if (userDetails?.Value && !userDetails?.Value?.isSuperAdmin) {
      navigate("/setting");
    }
  }, [userDetails, sortData]);

  const setCurrentTab = () => {
    if (checkPermission(settings, permissionCarrierView, '', featureSubmodule, props.employeePermissions)) {
      setActiveTab(initialTab ? Number(initialTab) : 1);
      return
    } else if (checkPermission(settings, permissionSellerCarrierView, '', featureSubmodule, props.employeePermissions)) {
      setActiveTab(initialTab ? Number(initialTab) : 2);
      return
    } else if (checkPermission(settings, permissionSellerCarrierServicesView, '', featureSubmodule, props.employeePermissions)) {
      setActiveTab(initialTab ? Number(initialTab) : 3);
      return
    }
  }

  /* istanbul ignore next */
  const handleStatusChange = (status, index) => {
    let allCarriersData = [...allCarriers];
    allCarriersData[index].isActive = status;
    allCarriersData[index].statusChanged = true;
    setAllCarriers(allCarriersData);
    setAlertStatusUpdate(true);
  };

  useEffect(() => {
    if (!services.length) {
      setShowLoader(true);
    } else {
      let allCarriersData = JSON.parse(JSON.stringify(services));
      const myItemsWithIds = allCarriersData.map((item, index) => ({
        ...item,
      }));
      setAllCarriers(myItemsWithIds);
      setShowLoader(false);
    }
  }, [services]);

  const handleCancelAlert = () => {
    setAlertStatusUpdate(false);
    let allCarriersData = JSON.parse(JSON.stringify(services));
    setAllCarriers(allCarriersData);
  };

  const handleUpdateStatus = async () => {
    let response = allCarriers.filter((item) => item.statusChanged)[0];
    let postData = `carrierCompanyId=${response.id}&modifiedBy=${userDetails?.Value?.employeeId}&isActive=${response.isActive}`;
    setShowLoader(true);
    let updateRes = await updateCarrierStatus(postData);
    /* istanbul ignore next */
    if (updateRes.data && updateRes.data.Status === 200) {
      toast.success("Status Updated Successfully");
      setShowLoader(false);
      setAlertStatusUpdate(false);
      dispatch(getAllCarrierServices({}));
    }
  };

  /* istanbul ignore next */
  const handleSortingCoulmn = (event, sortColumn) => {
    event.preventDefault();
    let newSortOrder = "DESCENDING";
    setSortingColumn(sortColumn);
    if (sortColumn === sortingColumn) {
      setSortOrder(sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING");
      newSortOrder = sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING";
    } else {
      setSortOrder("DESCENDING");
    }
    setsortData({
      ...sortData,
      sortColumn: sortColumn,
      sortOrder: newSortOrder,
    });
  };


  const handleActiveTab = (tab) => {
    setActiveTab(tab);
    setLocalStorageItem('carrierTab', tab);
  };

  const renderCarrierServices = () => {
    return (
      <SellerCarrierServices
        {...props}
        navigate={navigate}
        userDetails={userDetails}
        dispatch={dispatch}
        employeePermissions={employeePermissions}
      />
    )
  }

  return (
    <main>
      <div className="main__container">
        <div className="main__tile_heading cus-btn-include-sec">
          Manage Carriers
          <div className="top_right_button_container">
            <Button
              className="cus-primary-transparent-btn"
              onClick={() => {
                navigate("/setting");
                setLocalStorageItem('carrierTab', 1);
                dispatch(sellerCarrierBackDetails({}));
                dispatch(sellerCarrierServiceBackDetails({}));
              }}
            >
              Back
            </Button>
          </div>
        </div>
        {showLoader && <LoaderSpinner />}

        <div className="container-fluid">
          <div className="cus-page-content-sec">
            <div className="cus-tab-heading-sec">
              <div className="tabs">
                {checkPermission(settings, SUBF_SERVICECONFIGIURATION, permissionCarrierView, featureSubmodulePermission, props.employeePermissions) && (
                  <button
                    onClick={() => handleActiveTab(1)}
                    className={`btnTab ${activeTab === 1 ? "cus-tab-btn active" : "cus-tab-btn"
                      }`}
                  >
                    Carrier
                  </button>
                )}
                {checkPermission(settings, SUBF_SERVICECONFIGIURATION, permissionSellerCarrierView, featureSubmodulePermission, props.employeePermissions) && (
                  <button
                    onClick={() => handleActiveTab(2)}
                    className={`btnTab ${activeTab === 2 ? "cus-tab-btn active" : "cus-tab-btn"
                      }`}
                  >
                    Seller Carrier
                  </button>
                )}

                {checkPermission(settings, SUBF_SERVICECONFIGIURATION, permissionSellerCarrierServicesView, featureSubmodulePermission, props.employeePermissions) && (
                  <button
                    onClick={() => handleActiveTab(3)}
                    className={`btnTab ${activeTab === 3 ? "cus-tab-btn active" : "cus-tab-btn"
                      }`}
                  >
                    Seller Carrier Services
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        {activeTab === 1 && (
          <div className="cus-page-content-sec">
            <div className="top_right_button_container cus-content-only-sec">
              <div className="incoming_wrapper main_content_table incomingTbl">
                <div className="tableFixHead">
                  <Table className="table table-borderless keyGenerate">
                    <thead>
                      <tr className="trow">
                        <th className="trows-s">#</th>
                        <th className="trows-s" width={"55%"}>
                          <div
                            className={
                              sortingColumn === "Name" ? "active" : "sortColumn"
                            }
                            onClick={(e) => handleSortingCoulmn(e, "Name")}
                          >
                            Carrier
                            <i
                              className={
                                sortingColumn === "Name" &&
                                  sortOrder === "ASCENDING"
                                  ? "fa fa-arrow-up"
                                  : "fa fa-arrow-down"
                              }
                              aria-hidden="true"
                            ></i>
                          </div>
                        </th>

                        <th className="trows-s" width={"30%"}>
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {allCarriers &&
                        allCarriers.length > 0 &&
                        allCarriers.map((carrier, index) => (
                          <tr key={carrier?.id}>
                            <td className="trows-s">{index + 1}</td>
                            <td className="trows-s">
                              {carrier.carrierCompanyName}
                            </td>
                            <td className="trows-s">
                              {checkPermission(settings, permissionCarrierEdit, '', featureSubmodule, props.employeePermissions) && (
                                <Switch
                                  onChange={(isActive) =>
                                    handleStatusChange(isActive, index)
                                  }
                                  checked={carrier.isActive}
                                  className="switchBtn"
                                />
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeTab === 2 && (
          <CompanyCarrierList
            {...props}
            userDetails={userDetails}
          />
        )}
        {activeTab === 3 && (
          renderCarrierServices()
        )}
      </div>

      {alertStatusUpdate && (
        <AlertDialogModal
          modalShow={alertStatusUpdate}
          handleCancelDeleteAlert={handleCancelAlert}
          modalClass={"modalDeleteWrapper"}
          messageText="Are you sure, you want to update status of this record?"
          handleYesDeleteAlert={handleUpdateStatus}
          headingText={"UPDATE STATUS"}
        />
      )}
    </main>
  );
};

export default ManageCarriers;
