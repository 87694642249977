import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "bootstrap-4-react/lib/components";
import {
  getAllBarCodeType,
  getBarcodeSize,
  CreateAdhocBarCode,
  updateBarcodePrintingSize,
  handleAutoPrintAction,
  getActivePrintListAction,
} from "../../../store/actions/barcode.action";
import { toast } from "react-toastify";
import { downloadPDF, useTextToSpeech } from "../../../config/utils";
import LoaderSpinner from "../../common/Loader";

/*istanbul ignore next */
const AdhocBarcodeGeneration = (props) => {
  const { type, barCodeRecord } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setErrors] = useState({});
  const [pdfBase64, setPdfBase64] = useState("");
  let speakCalled = true;
  const [formData, setFormData] = useState({
    barCodeText: barCodeRecord?.productSku ? barCodeRecord?.productSku : '',
    barCodeTitle: barCodeRecord?.productName ? barCodeRecord?.productName : '',
    barCodeSubTitle: "",
    noOfPrints: 1,
    printPerPage: 0,
    width: 0,
    height: 0,
    fileAction: 1,
    barCodeType: 1,
  });
  const [isdefaultBarcodeValue, setIsdefaultBarcodeValue] = useState(false);
  const [typedata, setTypeData] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [loader, setLoader] = useState(false);
  const { barcodeSizeList, barcodeTypeList } = useSelector(
    (state) => state.barcodeReducer
  );
  const { handleSpeak } = useTextToSpeech();

  useEffect(() => {
    dispatch(getAllBarCodeType());
    dispatch(getBarcodeSize({}));
    setIsDataLoaded(true);
  }, []);

  useEffect(() => {
    if (isDataLoaded) {
      const selectedBarcodeTypeId = barcodeTypeList.find(
        (obj) => obj?.id === barcodeSizeList?.BarCodePrintType
      );
      setFormData({
        ...formData,
        width: barcodeSizeList?.Width,
        height: barcodeSizeList?.Height,
        fileAction: barcodeSizeList?.FileAction,
        barCodeType: barcodeSizeList?.BarCodePrintType,
      });
      setTypeData(selectedBarcodeTypeId?.name);
    }
  }, [barcodeTypeList, barcodeSizeList]);

  const validateAddress = () => {
    let isValid = true;
    let errorData = {};
    if (formData.barCodeText === "") {
      isValid = false;
      errorData.barCodeText = "This is required";
    }
    if (!formData.noOfPrints) {
      isValid = false;
      errorData.noOfPrints = "This is required";
    }
    if (!formData.width) {
      isValid = false;
      errorData.width = "This is required";
    }
    if (!formData.height) {
      isValid = false;
      errorData.height = "This is required";
    }
    setErrors(errorData);
    return isValid;
  };

  const handleChange = (e, type = "") => {
    let { value, name } = e.target;
    setFormData({ ...formData, [name]: type ? Number(value) || null : value });
    if (name === "height" || name === "width") {
      setTypeData("Custom");
    }
    setErrors({ ...error, [name]: "" });
  };

  const handleBarcodeWidthHeight = (e, type = "") => {
    let name = e.target.value;
    const selectedBarcodeType = barcodeTypeList.find(
      (type) => type.name === name
    );
    setTypeData(name);
    if (selectedBarcodeType) {
      if (barcodeSizeList.Width && barcodeSizeList.Height && selectedBarcodeType.id === 1) {
        setFormData({
          ...formData,
          width: barcodeSizeList.Width,
          height: barcodeSizeList.Height,
          barCodeType: selectedBarcodeType.id,
        });
      } else {
        setFormData({
          ...formData,
          width: selectedBarcodeType.width,
          height: selectedBarcodeType.height,
          barCodeType: selectedBarcodeType.id,
        });
      }
    }
    setErrors({ ...error, [name]: "" });
  };

  const handleGenerate = async () => {
    try {
      if (validateAddress()) {
        setLoader(true);
        const selectedBarcodeType = barcodeTypeList.find(
          (obj) => obj.name === typedata
        );
        const createBarCodeRes = await dispatchCreateAdhocBarCode();

        if (isCreateBarCodeSuccessful(createBarCodeRes)) {
          if (isdefaultBarcodeValue === true) {
            dispatch(
              updateBarcodePrintingSize({
                ...formData,
                Width: formData.width,
                Height: formData.height,
                FileAction: formData.fileAction,
                BarCodePrintType: selectedBarcodeType.id,
              })
            );
          }
          handleGenerateSuccess(createBarCodeRes.data.Result.Entity);
        } else {
          handleGenerateFailure(createBarCodeRes);
          setLoader(false);
        }
      } else {
        handleValidationFailure();
        setLoader(false);
      }
    } catch (error) {
      handleGenerateError();
    }
  };

  const dispatchCreateAdhocBarCode = async () => {
    return await Promise.resolve(dispatch(CreateAdhocBarCode(formData)));
  };

  const isCreateBarCodeSuccessful = (createBarCodeRes) => {
    return (
      createBarCodeRes &&
      createBarCodeRes.data?.Result &&
      createBarCodeRes.data.Result.Status === 200
    );
  };

  const handleGenerateFailure = (createBarCodeRes) => {
    setPdfBase64("");
    toast.error(createBarCodeRes.data.Result.Message.AppStatusDescription);
    handleSpeak(createBarCodeRes.data.Result.Message.AppStatusDescription);
  };

  const handleValidationFailure = () => {
    let errorMessage = "Validation failed";
    if (formData.barCodeText == "") errorMessage += " barCode Text";
    if (!formData.noOfPrints) errorMessage += " number Of Prints";
    if (!formData.width) errorMessage += " width";
    if (!formData.height) errorMessage += " height";
    handleSpeak(errorMessage);
  };

  const handleGenerateError = () => {
    toast.error("Looks like you are not connected to printer. Please contact the administrator");
  };

  const handleAutoPrint = (base64Pdf) => {
    getActivePrintListAction()
      .then((res) => {
        const printerList = res?.data?.data || [];
        const findActive = printerList.filter((printer) => printer.isDefault);

        if (findActive.length) {
          handlePrintLoop(findActive[0].printerName, base64Pdf);
        } else {
          handleNoPrinters();
        }
      })
      .catch(() => {
        handleNoPrinters();
      });
  };

  const handlePrintLoop = (printerName, base64Pdf) => {
    for (let i = 0; i < formData.noOfPrints; i++) {
      // eslint-disable-next-line no-loop-func
      setTimeout(() => {
        const data = {
          PrinterName: printerName,
          FileBase64: base64Pdf,
          Height: Number(formData.height),
          Width: Number(formData.width),
        };
        handleAutoPrintAction(data)
          .then(() => {
            if (speakCalled) {
              if (formData.fileAction === 2) {
                handleSpeak("Print successfully");
              } else {
                handleSpeak("Save and print Successfully");
              }
              speakCalled = false;
            }
          })
          .finally(() => {
            setLoader(false);
          })
          .catch(() => {
            handleNoPrinters();
          });
      }, i * 1000);
    }
  };

  const handleNoPrinters = () => {
    toast.error("Looks like you are not connected to printer. Please contact the administrator.");
    setLoader(false);
  };


  const handleGenerateSuccess = (base64Pdf) => {
    if (formData.fileAction === 1) {
      downloadPDF(base64Pdf);
      setPdfBase64(base64Pdf);
      handleSpeak("Saved as pdf successfully");
      setLoader(false);
    }
    if (formData.fileAction === 2) {
      handleAutoPrint(base64Pdf);
    }
    if (formData.fileAction === 3) {
      downloadPDF(base64Pdf);
      handleAutoPrint(base64Pdf);
    }
  };

  return (
    <main className={type ? 'modalBarcode' : ''}>
      <div className="main__container">
        <div className="main__tile_heading cus-btn-include-sec custitle">
          <div>Adhoc Barcode Generation</div>
          <div className="top_right_button_container">
            <Button
              className="cus-seconday-bg-btn top-btn"
              id="savesr"
              onClick={handleGenerate}
              disabled={loader}
            >
              Generate
            </Button>
            <Button
              className="cus-primary-transparent-btn"
              onClick={() => {
                navigate("/barcode");
              }}
              disabled={loader}
            >
              Back
            </Button>
          </div>
        </div>
        {loader && <LoaderSpinner />}
        <div className="main_content cus-page-content-sec incomingTbl">
          <div className="top_right_button_container cus-content-only-sec">
            <div className="incoming_wrapper addressDetailWrapper fontFix">
              <div className="row">
                <div className="col-lg-4 col-sm-12 col-md-12 mobMb-10">
                  <label>Barcode Text Box</label>{" "}
                  <span className="require-field">*</span>
                  <input
                    value={formData.barCodeText}
                    onChange={handleChange}
                    className="form-control availableBalanceInput"
                    type="text"
                    name="barCodeText"
                  />
                  <p className="error dialogError">{error.barCodeText}</p>
                </div>
                <div className="col-lg-4 col-sm-12 col-md-12 mobMb-10">
                  <label>Barcode Title</label>
                  <input
                    value={formData.barCodeTitle}
                    onChange={handleChange}
                    className="form-control availableBalanceInput"
                    type="text"
                    name="barCodeTitle"
                  />
                </div>
                <div className="col-lg-3 col-lg-4 mobMb-10">
                  <label>Sub Title</label>
                  <input
                    value={formData.barCodeSubTitle}
                    onChange={handleChange}
                    className="form-control availableBalanceInput"
                    type="text"
                    name="barCodeSubTitle"
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-lg-3 col-lg-4">
                  <label>Number of Print</label>
                  <span className="require-field">*</span>
                  <input
                    value={formData.noOfPrints}
                    onChange={(e) => handleChange(e, "num")}
                    className="form-control availableBalanceInput"
                    type="number"
                    name="noOfPrints"
                    min={0}
                    max={100}
                    onKeyDown={(evt) =>
                      ["e", ".", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                  />
                  <p className="error dialogError">{error.noOfPrints}</p>
                </div>
                <div className="col-lg-3 col-lg-8 mobMbTop-10">
                  <div className="row">
                    <div className="col-4">
                      <label>
                        Width <span className="error">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        name="width"
                        value={formData.width}
                        min={0}
                        onChange={(e) => handleChange(e, "num")}
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                      />
                      <p className="error dialogError">{error.width}</p>
                    </div>
                    <div className="col-1 alignEnd alignCenter">X</div>
                    <div className="col-4">
                      <label>
                        Height <span className="error">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        name="height"
                        value={formData.height}
                        min={0}
                        onChange={(e) => handleChange(e, "num")}
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                      />
                      <p className="error dialogError">{error.height}</p>
                    </div>
                    <div className="col-2 alignEnd">Inches</div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-lg-3 mobMb-10">
                  <label>Type</label>
                  <select
                    className="selectInput form-control"
                    value={typedata}
                    onChange={(e) => handleBarcodeWidthHeight(e)}
                  >
                    {barcodeTypeList.map((option) => (
                      <option
                        value={option.name}
                        key={option.id}
                        disabled={option.isDisabled}
                      >
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-9">
                  <fieldset
                    id="fileAction"
                    className="codeOptions codeOptions-mob-res"
                    onChange={(e) => handleChange(e, "num")}
                  >
                    <div className="col col-md-12 col-sm-12">
                      <input
                        type="radio"
                        value={1}
                        checked={Number(formData.fileAction) === 1}
                        name="fileAction"
                        id="savePdf"
                      />{" "}
                      <label for="savePdf">Save as Pdf</label>
                    </div>
                    <div className="col col-md-12 col-sm-12">
                      <input
                        type="radio"
                        value={2}
                        checked={Number(formData.fileAction) === 2}
                        name="fileAction"
                        id="print"
                      />{" "}
                      <label for="print">Print</label>
                    </div>
                    <div className="col col-md-12 col-sm-12">
                      <input
                        type="radio"
                        value={3}
                        checked={Number(formData.fileAction) === 3}
                        name="fileAction"
                        id="savePrint"
                      />{" "}
                      <label for="savePrint">Save and Print</label>
                    </div>
                  </fieldset>
                </div>
                <div className="col col-md-12 col-sm-12">
                  <input
                    type="checkbox"
                    checked={isdefaultBarcodeValue}
                    name="isdefaultBarcodeValue"
                    onChange={() => {
                      setIsdefaultBarcodeValue((prevState) => !prevState);
                    }}
                    value={isdefaultBarcodeValue}
                    className="checkBoxField"
                    id="setDefault"
                  />
                  <label for="setDefault">Set As Default Settings</label>
                </div>
              </div>
              {type && (
                <div className="col alignRight">
                  <Button
                    className="cus-seconday-bg-btn top-btn"
                    id="savesr"
                    onClick={handleGenerate}
                    disabled={loader}
                  >
                    Generate
                  </Button>
                </div>
              )}

              {pdfBase64 && (
                <div className="mt-5 alignCenter">
                  <iframe
                    src={`data:application/pdf;base64,${pdfBase64}#view=fit`}
                    height="475px"
                    width="100%"
                    title="PDF"
                  ></iframe>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default AdhocBarcodeGeneration;
