import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCarrierServices, getAllCountries, getDimensionUnitList, getWeightUnitList } from '../../../store/actions';
import {
    createConsignmentElement,
    createConsole,
    deleteConsignments,
    getAllConsignmentCarrierType,
    getAllConsignmentStatus,
    getAllConsignmentType,
    getConsignmentStatusListById,
    getInboundById,
    getInboundDefaultValues,
    updateConsignmentMedia,
    updateConsoleById,
    updateInboundFile
} from '../../../store/actions/inventory.action';
import { Tooltip as WmsTooltip } from "react-tooltip";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LoaderSpinner from '../../common/Loader';
import { allowedExtensions, consoleObject, elementItemObj, elementObj } from '../../../constant';
import { toast } from "react-toastify";
import { appendFilesToUploadData, filterConsignments, findElementStatus, findInvalidRecords, handleDocsUpload } from '../../../config/utils';
import ImageSlider from '../../incoming/ImageSlider';
import noImage from "../../../assets/No-Image-Placeholder.png";
import InLargeImageSlider from '../../modal/InlargeImageSlider';
import ConsignmentDetails from './ConsignmentDetails';
import { v4 as uuidv4 } from 'uuid';

/* istanbul ignore next */
const ConsoleComponent = (props) => {
    const { id } = useParams();
    const [loader, setLoader] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({});
    const [elements, setElements] = useState([]);
    const [originalResponseConsignment, setOriginalResponseConsignment] = useState({});
    const countries = useSelector((state) => state.shipment.countries);
    const userDetail = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const { consignmentTypes, consignmentAllStatus } = useSelector((state) => state.InventoryManagement);
    const [error, setErrors] = useState({});
    const [consignmentTypeList, setConsignmentTypeList] = useState([]);
    const [isEditable, setIsEditable] = useState(location?.state?.isEdit);
    const { dimensionUnitList, weightUnitList } = useSelector((state) => state.shipment);
    const [consignmentStatusList, setConsignmentStatusList] = useState([]);
    const [image, setImage] = useState([]);
    const [allCountries, setAllCountries] = useState([]);
    const [carriers, setCarriers] = useState([]);
    const [showInlargeModal, setshowInlargeModal] = useState(false);
    const [consginmentStatuses, setConsginmentStatuses] = useState([]);
    const backUrl = location?.state?.backUrl;
    const [attachmentFile, setAttachmentFile] = useState([]);
    const [deletedConsignments, setDeletedConsignments] = useState([]);
    const [fileActions, setFileActions] = useState([]);

    useEffect(() => {
        if (countries.length) {
            let allCounty = [...countries];
            setAllCountries(allCounty);
        }
    }, [countries]);

    useEffect(() => {
        if (!dimensionUnitList.length) {
            dispatch(getDimensionUnitList());
        }

        if (!weightUnitList.length) {
            dispatch(getWeightUnitList());
        }
    }, [dimensionUnitList, weightUnitList]);

    useEffect(() => {
        setFormData({
            ...formData,
            consignmentStatusId: findElementStatus(elements, formData, formData.consignmentTypeId),
        });
    }, [elements]);

    const fetchStatuses = () => {
        getConsignmentStatusListById(id).then(res => {
            if (res.data.Status === 200) {
                setConsginmentStatuses(res.data.EntityList || []);
            }
        });
    }

    const handleDeleteImage = (index) => {
        let images = [...image];
        images.splice(index, 1);
        setImage(images);
    };

    const handleDeleteDoc = (item, index) => {
        let files = [...attachmentFile];
        if (item.id) {
            files[index].isDeleted = true;
        } else {
            files.splice(index, 1);
        }
        setAttachmentFile(files);
        let cloneFormData = JSON.parse(JSON.stringify(formData));
        let deletedFiles = [];
        cloneFormData?.files?.map((box) => {
            if (box.id === item.id) {
                deletedFiles.push({
                    ...box,
                    isDeleted: true,
                });
            }
        });
        setFileActions([...fileActions, ...deletedFiles]);
    };

    const handleImageUpload = (e) => {
        let files = [...e.target.files];
        try {
            if (files.length > 0 && files.length <= 5) {
                let filterFiles = files.filter((file, index) => {
                    const extension = file.name.split(".").slice(-1).pop();
                    const fileSize = Math.round(file.size / 1024);
                    /* istanbul ignore next */
                    if (!allowedExtensions.includes(extension ? extension.toLowerCase() : "")) {
                        files.splice(index, 1);
                        toast.error("Unsupported File format", 1000);
                        return false;
                    } else if (fileSize > 1024) {
                        files.splice(index, 1);
                        e.target.value = "";
                        toast.info("Each picture size should be less than 1MB", 1000);
                        return false;
                    }
                    return file;
                });

                setErrors({ ...error, image: "" });
                setImage(filterFiles);
                /* istanbul ignore next */
            } else if (files.length > 5) {
                toast.info("Only 5 images can be upload at one time", 1000);
                e.target.value = "";
            } else {
                e.target.value = "";
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (!consignmentAllStatus?.length) {
            dispatch(getAllConsignmentStatus());
        } else {
            setConsignmentStatusList(consignmentAllStatus);
        }
    }, [consignmentAllStatus]);

    useEffect(() => {
        if (Number(id)) {
            fetchInboundById();
            fetchStatuses();
        } else {
            let conObj = { ...consoleObject }
            fetchDefaultValues(conObj);
        }
    }, [id]);

    useEffect(() => {
        if (userDetail?.Value?.isSuperAdmin || userDetail?.Value?.roleName === 'Warehouse Worker' || userDetail?.Value?.roleName === 'Warehouse Manager') {
            if (Number(id) && originalResponseConsignment.consignmentStatusId === 3) {
                setIsEditable(false);
            }
        } else {
            if (Number(id) && originalResponseConsignment.consignmentStatusId > 1) {
                setIsEditable(false);
            }
        }
    }, [id, originalResponseConsignment]);

    useEffect(() => {
        if (!consignmentTypes.length) {
            dispatch(getAllConsignmentType());
        } else {
            setConsignmentTypeList(consignmentTypes);
        }
    }, [consignmentTypes]);

    useEffect(() => {
        if (!countries.length) {
            dispatch(getAllCountries());
        }
        fetchCarriers();
    }, []);

    const fetchCarriers = () => {
        getAllConsignmentCarrierType().then(res => {
            if (res.data.Status === 200) {
                setCarriers(res.data.EntityList);
            }
        });
    }

    const fetchDefaultValues = async (formObject) => {
        getInboundDefaultValues().then(res => {
            if (res.data.Status === 200) {
                let form = { ...formObject };
                if (!Number(id)) {
                    form.consignmentNumber = res.data.Entity.ConsignmentNumber;
                    form.countryOfOriginId = res.data.Entity.CountryId;
                    form.consignmentTypeId = 3;
                }
                form.sellerCode = res.data.Entity.SellerCode;
                setFormData(form);
            }
        });
    }

    const fetchInboundById = async () => {
        setLoader(true);
        let dataRes = await Promise.resolve(dispatch(getInboundById(id)));
        if (dataRes.data.Status === 200) {
            setOriginalResponseConsignment(JSON.parse(JSON.stringify(dataRes.data.Entity)));
            fetchDefaultValues(dataRes.data.Entity);
            setElements(dataRes.data.Entity.consignmentElements);
            setAttachmentFile(dataRes.data.Entity.files);
        }
        setLoader(false);
    };

    const handleFormValue = (e) => {
        const { name, value } = e.target;
        if (name === 'consignmentTypeId') {
            setElements([]);
        }
        setFormData({ ...formData, [name]: value });
        setErrors({ ...error, [name]: '' });
    };

    const handleStatusChange = (e) => {
        const { name, value } = e.target;
        let consignmentOrderId = e.target.selectedOptions[0].getAttribute('data-set');
        let findStatusList = consignmentStatusList.filter(item => item.id === originalResponseConsignment.consignmentStatusId)[0];

        if ((Number(consignmentOrderId) < findStatusList.consignmentOrder) && id > 0) {
            toast.error('Consignment status cannot be move to backward state');
        } else {
            setFormData({ ...formData, [name]: value });
            setErrors({ ...error, [name]: '' });
        }
    }

    const handleBack = () => {
        if (backUrl) {
            navigate(backUrl, {
                state: {
                    isEditable: isEditable,
                    type: location?.state?.historyType
                }
            });
        } else {
            navigate('/inbound');
        }
    }

    const shouldDisableComponent = () => {
        const isSuperAdmin = userDetail?.Value?.isSuperAdmin;
        const isWarehouseWorkerOrManager =
            userDetail?.Value?.roleName === 'Warehouse Worker' ||
            userDetail?.Value?.roleName === 'Warehouse Manager';
        const consignmentStatusId = originalResponseConsignment.consignmentStatusId;

        const isConsignmentStatusValid = (isSuperAdmin && originalResponseConsignment.consignmentStatusId === 3) || (!isSuperAdmin && !isWarehouseWorkerOrManager && consignmentStatusId > 1)

        return loader || !isEditable || (Number(id) && isConsignmentStatusValid);
    };

    const updateMedia = async (type, consignmentId) => {
        const createFormData = (key, id) => {
            const formData = new FormData();
            formData.append(key, id);
            return formData;
        };

        const uploadData = async (uploadFunc, data) => {
            try {
                const response = await dispatch(uploadFunc(data));
                return response?.data?.Status === 200;
            } catch (error) {
                console.error("Upload failed:", error);
                return false;
            }
        };

        const imageUploadData = createFormData("ConsignmentId", consignmentId);
        const fileUploadData = createFormData("InboundId", consignmentId);

        const filteredImages = image.filter((item) => !item.isImported);
        const filteredDocs = attachmentFile.filter((item) => !item.isImported);

        if (filteredImages.length > 0) {
            appendFilesToUploadData(imageUploadData, filteredImages, "Attachments");
        }
        if (filteredDocs.length > 0) {
            appendFilesToUploadData(fileUploadData, filteredDocs, "Files");
        }

        const uploadResults = await Promise.all([
            filteredImages.length > 0 ? uploadData(updateConsignmentMedia, imageUploadData) : true,
            filteredDocs.length > 0 ? uploadData(updateInboundFile, fileUploadData) : true,
        ]);

        if (uploadResults.every((result) => result)) {
            handleCreateElements(consignmentId, type);
        }
        setLoader(false);
    };

    const validateMandatoryFields = (elements, consignmentTypeId) => {
        if (consignmentTypeId === 1) {
            // Check for mandatory details in pallets & boxes
            const hasMissingDetails = elements.some((item) =>
                Number(item.shippingStatusId) === 0 || filterConsignments(item.consignmentElements)
            );

            if (hasMissingDetails) {
                toast.error('Provide mandatory details for all the Pallet & boxes');
                return false;
            }

            // Check for empty incoming quantities
            const findEmptyIncomingQty = findInvalidRecords(elements, consignmentTypeId);
            if (findEmptyIncomingQty.length > 0) {
                toast.error('Incoming Quantity is mandatory for all the Items');
                return false;
            }
        } else if (consignmentTypeId === 2) {
            // Check for mandatory details in boxes
            const hasMissingBoxDetails = filterConsignments(elements);
            if (hasMissingBoxDetails) {
                toast.error('Provide mandatory details for all the boxes');
                return false;
            }
            const findEmptyIncomingQty = findInvalidRecords(elements, consignmentTypeId);
            if (findEmptyIncomingQty.length > 0) {
                toast.error('Incoming Quantity is mandatory for all the Items');
                return false;
            }
        } else if (consignmentTypeId === 3) {
            const findEmptyIncomingQty = findInvalidRecords(elements, consignmentTypeId);
            if (findEmptyIncomingQty.length > 0) {
                toast.error('Incoming Quantity is mandatory for all the Items');
                return false;
            }
        }

        return true;
    };

    const handleConnectionValidation = () => {
        const consignmentTypeId = Number(formData.consignmentTypeId);
        const elems = [...elements];

        return validateMandatoryFields(elems, consignmentTypeId);
    };

    const handleValidations = () => {
        let error = {};
        let isFormValid = true;
        if (!formData.consignmentNumber) {
            error.consignmentNumber = 'This is required.'
            isFormValid = false;
        }
        if (!Number(formData.carrierTypeId)) {
            error.carrierTypeId = 'This is required.'
            isFormValid = false;
        }
        if (Number(formData.consignmentStatusId) > 1 && !formData.trackingNumber) {
            error.trackingNumber = 'This is required.'
            isFormValid = false;
        }
        setErrors(error);
        return isFormValid && handleConnectionValidation();
    }

    const handleSaveConsole = async () => {
        if (handleValidations()) {
            if (Number(id) === 0) {
                let payloadData = { ...formData };
                payloadData.carrierTypeId = Number(formData.carrierTypeId);
                payloadData.countryOfOriginId = Number(formData.countryOfOriginId);
                payloadData.consignmentTypeId = Number(formData.consignmentTypeId);
                payloadData.consignmentStatusId = Number(formData.consignmentStatusId);
                payloadData.consignmentElements = elements;
                setLoader(true);
                let createConsoleRes = await Promise.resolve(dispatch(createConsole(payloadData)));
                if (createConsoleRes.data.Status === 200) {
                    if (createConsoleRes.data.Entity.id === 0) {
                        toast.error(createConsoleRes.data.Entity.resultMessage);
                        setLoader(false);
                    } else {
                        updateMedia('created', createConsoleRes.data.Entity.id);
                    }
                } else {
                    toast.error('Something went wrong');
                    setLoader(false);
                }
            } else {
                handleUpdateInbound();
            }
        }
    }

    const handleDeleteElement = async () => {
        let deletePayload = deletedConsignments.filter(list => list.id).map((item) => {
            return {
                consignmentType: item.consignmentTypeId,
                id: item.id
            }
        })
        let dataObj = {
            elements: [...deletePayload],
        }
        let deleteRes = await Promise.resolve(dispatch(deleteConsignments(dataObj)));
        if (deleteRes.data.Status !== 200) {
            toast.error('Something went wrong');
            setLoader(false);
        }
    };

    const handleCreateElements = async (inBoundId, type) => {
        if (elements.length) {
            let uploadData = {}
            uploadData.consignmentId = inBoundId;
            uploadData.consignmentElements = [...elements];
            let createConsignmentRes = await Promise.resolve(dispatch(createConsignmentElement(uploadData)));
            if (createConsignmentRes && createConsignmentRes.data.Status === 200) {
                toast.success(`Consignment ${type} Successfully`);
                setLoader(false);
                handleBack();
            } else {
                toast.error(`Something went wrong.`);
                setLoader(false);
            }
        } else {
            handleBack();
            toast.success(`Consignment ${type} Successfully`);
        }
    }

    const handleUpdateInbound = async (type) => {
        setLoader(true);
        if (deletedConsignments.length) {
            handleDeleteElement();
        }
        let payloadData = { ...formData };
        payloadData.carrierTypeId = Number(formData.carrierTypeId);
        payloadData.countryOfOriginId = Number(formData.countryOfOriginId);
        payloadData.consignmentTypeId = Number(formData.consignmentTypeId);
        payloadData.consignmentStatusId = Number(formData.consignmentStatusId);
        payloadData.fileActions = fileActions;
        let updateConsoleRes = await Promise.resolve(dispatch(updateConsoleById(payloadData)));
        if (updateConsoleRes.data.Status === 200 && updateConsoleRes.data.Entity.id !== 0) {
            updateMedia('updated', id);
        } else {
            toast.error(updateConsoleRes.data.Entity.resultMessage);
            setLoader(false);
        }
    }

    const handleAddConsoleType = (e) => {
        e.stopPropagation();
        let cloneElementObj = JSON.parse(JSON.stringify([elementObj]));
        cloneElementObj[0].name = `${formData.sellerCode}_${Number(formData.consignmentTypeId) === 1 ? 'PL' : 'BX'}_${elements.length + 1}`;
        cloneElementObj[0].consignmentTypeId = formData.consignmentTypeId;
        cloneElementObj[0].pId = uuidv4();
        setElements([...elements, ...cloneElementObj]);
    }

    const handleBlurInput = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value.trim() });
    };

    return (
        <main>
            <div className="main__container">
                <div className="main__tile_heading cus-btn-include-sec">
                    <span>Inbound Consignment</span>
                    <div className="top_right_button_container">
                        <div className="top_right_button_container">
                            <button
                                className="btn cus-seconday-bg-btn top-btn"
                                onClick={handleSaveConsole}
                                disabled={shouldDisableComponent()}
                            >
                                Save
                            </button>
                            <button
                                className="btn cus-seconday-bg-btn top-btn"
                                onClick={() => setIsEditable(true)}
                                disabled={(loader || isEditable) || (Number(id) && formData.consignmentStatusId > 1 && !userDetail?.Value?.isSuperAdmin) || (formData.consignmentStatusId === 3)}
                            >
                                Edit
                            </button>
                            <button className="btn cus-primary-transparent-btn" onClick={handleBack} disabled={loader}>
                                Back
                            </button>
                        </div>
                    </div>
                </div>
                {loader && <LoaderSpinner />}

                <div className="cus-page-content-sec">
                    <div className='row equalHeight'>
                        <div className={Number(id) > 0 ? 'col-sm-12 col-lg-9' : 'col-12'}>
                            <div className="incoming_wrapper main_content_table addressDetailWrapper addressForm fontFix">
                                <div className='row mb-3'>
                                    <div className='col-lg-3 col-md-6 col-sm-12 mobMb-10'>
                                        <label>Inbound Consignment ID <span className='require-field'>*</span></label>
                                        <input
                                            className={`form-control`}
                                            type={'text'}
                                            name="consignmentNumber"
                                            value={formData.consignmentNumber}
                                            onChange={handleFormValue}
                                            disabled={!isEditable || Number(id) > 0}
                                            onBlur={handleBlurInput}
                                        />
                                        <div className='error'>{error.consignmentNumber}</div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-12 mobMb-10'>
                                        <label>Consignment Type</label>
                                        <select
                                            value={formData.consignmentTypeId}
                                            className={`form-control consignmentTypeId`}
                                            onChange={handleFormValue}
                                            name='consignmentTypeId'
                                            disabled={!isEditable || Number(id) > 0}
                                        >
                                            {consignmentTypeList?.map((item) => (
                                                <option
                                                    value={item.id}
                                                    key={item.id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                        <div className='error'>{error.consignmentTypeId}</div>
                                    </div>

                                    <div className='col-lg-3 col-md-6 col-sm-12 mobMb-10'>
                                        <label className='zIndex1'>Consignment Status {consginmentStatuses.length > 0 && (
                                            <>
                                                <i className="fa fa-info-circle" aria-hidden="true" id="infoStatus"></i>
                                                <WmsTooltip
                                                    anchorId={`infoStatus`}
                                                    place="right"
                                                    data-tooltip-html={true}
                                                    className="tooltipWidth"
                                                >
                                                    <ul>
                                                        {consginmentStatuses.length > 0 && consginmentStatuses.map((list) => (
                                                            <li key={list.name}>{list.name} - {list.status}</li>
                                                        ))}
                                                    </ul>
                                                </WmsTooltip>
                                            </>
                                        )}
                                        </label>
                                        <select
                                            className={`form-control carrierSelect`}
                                            onChange={handleStatusChange}
                                            value={formData.consignmentStatusId}
                                            disabled={!isEditable}
                                            name={'consignmentStatusId'}>
                                            {consignmentStatusList?.map((item) => (
                                                <option
                                                    value={item.id}
                                                    key={item.id}
                                                    data-set={item.consignmentOrder}
                                                    disabled={
                                                        (Number(id) === 0 && (item.id === 3 || item.id === 4)) ||
                                                        ((item.id === 3 || item.id === 4) && !userDetail?.Value?.isSuperAdmin && !userDetail?.Value?.roleName == 'Warehouse Worker' && !userDetail?.Value?.roleName == 'Warehouse Manager')
                                                    }
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className='col-lg-3 col-md-6 col-sm-12 mobMb-10'>
                                        <label>Origin Country</label>
                                        <select
                                            className={`form-control countrySelect`}
                                            onChange={handleFormValue}
                                            value={Number(formData.countryOfOriginId)}
                                            name={'countryOfOriginId'}
                                            disabled={!isEditable}
                                        >
                                            <option>Select</option>
                                            {allCountries?.map((country) => {
                                                return (
                                                    <option
                                                        value={country.id}
                                                        key={country.id}
                                                    >
                                                        {country.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <div className='col-lg-3 col-md-6 col-sm-12 mobMb-10'>
                                        <label>Carrier <span className='require-field'>*</span></label>
                                        <select
                                            className={`form-control carrierSelect`}
                                            onChange={handleFormValue}
                                            value={formData.carrierTypeId}
                                            disabled={!isEditable}
                                            name={'carrierTypeId'}
                                        >
                                            <option value={'0'}>Select Carrier</option>
                                            {carriers?.map((item) => (
                                                <option
                                                    value={item.id}
                                                    key={item.id}
                                                >
                                                    {item.carrierName}
                                                </option>
                                            ))}
                                        </select>
                                        <div className='error'>{error.carrierTypeId}</div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-12 mobMb-10'>
                                        <label>Tracking Number</label>
                                        <input
                                            className={`form-control`}
                                            type={'text'}
                                            name="trackingNumber"
                                            value={formData.trackingNumber}
                                            onChange={handleFormValue}
                                            disabled={!isEditable}
                                            onBlur={handleBlurInput}
                                        />
                                        <div className='error'>{error.trackingNumber}</div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                        <label>Sub Tracking Number</label>
                                        <input
                                            className={`form-control`}
                                            type={'text'}
                                            name="subTrackingNumber"
                                            value={formData.subTrackingNumber}
                                            onChange={handleFormValue}
                                            disabled={!isEditable}
                                            onBlur={handleBlurInput}
                                        />
                                        <div className='error'>{error.subTrackingNumber}</div>
                                    </div>


                                </div>

                                <div className='row mb-3'>
                                    <div className='col-lg-3 col-md-6 col-sm-12 mobMb-10'>
                                        <label>Images</label>
                                        <div className='uploadContainer'>
                                            <div className="inlineBlock fileUpload inventory">
                                                <button
                                                    disabled={!isEditable}
                                                    type="button"
                                                    className="btn cus-seconday-bg-btn imgUploadbtn"
                                                >
                                                    Upload images
                                                </button>
                                                <input
                                                    className="hiddenFileField"
                                                    onChange={handleImageUpload}
                                                    disabled={!isEditable}
                                                    type="file"
                                                    id="customFile"
                                                    multiple
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            {image.length > 0 &&
                                                image.map((item, index) => (
                                                    <div
                                                        className="selectedFile"
                                                        key={item?.id}
                                                    >
                                                        <div className="fileName">{item.name}</div>
                                                        <button
                                                            className='deleteImg'
                                                            onClick={() => handleDeleteImage(index)}
                                                        >
                                                            <i
                                                                className="fa fa-times"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </button>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                        <label>Attachments</label>
                                        <div className='uploadContainer'>
                                            <div className="inlineBlock fileUpload inventory">
                                                <button
                                                    disabled={!isEditable}
                                                    type="button"
                                                    className="btn cus-seconday-bg-btn"
                                                >
                                                    Upload Documents
                                                </button>
                                                <input
                                                    className="hiddenFileField"
                                                    onChange={(e) => {
                                                        handleDocsUpload(e, toast, error, setErrors, setAttachmentFile, attachmentFile);
                                                    }}
                                                    disabled={!isEditable}
                                                    type="file"
                                                    id="customFile"
                                                    multiple
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            {attachmentFile.length > 0 &&
                                                attachmentFile.map((item, index) => (
                                                    <>
                                                        {!item.isDeleted && (
                                                            <div className="selectedFile mr-2" key={item?.id}>
                                                                <div className="fileName">
                                                                    {item.fileUrl ? (
                                                                        <a
                                                                            href={item.fileUrl}
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            {item.name}
                                                                        </a>
                                                                    ) : (
                                                                        <>{item.name}</>
                                                                    )}
                                                                </div>
                                                                <button
                                                                    className="deleteFile"
                                                                    onClick={() =>
                                                                        handleDeleteDoc(item, index)
                                                                    }
                                                                    disabled={!isEditable}
                                                                >
                                                                    <i className="fa fa-times" aria-hidden="true"></i>
                                                                </button>
                                                            </div>
                                                        )}
                                                    </>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {Number(id) > 0 && (
                            <div className='col-lg-3 col-sm-12 mobMbTop-10 inlineFlex'>
                                <div className="main_content_table addressDetailWrapper imageBlock" style={{ textAlign: 'center' }}>
                                    {formData?.images?.length > 0 ? (
                                        <ImageSlider
                                            isView={isEditable}
                                            images={formData.images}
                                            showLargeSliderModal={showInlargeModal}
                                            setShowLargeSliderModal={setshowInlargeModal}
                                        />
                                    ) : (
                                        <img src={noImage} alt="" className='inBoundDefaultImage' />
                                    )}
                                </div>
                            </div>
                        )}

                        <div className="incomingAccordion accordion mt-3 fontFix">
                            <ConsignmentDetails
                                {...props}
                                consignmentId={id}
                                isEditable={isEditable}
                                elements={elements}
                                setElements={setElements}
                                formData={formData}
                                handleAddConsoleType={handleAddConsoleType}
                                setLoader={setLoader}
                                setDeletedConsignments={setDeletedConsignments}
                                deletedConsignments={deletedConsignments}
                                userDetail={userDetail}
                            />
                        </div>

                        {formData?.images?.length > 0 &&
                            <InLargeImageSlider
                                popupTitle={'Product'}
                                view={true}
                                images={formData?.images}
                                showModal={showInlargeModal}
                                setShowLargeSliderModal={setshowInlargeModal}
                            />
                        }
                    </div>
                </div>
            </div>
        </main>
    )
}
export default ConsoleComponent;
