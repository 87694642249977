import React, { useState, useEffect, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector, useDispatch } from "react-redux";
import { InputGroup, Form } from "react-bootstrap";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Button } from "bootstrap-4-react/lib/components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import noImage from "../../../assets/No-Image-Placeholder.png";

import {
    createproduct,
    getProductConditionList,
    getProductDetails,
    getProductProcessTypeList,
    getProductStatusList,
    getProductWarehouseList,
    updateMediaForProduct,
    updateproduct,
} from "../../../store/actions/incoming";
import moment from "moment";
import LoaderSpinner from "../../common/Loader";
import { allowedExtensions, videoallowedExtensions } from "../../../constant";
import ImageSlider from "../ImageSlider";
import InLargeImageSlider from "../../modal/InlargeImageSlider";
import RenderImages from "./images";
import RenderVideos from "./RenderVideos";

/*istanbul ignore next */
const EditProduct = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { view, date, landingSearch, trackingNumber, productType } = location?.state;
    const videoInputRef = useRef(null);
    const [image, setImage] = useState([]);

    const [showBeforeProcessingModal, setShowBeforeProcessingModal] = useState(false);
    const [showAfterProcessingModal, setShowAfterProcessingModal] = useState(false);

    const [afterImage, setAfterImage] = useState([]);
    const [error, setErrors] = useState({});
    const [loader, setLoader] = useState(false);
    const [video, setVideo] = useState();
    const [isFormEdited, setIsFormEdited] = useState(false);
    const UserValue = useSelector((state) => state.auth.user);
    const productDetailsById = useSelector(
        (state) => state?.IncomingProductReducer?.productDetailsById
    );
    const productDetailStatus = useSelector(
        (state) => state?.IncomingProductReducer?.productDetailStatus
    );
    const productStatusList = useSelector(
        (state) => state?.IncomingProductReducer?.productStatusList
    );
    const productConditionList = useSelector(
        (state) => state?.IncomingProductReducer?.productConditionList
    );
    const productWarehouseList = useSelector(
        (state) => state?.IncomingProductReducer?.productWarehouseList
    );
    const productProcessList = useSelector(
        (state) => state?.IncomingProductReducer?.productProcessList
    );
    const [formData, setFormData] = useState({
        modifiedDate: new Date(),
        createdDate: new Date(),
        modifiedBy: UserValue?.Value?.employeeId,
        incomingDate: new Date(),
    });

    const dispatch = useDispatch();
    let { id } = useParams();
    let isCreateForm = parseInt(id) === 0;

    useEffect(() => {
        if (parseInt(id) !== 0) {
            dispatch(getProductDetails(id));
        }
    }, [id]);


    useEffect(() => {
        if (!productStatusList?.length) {
            dispatch(getProductStatusList());
        }
        if (!productConditionList?.length) {
            dispatch(getProductConditionList());
        }
        if (!productWarehouseList?.length) {
            dispatch(getProductWarehouseList());
        }
        if (!productProcessList?.length) {
            dispatch(getProductProcessTypeList());
        }
    }, []);

    useEffect(() => {
        if (parseInt(id) !== 0) {
            setFormData(productDetailsById);
        } else {
            setFormData({
                ...formData,
                companySeller_Id: UserValue?.Value?.companySellerId,
            });
        }
    }, [productDetailsById]);

    useEffect(() => {
        if (productDetailStatus === "pending") {
            setLoader(true);
        } else {
            setLoader(false);
        }
    }, [productDetailStatus]);

    const validateForm = () => {
        let isValid = true;
        let errorData = {};

        if (UserValue?.Value?.roleName === "Seller" && !formData?.notes) {
            isValid = false;
            errorData.notes = "Notes is required";
            setErrors(errorData);
            return isValid;
        } else {
            if (!formData?.inboundLocation_Id) {
                isValid = false;
                errorData.inboundLocation_Id = "This is required";
            }
            if (!formData?.inboundProductCondition_Id) {
                isValid = false;
                errorData.inboundProductCondition_Id = "This is required";
            }
            if (!formData?.sku) {
                isValid = false;
                errorData.sku = "This is required";
            }
            if (!formData?.itemName) {
                isValid = false;
                errorData.itemName = "This is required";
            }

            if (!formData?.inboundProductStatus_Id) {
                isValid = false;
                errorData.inboundProductStatus_Id = "This is required";
            }

            setErrors(errorData);
            return isValid;
        }
    };

    const validateCreateForm = () => {
        let isValid = true;
        let errorData = {};
        if (
            formData?.inboundLocation_Id == "0" ||
            formData?.inboundLocation_Id == undefined
        ) {
            isValid = false;
            errorData.inboundLocation_Id = "This is required";
        }
        if (
            formData?.inboundProductCondition_Id == "0" ||
            formData?.inboundProductCondition_Id == undefined
        ) {
            isValid = false;
            errorData.inboundProductCondition_Id = "This is required";
        }
        if (!formData?.sku) {
            isValid = false;
            errorData.sku = "This is required";
        }
        if (!formData?.itemName) {
            isValid = false;
            errorData.itemName = "This is required";
        }

        if (
            formData?.inboundProductStatus_Id == "0" ||
            formData?.inboundProductStatus_Id == undefined
        ) {
            isValid = false;
            errorData.inboundProductStatus_Id = "This is required";
        }

        setErrors(errorData);
        return isValid;
    };

    const handleFormChange = (e) => {
        const { value, name } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });
        setErrors({
            ...error,
            [name]: "",
        });
        setIsFormEdited(true);
    };

    const handleSliderDeleteImage = (e, item) => {
        let formDataCopyObj = JSON.parse(JSON.stringify(formData));
        let updateBeforeProcessingImages =
            formDataCopyObj.beforeProcessingImages.map((imagePack) => {
                return {
                    ...imagePack,
                    isDeleted: item.id === imagePack.id ? true : false,
                };
            });
        let updateAfterProcessingImages = formDataCopyObj.afterProcessingImages.map(
            (imagePack) => {
                return {
                    ...imagePack,
                    isDeleted: item.id === imagePack.id ? true : false,
                };
            }
        );

        if (updateBeforeProcessingImages.length) {
            formDataCopyObj.beforeProcessingImages = updateBeforeProcessingImages;
        }
        if (updateAfterProcessingImages.length) {
            formDataCopyObj.afterProcessingImages = updateAfterProcessingImages;
        }

        setIsFormEdited(true);
        setFormData(formDataCopyObj);
    };


    const handleMarkPrimary = (e, item) => {
        let formDataCopyObj = JSON.parse(JSON.stringify(formData));

        let updateBeforeProcessingImages =
            formDataCopyObj.beforeProcessingImages.map((imagePack) => {
                return {
                    ...imagePack,
                    isPrimary: item.id === imagePack.id ? true : false,
                };
            });
        let updateAfterProcessingImages = formDataCopyObj.afterProcessingImages.map(
            (imagePack) => {
                return {
                    ...imagePack,
                    isPrimary: item.id === imagePack.id ? true : false,
                };
            }
        );
        formDataCopyObj.beforeProcessingImages = updateBeforeProcessingImages;
        formDataCopyObj.afterProcessingImages = updateAfterProcessingImages;
        setFormData(formDataCopyObj);
        setIsFormEdited(true);
    };


    const handleCreateForm = async () => {
        let data = { ...formData };
        data.createdBy = UserValue?.Value?.employeeId;
        setLoader(true);

        let response = await createproduct(data);
        if (response && response?.data?.Status === 200) {
            let uploadData = new FormData();
            uploadData.append("id", response?.data?.Entity);
            uploadData.append("modifiedBy", UserValue?.Value?.employeeId);

            uploadData = appendFilesToUploadData(uploadData, video, 'video');
            uploadData = appendFilesToUploadData(uploadData, image, 'beforeProcessingImages');
            uploadData = appendFilesToUploadData(uploadData, afterImage, 'afterProcessingImages');

            let uploadRes = await updateMediaForProduct(uploadData);
            if (uploadRes && uploadRes?.data?.Status === 200) {
                navigate(`/incoming-products`,
                    { state: { view: true, date: location?.state?.date } }
                );

                setIsFormEdited(false);
                toast.success("Changes Saved Successfully");

                dispatch(getProductDetails(response?.data?.Entity));
                setVideo(null);
                setImage([]);
                setAfterImage([]);
            } else {
                toast.error(response?.data?.Message?.AppStatusDescription);
            }
            setLoader(false);
        } else {
            setLoader(false);
            toast.error(response?.data?.Message?.AppStatusDescription);
        }
    }

    const appendFilesToUploadData = (uploadData, files, key) => {
        if (files) {
            for (let i = 0; i < files.length; i++) {
                uploadData.append(key, files[i]);
            }
        }
        return uploadData;
    };

    const handleSave = async (e) => {
        e.preventDefault();
        if (isCreateForm) {

            if (validateCreateForm()) {
                handleCreateForm();
            }
        } else {
            if (validateForm()) {
                setLoader(true);
                let updateRes = JSON.parse(JSON.stringify(formData));
                updateRes.companySellerId = UserValue?.Value?.companySellerId;
                updateRes.modifiedBy = UserValue?.Value?.employeeId;
                updateRes.imageActions = [...updateRes.afterProcessingImages.filter((item) => item.isPrimary || item.isDeleted),
                ...updateRes.beforeProcessingImages.filter(
                    (item) => item.isPrimary || item.isDeleted
                ),
                ];
                handleUpdateProduct(updateRes);
            }
        }
    };

    const handleUpdateProduct = async (updateRes) => {
        let response = await updateproduct(updateRes);
        if (response && response?.data?.Status === 200) {
            let uploadData = new FormData();
            uploadData.append("id", id);
            uploadData.append("modifiedBy", 1);

            uploadData = appendFilesToUploadData(uploadData, video, 'video');
            uploadData = appendFilesToUploadData(uploadData, image, 'beforeProcessingImages');
            uploadData = appendFilesToUploadData(uploadData, afterImage, 'afterProcessingImages');

            let uploadRes = await updateMediaForProduct(uploadData);
            if (uploadRes && uploadRes?.data?.Status === 200) {
                navigate(
                    `/incoming-product/fd-${new Date(
                        formData.createdDate
                    ).toISOString()}-3pl/${id}`,
                    {
                        state: {
                            view: true,
                            date: location?.state?.date,
                            landingSearch,
                            trackingNumber,
                        },
                    }
                );
                toast.success("Changes Saved Successfully");
                setIsFormEdited(false);
                dispatch(getProductDetails(id));
                setVideo(null);
                setImage([]);
                setAfterImage([]);
            } else {
                toast.error(response?.data?.Message?.AppStatusDescription);
            }
            setLoader(false);
        }
    }

    const handleImageUpload = (e) => {
        let files = [...e.target.files];
        try {
            if (files.length > 0 && files.length <= 5) {
                let filterFiles = files.filter((file, index) => {
                    const extension = file.name.split(".").slice(-1).pop();
                    const fileSize = Math.round(file.size / 1024);

                    if (
                        !allowedExtensions.includes(
                            extension ? extension.toLowerCase() : ""
                        )
                    ) {
                        files.splice(index, 1);
                        toast.error("Unsupported File format", 1000);

                        return false;
                    } else if (fileSize > 1024) {
                        files.splice(index, 1);
                        e.target.value = "";
                        toast.info("Each picture size should be less than 1MB", 1000);
                        return false;
                    }
                    return file;
                });
                setErrors({ ...error, image: "" });
                setImage(filterFiles);
                setIsFormEdited(true);
            } else if (files.length > 5) {
                toast.info("5 images can be upload at one time", 1000);
                e.target.value = "";
            } else {
                e.target.value = "";
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleDeleteImage = (index) => {
        let images = [...image];
        images.splice(index, 1);
        setImage(images);
    };

    const handleAfterImageUpload = (e) => {
        let files = [...e.target.files];
        try {
            if (files.length > 0 && files.length <= 5) {
                let filterFiles = files.filter((file, index) => {
                    const extension = file.name.split(".").slice(-1).pop();
                    const fileSize = Math.round(file.size / 1024);
                    if (
                        !allowedExtensions.includes(
                            extension ? extension.toLowerCase() : ""
                        )
                    ) {
                        files.splice(index, 1);
                        toast.error("Unsupported File format", 1000);
                        return false;
                    } else if (fileSize > 1024) {
                        files.splice(index, 1);
                        e.target.value = "";
                        toast.info("Each picture size should be less than 1MB", 1000);
                        return false;
                    }
                    return file;
                });
                setErrors({ ...error, image: "" });
                setAfterImage(filterFiles);
                setIsFormEdited(true);

            } else if (files.length > 5) {

                toast.error("5 images can be upload at one time", 1000);
                e.target.value = "";
            } else {
                e.target.value = "";
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleVideoUpload = (e) => {
        let files = [...e.target.files];
        try {
            if (files.length > 0 && files.length <= 5) {
                let filterFiles = files.filter((file, index) => {
                    const extension = file.name.split(".").slice(-1).pop();
                    const fileSize = file.size / (1024 * 1024); // Convert size to MB
                    if (fileSize > 10) {
                        files.splice(index, 1);
                        toast.error("Video size should be less than 10MB", 1000);
                        e.target.value = "";
                        return false;
                    } else if (
                        !videoallowedExtensions.includes(
                            extension ? extension.toLowerCase() : ""
                        )
                    ) {
                        files.splice(index, 1);
                        toast.error("Unsupported file format", 1000);
                        return false;
                    }
                    return file;
                });
                setErrors({ ...error, video: "" });
                setVideo(filterFiles);
                setIsFormEdited(true);
                if (e.target.files.length !== files.length) {
                    toast.info("Each video size should be less than 10MB", 1000);
                    e.target.value = "";
                }
            } else if (files.length > 5) {
                toast.info("Up to 5 videos can be uploaded at one time", 1000);
                e.target.value = "";
            } else {
                e.target.value = "";
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleEdit = () => {
        navigate(`/incoming-product/fd-${date}-3pl/${id}`, {
            state: {
                view: false,
                date: location?.state?.date,
                landingSearch,
                trackingNumber,
            },
        });
    };

    const handleBack = () => {
        if (isCreateForm) {
            navigate(`/incoming-products`);
        } else if (landingSearch || landingSearch === "") {
            navigate(`/incoming-products/fd-${date}-3pl`, {
                state: { dateValue: location?.state?.date, searchTxt: landingSearch },
            });
        } else if (trackingNumber) {
            navigate(`/incoming-products/fd-${date}-3pl`, {
                state: { trackingNumber },
            });
        } else if (productType === "allProducts") {
            navigate(`/incoming-product-listing`, {
            });
        } else {
            navigate(`/incoming-products/fd-${date}-3pl`, {
                state: { date: location?.state?.date, searchTxt: landingSearch },
            });
        }
    };

    const handleDateChange = (e, name) => {
        setFormData({
            ...formData,
            [name]: moment(e).format("MM-DD-YYYY"),
        });
        setIsFormEdited(true);
    };

    const handleUpdateDeleteVideo = () => {
        setFormData({
            ...formData,
            videoUrl: "",
            deleteVideo: true,
        });
        setIsFormEdited(true);
    };

    const renderTitle = () => {
        let title;
        if (parseInt(id) === 0) {
            title = "Create Product";
        } else if (!view) {
            title = "Edit Product Details";
        } else {
            title = "Product Details";
        }
        return title
    }

    return (
        <main>
            <div className="main__container">
                <div className="main__tile_heading cus-btn-include-sec">
                    {renderTitle()}
                    <div></div>
                    <div className="top_right_button_container">
                        {isFormEdited ? (
                            <Button
                                disabled={!isFormEdited}
                                className="cus-seconday-bg-btn top-btn"
                                id="savesr"
                                onClick={handleSave}
                            >
                                Save
                            </Button>
                        ) : (
                            <Button
                                disabled={!isFormEdited}
                                className="cus-seconday-bg-btn top-btn"
                                id="savesr"
                            >
                                Save
                            </Button>
                        )}
                        {!isCreateForm && (
                            <Button
                                disabled={!view}
                                className="cus-seconday-bg-btn top-btn"
                                id="hde"
                                onClick={handleEdit}
                            >
                                Edit
                            </Button>
                        )}
                        <Button
                            className="cus-primary-transparent-btn top-btn"
                            id="hdb"
                            onClick={() => handleBack()}
                        >
                            Back
                        </Button>
                    </div>
                </div>
                {loader && <LoaderSpinner />}
                <div className="main_content cus-page-content-sec">
                    <div className="top_right_button_container cus-content-only-sec">
                        <div className="incoming_wrapper pt-3 fontFix">
                            <div className="row">
                                <div className={parseInt(id) === 0 ? "col-12" : "col-lg-8 col-sm-12"}>
                                    <Row className="cus-mar-bottom20 p-2 rowBottomSpace">
                                        <Col xs={12} lg={3}>
                                            <InputGroup>
                                                <div className="input-label-Created-date font-weight-boldss">
                                                    Serial No <span className="require-field">*</span>
                                                </div>
                                                <div className="input-box">
                                                    <input
                                                        disabled={view}
                                                        onChange={handleFormChange}
                                                        value={formData?.sku}
                                                        name={"sku"}
                                                        className="form-control"
                                                        maxLength={100}
                                                    />
                                                </div>
                                                {error.sku && (
                                                    <label className="requiredthis">
                                                        *This is required
                                                    </label>
                                                )}
                                            </InputGroup>
                                        </Col>

                                        <Col xs={12} lg={3}>
                                            <InputGroup>
                                                <div className="input-label-Created-date font-weight-boldss">
                                                    Item Name <span className="require-field">*</span>
                                                </div>
                                                <div className="input-box">
                                                    <input
                                                        disabled={view}
                                                        onChange={handleFormChange}
                                                        value={formData?.itemName}
                                                        name={"itemName"}
                                                        className="form-control"
                                                        maxLength={100}
                                                    />
                                                </div>
                                                {error.itemName && (
                                                    <label className="requiredthis">
                                                        *This is required
                                                    </label>
                                                )}
                                            </InputGroup>
                                        </Col>
                                        <Col xs={12} lg={3}>
                                            <div className="input-label-Created-date font-weight-boldss">
                                                Warehouse <span className="require-field">*</span>
                                            </div>
                                            <div>
                                                <select
                                                    disabled={view}
                                                    onChange={handleFormChange}
                                                    value={formData?.inboundLocation_Id}
                                                    name={"inboundLocation_Id"}
                                                    className="form-control noMarginBtm"
                                                >
                                                    {isCreateForm && (
                                                        <option selected value={0}>
                                                            Select
                                                        </option>
                                                    )}
                                                    {productWarehouseList?.length &&
                                                        productWarehouseList.map((item) => (
                                                            <option value={item.id} key={item.id}>
                                                                {item.location}
                                                            </option>
                                                        ))}
                                                </select>
                                                {error.inboundLocation_Id && (
                                                    <label className="requiredthis">
                                                        *This is required
                                                    </label>
                                                )}
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={3}>
                                            <InputGroup>
                                                <div className="input-label-Created-date font-weight-boldss">
                                                    Condition <span className="require-field">*</span>
                                                </div>
                                                <div>
                                                    <select
                                                        disabled={view}
                                                        onChange={handleFormChange}
                                                        value={formData?.inboundProductCondition_Id}
                                                        name={"inboundProductCondition_Id"}
                                                        className="form-control noMarginBtm"
                                                    >
                                                        {isCreateForm && (
                                                            <option selected value={0}>
                                                                Select
                                                            </option>
                                                        )}
                                                        {productConditionList?.length &&
                                                            productConditionList.map((item, index) => (
                                                                <option key={item.id} value={item.id}>
                                                                    {item.condition}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </div>
                                                {error.inboundProductCondition_Id && (
                                                    <label className="requiredthis">
                                                        *This is required
                                                    </label>
                                                )}
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row className="cus-mar-bottom20 p-2 rowBottomSpace">
                                        <Col xs={12} lg={3}>
                                            <InputGroup>
                                                <div className="input-label-Created-date font-weight-boldss">
                                                    Quantity
                                                </div>
                                                <div className="input-box">
                                                    <input
                                                        disabled={view}
                                                        onChange={handleFormChange}
                                                        value={formData?.quantity}
                                                        name={"quantity"}
                                                        type="number"
                                                        min={1}
                                                        className="form-control"
                                                        maxLength={100}
                                                    />
                                                </div>

                                            </InputGroup>
                                        </Col>

                                        <Col xs={12} lg={3}>
                                            <InputGroup>
                                                <div className="input-label-Created-date font-weight-boldss">
                                                    Size
                                                </div>
                                                <div className="input-box">
                                                    <input
                                                        disabled={view}
                                                        onChange={handleFormChange}
                                                        value={formData?.size}
                                                        name={"size"}
                                                        className="form-control"
                                                        maxLength={100}
                                                    />
                                                </div>

                                            </InputGroup>
                                        </Col>
                                        <Col xs={12} lg={3}>
                                            <InputGroup>
                                                <div className="input-label-Created-date font-weight-boldss">
                                                    Product Expiry{" "}
                                                </div>
                                                <div>
                                                    <DatePicker
                                                        disabled={view}
                                                        className="form-control receivedDate"
                                                        onChange={(receivedDate) =>
                                                            handleDateChange(receivedDate, "productExpiry")
                                                        }
                                                        showIcon
                                                        value={
                                                            formData?.productExpiry || isCreateForm
                                                                ? moment(formData?.productExpiry).format(
                                                                    "MM-DD-YYYY"
                                                                )
                                                                : null
                                                        }
                                                        name={"productExpiry"}
                                                    />
                                                </div>
                                            </InputGroup>
                                        </Col>
                                        <Col xs={12} lg={3}>
                                            <InputGroup>
                                                <div className="input-label-Created-date font-weight-boldss">
                                                    RMA Code
                                                </div>
                                                <div className="input-box">
                                                    <input
                                                        disabled={view}
                                                        onChange={handleFormChange}
                                                        value={formData?.rmaCode}
                                                        name={"rmaCode"}
                                                        className="form-control"
                                                        maxLength={100}
                                                    />
                                                </div>

                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row className="cus-mar-bottom p-2 rowBottomSpace">
                                        {/* <Col xs={3}>
                        <InputGroup>
                          <div className="input-label-Created-date font-weight-boldss">
                            Processed By<span className="require-field">*</span>
                          </div>
                          <div className="input-box">
                            <input
                              disabled={view}
                              onChange={handleFormChange}
                              value={formData?.processedBy}
                              name={"processedBy"}
                              className="form-control"
                              maxLength={100}
                            />
                          </div>
                          {error.processedBy && (
                            <label className="requiredthis">
                              *This is required
                            </label>
                          )}
                        </InputGroup>
                      </Col> */}
                                        <Col xs={12} lg={3}>
                                            <InputGroup>
                                                <div className="input-label-Created-date font-weight-boldss">
                                                    Incoming Date{" "}
                                                    <span className="require-field">*</span>
                                                </div>
                                                <div>
                                                    <DatePicker
                                                        disabled={view}
                                                        className="form-control receivedDate"
                                                        onChange={(receivedDate) =>
                                                            handleDateChange(receivedDate, "incomingDate")
                                                        }
                                                        showIcon
                                                        value={moment(formData?.incomingDate).format(
                                                            "MM-DD-YYYY"
                                                        )}
                                                        name={"incomingDate"}
                                                    />
                                                </div>
                                            </InputGroup>
                                        </Col>
                                        <Col xs={12} lg={3}>
                                            <InputGroup>
                                                <div className="input-label-Created-date font-weight-boldss">
                                                    Package Tracking Id
                                                </div>
                                                <div className="input-box">
                                                    {formData?.trackingUrl ? (
                                                        <div className="tackingUrl">
                                                            <a target="_blank" href={formData?.trackingUrl}>
                                                                {" "}
                                                                {formData?.trackingNo}
                                                            </a>
                                                        </div>
                                                    ) : (
                                                        <input
                                                            disabled={view}
                                                            onChange={handleFormChange}
                                                            value={formData?.trackingNo}
                                                            name={"trackingNo"}
                                                            className="form-control"
                                                            maxLength={100}
                                                        />
                                                    )}
                                                </div>

                                            </InputGroup>
                                        </Col>
                                        <Col xs={12} lg={3}>
                                            <InputGroup>
                                                <div className="input-label-Created-date font-weight-boldss">
                                                    Status <span className="require-field">*</span>
                                                </div>
                                                <div>
                                                    <select
                                                        disabled={view}
                                                        onChange={handleFormChange}
                                                        value={formData?.inboundProductStatus_Id}
                                                        name={"inboundProductStatus_Id"}
                                                        className="form-control noMarginBtm"
                                                    >
                                                        {isCreateForm && (
                                                            <option value={0} selected>
                                                                Select
                                                            </option>
                                                        )}
                                                        {productStatusList?.length &&
                                                            productStatusList.map((item, index) => (
                                                                <option key={item.id} value={item.id}>
                                                                    {item.status}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </div>
                                                {error.inboundProductStatus_Id && (
                                                    <label className="requiredthis">
                                                        *This is required
                                                    </label>
                                                )}
                                            </InputGroup>
                                        </Col>
                                        <Col xs={12} lg={3}>
                                            <InputGroup>
                                                <div className="input-label-Created-date font-weight-boldss">
                                                    Process Type
                                                </div>
                                                <div>
                                                    <select
                                                        disabled={view}
                                                        onChange={handleFormChange}
                                                        value={formData?.inboundProductProcessType_Id}
                                                        name={"inboundProductProcessType_Id"}
                                                        className="form-control noMarginBtm"
                                                    >
                                                        {isCreateForm && (
                                                            <option selected value>
                                                                Select
                                                            </option>
                                                        )}
                                                        {productProcessList?.length &&
                                                            productProcessList.map((item, index) => (
                                                                <option key={item.id} value={item.id}>
                                                                    {item.processType}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </div>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row className="cus-mar-bottom20 p-2 rowBottomSpace">
                                        <Col xs={12} lg={3}>
                                            <InputGroup>
                                                <div className="input-label-Created-date font-weight-boldss">
                                                    Processing Date{" "}
                                                </div>
                                                <div>
                                                    <DatePicker
                                                        disabled
                                                        className="form-control receivedDate"
                                                        dateFormat="MM-dd-yyyy"
                                                        onChange={(receivedDate) =>
                                                            handleDateChange(receivedDate, "processingDate")
                                                        }
                                                        showIcon
                                                        value={
                                                            formData?.processingDate || isCreateForm
                                                                ? moment(formData?.processingDate).format(
                                                                    "MM-DD-YYYY"
                                                                )
                                                                : null
                                                        }
                                                        name={"processingDate"}
                                                    />
                                                </div>
                                            </InputGroup>
                                        </Col>
                                        <Col xs={12} lg={3}>
                                            <InputGroup>
                                                <div className="input-label-Created-date font-weight-boldss">
                                                    Shipped Quantity
                                                </div>
                                                <div className="input-box">
                                                    <input
                                                        disabled={view}
                                                        onChange={handleFormChange}
                                                        type="number"
                                                        min={1}
                                                        value={formData?.shippedQuantity}
                                                        name={"shippedQuantity"}
                                                        className="form-control"
                                                        maxLength={100}
                                                    />
                                                </div>

                                            </InputGroup>
                                        </Col>
                                        <Col xs={12} lg={3}>
                                            <InputGroup>
                                                <div className="input-label-Created-date font-weight-boldss">
                                                    Available Quantity
                                                </div>
                                                <div className="input-box">
                                                    <input
                                                        disabled={view}
                                                        onChange={handleFormChange}
                                                        value={formData?.availableQuantity}
                                                        name={"availableQuantity"}
                                                        className="form-control"
                                                        type="number"
                                                        min={1}
                                                        maxLength={100}
                                                    />
                                                </div>

                                            </InputGroup>
                                        </Col>
                                        <Col xs={12} lg={3}>
                                            <InputGroup>
                                                <div className="input-label-Created-date font-weight-boldss">
                                                    Ship Date{" "}
                                                </div>
                                                <div>
                                                    <DatePicker
                                                        disabled
                                                        className="form-control receivedDate"
                                                        dateFormat="MM-dd-yyyy"
                                                        onChange={(receivedDate) =>
                                                            handleDateChange(receivedDate, "shipDate")
                                                        }
                                                        showIcon
                                                        value={
                                                            formData?.shipDate || isCreateForm
                                                                ? moment(formData?.shipDate).format(
                                                                    "MM-DD-YYYY"
                                                                )
                                                                : null
                                                        }
                                                        name={"shipDate"}
                                                    />
                                                </div>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row className="cus-mar-bottom20 p-2 rowBottomSpace">

                                        <Col xs={12} lg={3}>
                                            <InputGroup>
                                                <div className="input-label-Created-date font-weight-boldss">
                                                    Packing Supplies
                                                </div>
                                                <div className="input-box">
                                                    <input
                                                        disabled={view}
                                                        onChange={handleFormChange}
                                                        value={formData?.packingSupplies}
                                                        name={"packingSupplies"}
                                                        className="form-control"
                                                        maxLength={100}
                                                    />
                                                </div>

                                            </InputGroup>
                                        </Col>

                                        <Col xs={12} lg={3}>
                                            <InputGroup>
                                                <div className="input-label-Created-date font-weight-boldss">
                                                    Item Bin
                                                </div>
                                                <div className="input-box">
                                                    <input
                                                        disabled={view}
                                                        onChange={handleFormChange}
                                                        value={formData?.itemBinNo}
                                                        name={"itemBinNo"}
                                                        className="form-control"
                                                        maxLength={100}
                                                    />
                                                </div>


                                            </InputGroup>
                                        </Col>
                                        <Col xs={12} lg={3}>
                                            <InputGroup>
                                                <div className="input-label-Created-date font-weight-boldss">
                                                    Order Id
                                                </div>
                                                <div className="input-box">
                                                    <input
                                                        disabled={view}
                                                        onChange={handleFormChange}
                                                        value={formData?.orderId}
                                                        name={"orderId"}
                                                        className="form-control"
                                                        maxLength={100}
                                                    />
                                                </div>
                                            </InputGroup>
                                        </Col>

                                        <Col xs={12} lg={3}>
                                            <InputGroup>
                                                <div className="input-label-Created-date font-weight-boldss">
                                                    External Platform Product ID
                                                </div>
                                                <div className="input-box">
                                                    <input
                                                        disabled={view}
                                                        onChange={handleFormChange}
                                                        value={formData?.externalPlatformProductId}
                                                        name={"externalPlatformProductId"}
                                                        className="form-control"
                                                        maxLength={100}
                                                    />
                                                </div>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row className="cus-mar-bottom20 p-2 rowBottomSpace">
                                        <Col xs={12} lg={3}>
                                            <InputGroup>
                                                <div className="input-label-Created-date font-weight-boldss mb-1">
                                                    Video (format: mp4, avi, mov)
                                                </div>
                                                <div>
                                                    <div className="fileUpload">
                                                        <button
                                                            disabled={view}
                                                            type="button"
                                                            className="btn cus-seconday-bg-btn"
                                                        >
                                                            Upload Video
                                                        </button>
                                                        <input
                                                            ref={videoInputRef}
                                                            className="hiddenFileField"
                                                            onChange={handleVideoUpload} // Modify the function name here
                                                            disabled={view}
                                                            type="file"
                                                            id="customFile"
                                                            multiple
                                                        />
                                                    </div>
                                                    {formData?.videoUrl && (
                                                        <div className="selectedFile">
                                                            <div className="fileName">
                                                                {formData?.video}
                                                            </div>
                                                            <a
                                                                href={formData?.videoUrl}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                {formData?.video}
                                                            </a>
                                                            {!view && (
                                                                <i
                                                                    className="fa fa-times"
                                                                    aria-hidden="true"
                                                                    onClick={handleUpdateDeleteVideo}
                                                                ></i>
                                                            )}
                                                        </div>
                                                    )}

                                                    <RenderVideos
                                                        video={video}
                                                        formData={formData}
                                                        setVideo={setVideo}
                                                        videoInputRef={videoInputRef}
                                                    />
                                                </div>
                                            </InputGroup>
                                        </Col>
                                        <Col xs={12} lg={3}>
                                            <InputGroup>
                                                <div className="input-label-Created-date font-weight-boldss mb-1">
                                                    Before processing image

                                                </div>
                                                <div>
                                                    <div className="fileUpload">
                                                        <button
                                                            disabled={view}
                                                            type="button"
                                                            className="btn cus-seconday-bg-btn"
                                                        >
                                                            Upload image
                                                        </button>
                                                        <input
                                                            className="hiddenFileField"
                                                            onChange={handleImageUpload}
                                                            disabled={view}
                                                            type="file"
                                                            id="customFilebefore"
                                                            multiple
                                                        />
                                                    </div>

                                                    {image.length > 0 &&
                                                        image.map((item, index) => (
                                                            <div
                                                                className="selectedFile"
                                                                key={item?.id}
                                                            >
                                                                <div className="fileName">{item.name}</div>
                                                                <button
                                                                    onClick={() => handleDeleteImage(index)}
                                                                >
                                                                    <i
                                                                        className="fa fa-times"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                </button>
                                                            </div>
                                                        ))}
                                                </div>
                                            </InputGroup>
                                        </Col>
                                        <Col xs={12} lg={3}>
                                            <InputGroup>
                                                <div className="input-label-Created-date font-weight-boldss mb-1">
                                                    After processing Image
                                                </div>
                                                <div>
                                                    <div className="fileUpload">
                                                        <button
                                                            disabled={view}
                                                            type="button"
                                                            className="btn cus-seconday-bg-btn"
                                                        >
                                                            Upload image
                                                        </button>
                                                        <input
                                                            className="hiddenFileField"
                                                            onChange={handleAfterImageUpload}
                                                            disabled={view}
                                                            type="file"
                                                            id="customFileafter"
                                                            multiple
                                                        />
                                                    </div>

                                                    <RenderImages
                                                        afterImage={afterImage}
                                                        setAfterImage={setAfterImage}
                                                    />
                                                </div>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row className="cus-mar-bottom20 p-2 rowBottomSpace">
                                        <Col xs={12}>
                                            <Form.Group>
                                                <div className="font-weight-boldss">Notes</div>
                                                <textarea
                                                    disabled={view}
                                                    type={"text"}
                                                    name="notes"
                                                    value={formData?.notes}
                                                    className="form-control incomingTextarea"
                                                    onChange={handleFormChange}
                                                    maxLength={200}
                                                />
                                                {error.notes && (
                                                    <label className="requiredthis">
                                                        *This is required
                                                    </label>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                                {parseInt(id) !== 0 && (
                                    <div className="col-4">
                                        <div className="row">
                                            <div className="col-lg-6 col-sm-12">
                                                <h5>Before Processing</h5>
                                                {formData?.beforeProcessingImages?.length > 0 ? (
                                                    <ImageSlider
                                                        isView={view}
                                                        handleMarkPrimary={handleMarkPrimary}
                                                        handleDeleteImage={handleSliderDeleteImage}
                                                        images={formData?.beforeProcessingImages.filter(
                                                            (item) => !item.isDeleted
                                                        )}
                                                        isUserAdmin={UserValue?.Value?.isSuperAdmin}
                                                        setShowLargeSliderModal={setShowBeforeProcessingModal}
                                                        showLargeSliderModal={showBeforeProcessingModal}
                                                    />
                                                ) : (
                                                    <img src={noImage} alt="" />
                                                )}
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <h5>After Processing</h5>
                                                {formData?.afterProcessingImages?.length ? (
                                                    <ImageSlider
                                                        isView={view}
                                                        handleMarkPrimary={handleMarkPrimary}
                                                        images={formData?.afterProcessingImages.filter(
                                                            (item) => !item.isDeleted
                                                        )}
                                                        handleDeleteImage={handleSliderDeleteImage}
                                                        isUserAdmin={UserValue?.Value?.isSuperAdmin}
                                                        setShowLargeSliderModal={setShowAfterProcessingModal}
                                                        showLargeSliderModal={showBeforeProcessingModal}
                                                    />
                                                ) : (
                                                    <img src={noImage} alt="" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {formData?.beforeProcessingImages?.length > 0 &&
                <InLargeImageSlider
                    popupTitle={'Product'}
                    view={view}
                    handleMarkPrimary={handleMarkPrimary}
                    images={formData?.beforeProcessingImages.filter(
                        (item) => !item.isDeleted
                    )}
                    handleDeleteImage={handleSliderDeleteImage}
                    isUserAdmin={UserValue?.Value?.isSuperAdmin}
                    showModal={showBeforeProcessingModal}
                    setShowLargeSliderModal={setShowBeforeProcessingModal}
                />
            }

            {formData?.afterProcessingImages?.length > 0 &&
                <InLargeImageSlider
                    popupTitle={'Product'}
                    view={view}
                    handleMarkPrimary={handleMarkPrimary}
                    images={formData?.afterProcessingImages.filter(
                        (item) => !item.isDeleted
                    )}
                    handleDeleteImage={handleSliderDeleteImage}
                    isUserAdmin={UserValue?.Value?.isSuperAdmin}
                    showModal={showAfterProcessingModal}
                    setShowLargeSliderModal={setShowAfterProcessingModal}
                />
            }

        </main>
    );
};

export default EditProduct;
